import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { Link } from 'gatsby';
import { CardElement, useRecurly } from '@recurly/react-recurly';
import BillingForm from '../PaymentMethod/BillingForm/BillingForm';
import './IsolatedPaymentMethodForm.scss';
import PaymentVendorsImage from '../../../assets/img/payment-vendors.png';
import { StateContext } from '../../../lib/providers';
import { ValidateBillingInformation } from '../../../lib/validators';

const baseBillingInfo = {
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: 'US',
};

export default function PaymentMethodForm({ onFormSubmit: handleFormSubmit }) {
  const state = useContext(StateContext);
  const { user } = state;
  const { data } = user;

  const formRef = useRef(null);
  const [billingInfo, setBillingInfo] = useState({ ...baseBillingInfo });
  const [recurlyToken, setRecurlyToken] = useState(null);
  const [useContactInfo] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const recurly = useRecurly();

  /**
   * Will fill in or clear form based on 'useContactInfo' flag
   */
  useEffect(() => {
    if (useContactInfo) {
      const firstName = data.recurly.firstName || '';
      const lastName = data.recurly.lastName || '';
      const address1 = data.recurly.address ? data.recurly.address.street1 : '';
      const address2 = data.recurly.address ? data.recurly.address.street2 : '';
      const city = data.recurly.address ? data.recurly.address.city : '';
      const region = data.recurly.address ? data.recurly.address.region : '';
      const zip = data.recurly.address ? data.recurly.address.postalCode : '';

      const contactInfo = {
        firstName,
        lastName,
        address1,
        address2,
        state: region,
        city,
        zip,
      };

      setBillingInfo(contactInfo);
    } else {
      setBillingInfo({ ...baseBillingInfo });
    }
  }, [useContactInfo, data]);

  /**
   * Uses payment form (including data-recurly fields) to request a token
   */
  const getRecurlyToken = useCallback(
    async (formRef) =>
      new Promise((resolve, reject) => {
        recurly.token(formRef.current, (err, token) => {
          setFieldErrors((fields) => ({ ...fields, cc: err }));
          if (err) reject(err);
          else resolve(token);
        });
      }),
    [recurly]
  );

  /**
   * Handles Information Submit
   */
  const handleInformationSubmit = useCallback(async () => {
    const information = { useContactInfo, billingInfo, recurlyToken };
    const validation = ValidateBillingInformation(information);
    setFieldErrors(validation.fields);
    if (validation.isValid) {
      const token = await getRecurlyToken(formRef);
      if (token) {
        setRecurlyToken(token);
        const payload = { token_id: token.id };
        handleFormSubmit({ token });
        console.log('handleInformationSubmit:payload', { payload });
      }
    } else {
      console.error(validation.error);
    }
  }, [
    formRef,
    getRecurlyToken,
    billingInfo,
    recurlyToken,
    useContactInfo,
    handleFormSubmit,
  ]);

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      handleInformationSubmit();
    },
    [handleInformationSubmit]
  );

  const handleBillingInfoChange = (evt) => {
    const { target } = evt;
    const { name, value } = target;
    setBillingInfo((val) => ({ ...val, [name]: value }));
  };

  return (
    <div className="IsoPaymentMethod">
      <h2 className="IsoPaymentMethod-heading">Payment Method</h2>
      <div className="IsoPaymentMethod-container">
        <div className="IsoPaymentMethod-top">
          <Link className="IsoPaymentMethod-top-control" to="/dashboard/plan">
            Cancel
          </Link>
        </div>
        <form className={`PaymentInfo`} ref={formRef} onSubmit={handleSubmit}>
          <div className="PaymentInfo-container">
            <div className="PaymentInfo-billing">
              {/* <div className="PaymentInfo-billing-setting">
                <input
                  className="PaymentInfo-billing-setting-box"
                  type="checkbox"
                  id="contactAddressIsBilling"
                  checked={useContactInfo}
                  onChange={handleBillingInfoCheckboxChange}
                />
                <label
                  className="PaymentInfo-billing-setting-label"
                  htmlFor="contactAddressIsBilling"
                >
                  Billing address is the same as contact address
                </label>
              </div> */}
              <div
                className={`PaymentInfo-billing-formContainer ${
                  useContactInfo ? 'useContact' : ''
                }`}
              >
                <BillingForm
                  fieldErrors={fieldErrors}
                  billingInfo={billingInfo}
                  useContactInfo={useContactInfo}
                  contactInfo={billingInfo}
                  onChange={handleBillingInfoChange}
                />
              </div>
            </div>
            <div className="PaymentInfo-vendors">
              <img
                src={PaymentVendorsImage}
                alt="Accepted Credit Card Vendors"
              />
            </div>
            <PaymentCardForm fieldErrors={fieldErrors} />
            <div className="PaymentInfo-submit-wrapper">
              <button className="PaymentInfo-submit" type="submit">
                Confirm Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

/**
 * CC Input
 */
const PaymentCardForm = ({ fieldErrors }) => {
  const recurlyError = fieldErrors.cc;

  /**
   * Concatenates an error message for cc payment
   */
  const PaymentError = () => {
    let str = 'Credit Card';
    if (recurlyError && recurlyError.fields) {
      const numOfErrors = recurlyError.fields.length;
      recurlyError.fields.forEach((field, i) => {
        if (i === 0) {
          str += ` ${field}`;
        } else if (numOfErrors === 2) {
          str += ` and ${field}`;
        } else if (numOfErrors === i + 1) {
          str += `, and ${field}`;
        } else {
          str += `, ${field}`;
        }
      });
      str = numOfErrors === 1 ? `${str} is invalid` : `${str} are invalid`;
      console.log('PaymentError', {
        recurlyError,
        str,
        numOfErrors,
        twoErrs: numOfErrors === 2,
      });
    }
    return recurlyError ? (
      <div className="PaymentInfo-ccForm-error">{str}</div>
    ) : null;
  };

  return (
    <div className="PaymentInfo-ccForm">
      <label className="PaymentInfo-ccForm-label" htmlFor={'cardNumber'}>
        Credit Card Information
      </label>
      <div className="PaymentInfo-ccForm-ccContainer">
        <CardElement />
      </div>
      <PaymentError />
    </div>
  );
};
