import React, { Component } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import { Hamburger, Arrow } from '../../icons';
import './Navbar.scss';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  // Closes mobile dropdown when clicking outside the menu
  dropdownClose = (e) => {
    if (this.state.open) {
      if (!e.path.includes(document.querySelector('.Navbar'))) {
        this.setState({ open: false });
      }
    }
  };

  // Shows/Hides BackToTop arrow based on scroll
  showHideBackToTop = () => {
    const height = window.innerHeight / 2;
    if (
      document.body.scrollTop > height ||
      document.documentElement.scrollTop > height
    ) {
      document.querySelector('.backToTop').style.display = 'flex';
    } else {
      document.querySelector('.backToTop').style.display = 'none';
    }
  };

  componentDidMount() {
    window.addEventListener('click', this.dropdownClose);
    window.addEventListener('scroll', this.showHideBackToTop);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.dropdownClose);
    window.removeEventListener('scroll', this.showHideBackToTop);
  }

  // eslint-disable-next-line consistent-return
  handleSignIn(buttonText) {
    if (typeof window !== 'undefined')
      return window.location.pathname === '/signin' ? 'Sign Up >' : buttonText;
  }

  render() {
    const { open } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query NavBarQuery {
            strapiNavbar {
              buttonText
              buttonUrl
              largeLogo {
                width
                height
                url
              }
              smallLogo {
                width
                height
                url
              }
              Links {
                name
                url
              }
            }
          }
        `}
        render={(navbarData) => (
          <div className="Navbar">
            <div className="bar">
              <Link to="/" aria-label="Return to Home Page">
                <img
                  src={navbarData.strapiNavbar.smallLogo.url}
                  className="small-logo"
                  alt="Amwell Logo"
                  width={navbarData.strapiNavbar.smallLogo.width}
                  height={navbarData.strapiNavbar.smallLogo.height}
                />
                <img
                  src={navbarData.strapiNavbar.largeLogo.url}
                  className="large-logo"
                  alt="Amwell Logo"
                  width={navbarData.strapiNavbar.largeLogo.width}
                  height={navbarData.strapiNavbar.largeLogo.height}
                />
              </Link>
              <div className="desktop-menu">
                {navbarData.strapiNavbar.Links.map((link, i) => {
                  if (link.url && link.name) {
                    return (
                      <Link to={link.url} className="link" key={i}>
                        {link.name}
                      </Link>
                    );
                  }
                  return null;
                })}
                {navbarData.strapiNavbar.buttonText &&
                navbarData.strapiNavbar.buttonUrl ? (
                  <Link
                    to={navbarData.strapiNavbar.buttonUrl}
                    className="primary-button"
                  >
                    {this.handleSignIn(navbarData.strapiNavbar.buttonText)}
                  </Link>
                ) : (
                  ''
                )}
              </div>
              <div className="mobile-menu">
                {navbarData.strapiNavbar.buttonText &&
                navbarData.strapiNavbar.buttonUrl ? (
                  <Link
                    to={navbarData.strapiNavbar.buttonUrl}
                    className="primary-button"
                  >
                    {this.handleSignIn(navbarData.strapiNavbar.buttonText)}
                  </Link>
                ) : (
                  ''
                )}
                <button
                  onClick={() => {
                    this.setState({ open: !open });
                  }}
                  aria-label="menu"
                >
                  <Hamburger />
                </button>
              </div>
            </div>
            <div className={`dropdown-menu ${open ? 'open' : ''}`}>
              {navbarData.strapiNavbar.Links.map((link, i) => {
                if (link.url && link.name) {
                  return (
                    <Link to={link.url} className="link" key={i}>
                      {link.name}
                    </Link>
                  );
                }
                return null;
              })}
            </div>

            <button
              className="backToTop"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Arrow />
            </button>
          </div>
        )}
      />
    );
  }
}

export default Navbar;
