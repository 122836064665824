import { getAuthInfo } from '.';
import { throwEndpointError } from '../../../utils';

export default async function deleteProvider(user, providerObj) {
  const { jwt } = await getAuthInfo();
  const { provider } = providerObj;

  console.log('Provider', provider);

  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/provider/${
      provider.id || provider.Id
    }`,
    {
      method: 'DELETE',
      headers: new Headers({
        Authorization: jwt,
      }),
    }
  ).catch((err) =>
    throwEndpointError(err, res, 'Error deleting provider account')
  );

  if (!res.ok) {
    throwEndpointError({}, res, 'Error deleting provider account');
    return;
  }

  const data = await res.json();
  console.log('Delete Provider Response', data);
  // eslint-disable-next-line consistent-return
  return data;
}
