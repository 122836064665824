import React, { useContext, useEffect, memo, useState, useRef } from 'react';
import { navigate } from 'gatsby';
import IdleTimer from 'react-idle-timer';
import { NavigationBar, SideNavigation } from '..';
import './DashboardLayout.scss';
import { SplashScreen } from '../../misc';
import { StateContext } from '../../../lib/providers';

import { IdlePopup } from '../../dashboard';

const StaticIdleTimer = memo(IdleTimer);

export default function DashboardLayout({ children }) {
  const state = useContext(StateContext);
  const { user } = state || {};
  const [showIdlePopup, setShowIdlePopup] = useState(false);
  const intervalRef = useRef(null);

  const idleHandler = () => setShowIdlePopup(true);

  useEffect(() => {
    if (showIdlePopup) {
      clearInterval(intervalRef.current);

      intervalRef.current = setInterval(() => {
        if (showIdlePopup) {
          clearInterval(intervalRef.current);
          navigate('/signout');
        }
      }, 1000 * 60);
    } else {
      clearInterval(intervalRef.current);
    }
  }, [showIdlePopup]);

  return (
    <div className="DashboardLayout">
      <StaticIdleTimer timeout={1000 * 60 * 14} onIdle={idleHandler} />
      {showIdlePopup && <IdlePopup setShowIdlePopup={setShowIdlePopup} />}
      <NavigationBar />
      <SideNavigation />
      <div className="DashboardLayout-container">
        {user ? children : <SplashScreen />}
      </div>
    </div>
  );
}
