import calcTierPricing from './calcTierPricing';
import calcTotalAmount from './calcTotalAmount';
import getAnnualDiscount from './getAnnualDiscount';
import getCodeBillingPeriod from './getCodeBillingPeriod';
import isCodeFreemium from './isCodeFreemium';

export default function calMonthlyPayment(subscription, providerAccounts) {
  const isFree = isCodeFreemium(subscription.plan.code);

  // Return free amount
  if (isFree) {
    const price = 0;
    const money = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(price);
    return { price, money };
  }

  const billingPeriod = getCodeBillingPeriod(subscription.plan.code);

  const voidDiscount = billingPeriod !== 'annual';

  const newSubtotal = calcTierPricing(providerAccounts, isFree);
  const newDiscount = getAnnualDiscount(newSubtotal.price, voidDiscount);

  return calcTotalAmount(newSubtotal.price, newDiscount.price);
}
