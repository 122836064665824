export default function mergeUniqueUsers(userPool) {
  console.log("Mapping User Pool", userPool)
  const map = {}
  const uniqueUserPool = []
  for (const user of userPool) {
    map[user.Email] = map[user.Email] ? [...map[user.Email], user] : [user]
  }

  Object.values(map).forEach(userEntries => {
    if (userEntries.length > 1) {
      const sorted = userEntries.sort((a, b) => {
        if (a.Id) return -1
        if (!a.Id) return 1
        return 0
      })
      const mergedUser = userEntries.reduce((acc, userOjb) => {
        /**
         *  If an "unregistered" slips through when there are multiple users, delete and assume it's registered
         */
        // eslint-disable-next-line no-param-reassign
        delete userOjb.unregistered
        return { ...acc, ...userOjb }
      }, {})
      console.log("User copies has been merged", {
        userEntries,
        mergedUser,
        sorted,
      })

      uniqueUserPool.push(mergedUser)
    } else {
      uniqueUserPool.push(userEntries[0])
    }
  })

  return uniqueUserPool
}
