import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { CardElement, useRecurly } from '@recurly/react-recurly';
import BillingForm from './BillingForm/BillingForm';
import { LoadingSpinner } from '../../misc';
import './PaymentMethod.scss';
import PaymentVendorsImage from '../../../assets/img/payment-vendors.png';
import { StateContext } from '../../../lib/providers';
import { Amwell } from '../../../lib/services';
import { ValidateBillingInformation } from '../../../lib/validators';

export default function PaymentMethod() {
  const [editingPaymentInfo, setEditingPaymentInfo] = useState(false);
  const [billingInfo, setBillingInfo] = useState(null);
  const { user } = useContext(StateContext);

  const handlePaymentMethodEditToggle = () => {
    setEditingPaymentInfo((val) => !val);
  };

  const getLatestBillingInformation = async () => {
    const billingAccount = await Amwell.fetchBillingInformation();
    setBillingInfo(billingAccount.billingInfo);
    console.log('Latest Billing Account Information', { billingAccount });
    setEditingPaymentInfo(false);
  };

  useEffect(() => {
    (async () => {
      await getLatestBillingInformation();
    })();
  }, [user]);

  return billingInfo ? (
    <div className="PaymentMethod">
      <h2 className="PaymentMethod-heading">Payment Method</h2>
      <div className="PaymentMethod-container">
        <button
          className="PaymentMethod-edit"
          onClick={handlePaymentMethodEditToggle}
        >
          {editingPaymentInfo ? 'Cancel' : 'Edit'}
        </button>
        {editingPaymentInfo ? (
          <BillingInformationForm onUpdate={getLatestBillingInformation} />
        ) : (
          <PaymentInformationPreview
            ccInfo={{
              ccType: billingInfo.paymentMethod.cardType,
              ccLastFour: billingInfo.paymentMethod.lastFour,
              ccExpirationMonth: billingInfo.paymentMethod.expMonth,
              ccExpirationYear: billingInfo.paymentMethod.expYear,
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
}

const PaymentInformationPreview = ({ ccInfo }) => {
  console.log('PaymentInformationPreview', ccInfo);
  const { ccType, ccLastFour, ccExpirationMonth, ccExpirationYear } = ccInfo;
  return (
    <div className="PaymentMethod-ccInfo">
      <span className="PaymentMethod-ccInfo-brand">{ccType}</span>
      <div className="PaymentMethod-ccInfo-group">
        <span className="PaymentMethod-ccInfo-lastFour">
          xxxx-xxxx-xxxx-{ccLastFour}
        </span>
        <span className="PaymentMethod-ccInfo-expiration">
          Exp: {ccExpirationMonth}/{ccExpirationYear.toString().slice(-2)}
        </span>
      </div>
    </div>
  );
};

const baseBillingInfo = {
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: 'US',
};

/**
 * Billing Information Form
 */
function BillingInformationForm({ onUpdate: handleUpdate }) {
  // const state = useContext(StateContext)
  // const dispatch = useContext(DispatchContext)
  const state = useContext(StateContext);
  const { user } = state;
  const { data } = user;

  const formRef = useRef(null);
  const [billingInfo, setBillingInfo] = useState({ ...baseBillingInfo });
  const [recurlyToken, setRecurlyToken] = useState(null);
  const [useContactInfo, setUseContactInfo] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const recurly = useRecurly();

  /**
   * Will fill in or clear form based on 'useContactInfo' flag
   */
  useEffect(() => {
    if (useContactInfo) {
      const firstName = data.recurly.firstName || '';
      const lastName = data.recurly.lastName || '';
      const address1 = data.recurly.address ? data.recurly.address.street1 : '';
      const address2 = data.recurly.address ? data.recurly.address.street2 : '';
      const city = data.recurly.address ? data.recurly.address.city : '';
      const region = data.recurly.address ? data.recurly.address.region : '';
      const zip = data.recurly.address ? data.recurly.address.postalCode : '';

      const contactInfo = {
        firstName,
        lastName,
        address1,
        address2,
        state: region,
        city,
        zip,
      };

      setBillingInfo(contactInfo);
    } else {
      setBillingInfo({ ...baseBillingInfo });
    }
  }, [useContactInfo, data]);

  /**
   * Uses payment form (including data-recurly fields) to request a token
   */
  const getRecurlyToken = useCallback(
    async (formRef) =>
      new Promise((resolve, reject) => {
        recurly.token(formRef.current, (err, token) => {
          setFieldErrors((fields) => ({ ...fields, cc: err }));
          if (err) reject(err);
          else resolve(token);
        });
      }),
    [recurly]
  );

  /**
   * Handles Information Submit
   */
  const handleInformationSubmit = useCallback(async () => {
    const information = { useContactInfo, billingInfo, recurlyToken };
    setGeneralError('');
    const validation = ValidateBillingInformation(information);
    setFieldErrors(validation.fields);
    if (validation.isValid) {
      const token = await getRecurlyToken(formRef);
      if (token) {
        setRecurlyToken(token);
        const payload = { token_id: token.id };
        try {
          const resData = await Amwell.updateBillingInformation(payload);
          // TODO: update in state (Note: does not update in SF in real time. Card Information not returned via existing /billingInfo endpoint)
          console.log('handleInformationSubmit:res', { resData });
          handleUpdate();
        } catch (err) {
          console.error('handleInformationSubmit:error', { err });
          setGeneralError('An Error Occurred');
        }
      } else {
        setGeneralError('An Error Occurred');
      }
    } else {
      setGeneralError(validation.error);
      console.error(validation.error);
    }
  }, [
    formRef,
    getRecurlyToken,
    billingInfo,
    recurlyToken,
    useContactInfo,
    handleUpdate,
  ]);

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      handleInformationSubmit();
    },
    [handleInformationSubmit]
  );

  const handleBillingInfoCheckboxChange = () => {
    setUseContactInfo((val) => !val);
  };

  const handleBillingInfoChange = (evt) => {
    const { target } = evt;
    const { name, value } = target;
    setBillingInfo((val) => ({ ...val, [name]: value }));
  };

  return (
    <form className={`PaymentInfo`} ref={formRef} onSubmit={handleSubmit}>
      <div className="PaymentInfo-container">
        <div className="PaymentInfo-billing">
          <div className="PaymentInfo-billing-setting">
            <input
              className="PaymentInfo-billing-setting-box"
              type="checkbox"
              id="contactAddressIsBilling"
              checked={useContactInfo}
              onChange={handleBillingInfoCheckboxChange}
            />
            <label
              className="PaymentInfo-billing-setting-label"
              htmlFor="contactAddressIsBilling"
            >
              Billing address is the same as contact address
            </label>
          </div>
          <div
            className={`PaymentInfo-billing-formContainer ${
              useContactInfo ? 'useContact' : ''
            }`}
          >
            <BillingForm
              fieldErrors={fieldErrors}
              billingInfo={billingInfo}
              useContactInfo={useContactInfo}
              contactInfo={billingInfo}
              onChange={handleBillingInfoChange}
            />
          </div>
        </div>
        <div className="PaymentInfo-vendors">
          <img src={PaymentVendorsImage} alt="Accepted Credit Card Vendors" />
        </div>
        <PaymentCardForm fieldErrors={fieldErrors} />
        <div className="PaymentInfo-submit-wrapper">
          <button className="PaymentInfo-submit" type="submit">
            Save Changes
          </button>
        </div>
        <p className="PaymentInfo-error">{generalError}</p>
      </div>
    </form>
  );
}

/**
 * CC Input
 */
const PaymentCardForm = ({ fieldErrors }) => {
  const recurlyError = fieldErrors.cc;

  /**
   * Concatenates an error message for cc payment
   */
  const PaymentError = () => {
    let str = 'Credit Card';
    if (recurlyError && recurlyError.fields) {
      const numOfErrors = recurlyError.fields.length;
      recurlyError.fields.forEach((field, i) => {
        if (i === 0) {
          str += ` ${field}`;
        } else if (numOfErrors === 2) {
          str += ` and ${field}`;
        } else if (numOfErrors === i + 1) {
          str += `, and ${field}`;
        } else {
          str += `, ${field}`;
        }
      });
      str = numOfErrors === 1 ? `${str} is invalid` : `${str} are invalid`;
      console.log('PaymentError', {
        recurlyError,
        str,
        numOfErrors,
        twoErrs: numOfErrors === 2,
      });
    }
    return recurlyError ? (
      <div className="PaymentInfo-ccForm-error">{str}</div>
    ) : null;
  };

  return (
    <div className="PaymentInfo-ccForm">
      <label className="PaymentInfo-ccForm-label" htmlFor={'cardNumber'}>
        Credit Card Information
      </label>
      <div className="PaymentInfo-ccForm-ccContainer">
        <CardElement />
      </div>
      <PaymentError />
    </div>
  );
};
