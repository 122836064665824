export default function ValidatePracticeUser(practiceUser) {
  const validation = {
    isValid: false,
    errors: [],
    fields: {},
  };

  const userValidation = validateUser(practiceUser);

  /**
   * Validation Check
   */
  validation.isValid = userValidation.foundErrors.length === 0;
  validation.fields = userValidation.errorFields;
  validation.errors = userValidation.foundErrors;

  // console.log("ValidatePracticeUser", {
  //   practiceUser,
  //   validation,
  // })
  return validation;
}

// ================= VALIDATION FUNCTIONS ====================== //

const isNotEmpty = (value, foundErrors, field) => {
  if (!value || value.trim() === '' || value === '0') {
    foundErrors.push({ field, message: 'This field is required' });
  }
  return foundErrors;
};

const isEmail = (value, foundErrors, field) => {
  const emailCheck =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  if (!emailCheck.test(value)) {
    foundErrors.push({ field, message: 'A valid email must be provided' });
  }
  return foundErrors;
};

const isProperLength = ({ value, length }, foundErrors, field) => {
  if (value.length !== parseInt(length, 10)) {
    foundErrors.push({
      field,
      message: `An NPI number must be ${length} characters`,
    });
  }
  return foundErrors;
};

/**
 * Returns the validation of an individual user
 */
export function validateUser(user) {
  let foundErrors = [];
  const errorFields = {};

  const { firstName, lastName, email, npi } = user;
  // CHeck first name
  foundErrors = isNotEmpty(firstName, foundErrors, 'firstName');

  // CHeck last name
  foundErrors = isNotEmpty(lastName, foundErrors, 'lastName');

  // CHeck email name
  foundErrors = isNotEmpty(email, foundErrors, 'email');
  foundErrors = isEmail(email, foundErrors, 'email');

  // Check npi if present
  if (npi) {
    foundErrors = isProperLength(
      { value: npi, length: 10 },
      foundErrors,
      'npi'
    );
  }

  // Push found error for each required field
  foundErrors.forEach((error) => {
    const { field, message } = error;
    errorFields[field] = errorFields[field] || [];
    errorFields[field].push(message);
  });

  // Create and return a validation object
  return {
    foundErrors,
    errorFields,
  };
}

export function filterValidUsers(users) {
  const validatedUsers = users.map((user) => ({
    user,
    validation: validateUser(user),
  }));
  return validatedUsers
    .filter((validatedUser) => !validatedUser.validation.foundErrors.length)
    .map((validatedUser) => validatedUser.user);
}
