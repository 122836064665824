/**
 * EXAMPLE
  {
    "FirstName":"Test",
    "LastName":"Account",
    "Title":"Tester",
    "Seat_Status__c":"Active",
    "Email":"test@ripentester2provider.com"
  }
  Note: append custom properties with __c
 */

export default function makeProviderObject(user) {
  const {
    firstName: FirstName,
    lastName: LastName,
    email: Email,
    // eslint-disable-next-line camelcase
    npi: NPI__c,
    title: Title,
    // eslint-disable-next-line camelcase
    specialty: Specialty__c,
  } = user;

  return {
    FirstName,
    LastName,
    Email: Email.toLowerCase(),
    NPI__c,
    Title,
    Specialty__c,
    Seat_Status__c: 'Active',
    AWPP_Relationship__c: 'Provider',
    System_Impact_Contact__c: true,
  };
}

export function getProviderFieldKey(fieldName) {
  switch (fieldName) {
    case 'firstName':
      return 'FirstName';
    case 'lastName':
      return 'LastName';
    case 'email':
      return 'Email';
    case 'npi':
      return 'NPI__c';
    case 'title':
      return 'Title';
    case 'specialty':
      return 'Specialty__c';
    default:
      return null;
  }
}
