import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { MinusIcon, PlusIcon } from '../../icons';
import './PlanChange.scss';
import { StateContext } from '../../../lib/providers';
import {
  calcMonthlyPayment,
  calcTierPricing,
  getAnnualDiscount,
  getMoneyPrice,
  isFreePlan,
  isPremiumPlan,
} from '../../../lib/utils';

const copylines = {
  free: 'You are currently on the Free Plan. Select a new plan below to continue.',
  premium:
    'You are currently on the Premium Plan. Select a new plan or change your user count below to continue.',
  canceled:
    'You are currently un enrolled in a plan. Select a new plan below to continue.',
};

export default function PlanChange({
  currentPlan,
  onContinue: handleContinue,
  editingPlanState,
  changedPlanState,
  changedUserAmountState,
  currentUserAmountState,
  annualCommitmentState,
  subscription,
  providerCount,
  allowPlanSwitching,
}) {
  const setIsEditingPlan = editingPlanState[1];
  const [changedPlan] = changedPlanState;
  const [currentUserAmount] = currentUserAmountState;
  const setChangedUserAmount = changedUserAmountState[1];

  /**
   * Will Bubble Continue Event
   */
  const forwardContinue = (evt) => {
    setIsEditingPlan(false);
    console.log('forwardContinue', { changedPlan, evt });
    handleContinue(evt);
  };

  /**
   * View if editing the current plan
   */
  const EditingView = () => (
    <>
      <div className="PlanChange-top">
        <p className="PlanChange-top-copy">{copylines[currentPlan]}</p>
        <button
          className="PlanChange-top-control"
          to="/dashboard/plan"
          onClick={() => {
            setChangedUserAmount(currentUserAmount);
            setIsEditingPlan(false);
            navigate('/dashboard/plan');
          }}
        >
          Cancel
        </button>
      </div>
      <PlanEditor
        currentPlan={currentPlan}
        subscription={subscription}
        providerCount={providerCount}
        onContinue={forwardContinue}
        changedPlanState={changedPlanState}
        changedUserAmountState={changedUserAmountState}
        annualCommitmentState={annualCommitmentState}
        currentUserAmountState={currentUserAmountState}
        allowPlanSwitching={allowPlanSwitching}
      />
    </>
  );

  return currentPlan ? (
    <div className="PlanChange">
      <h2 className="PlanChange-heading">Change Plan</h2>
      <div className="PlanChange-container">
        <EditingView />
      </div>
    </div>
  ) : null;
}

/**
 * Plan Editor
 */
const PlanEditor = ({
  currentPlan,
  onContinue: handleContinue,
  changedPlanState,
  changedUserAmountState,
  annualCommitmentState,
  currentUserAmountState,
  subscription,
  providerCount,
  allowPlanSwitching,
  isDowngradingToFree,
}) => {
  const [changedPlan] = changedPlanState;

  const PlanChangeViews = () => {
    /**
     * Handle if free/starter plan
     */
    if (isFreePlan(currentPlan)) {
      // console.log('PlanEditor')
      const isUpgradingPlan =
        isFreePlan(currentPlan) && !isFreePlan(changedPlan);
      if (isUpgradingPlan) {
        return (
          <UpgradeFromFreePlan
            onContinue={handleContinue}
            subscription={subscription}
            changedUserAmountState={changedUserAmountState}
            annualCommitmentState={annualCommitmentState}
          />
        );
      }
    }

      /**
       * (Free -> Free) No Change
       */
    if (isPremiumPlan(currentPlan)) {
        /**
         * Handle if premium plan
         */
        const isUpgradingUserAmount =
          isPremiumPlan(currentPlan) && isPremiumPlan(changedPlan);
        const isDowngradingToFree =
          isPremiumPlan(currentPlan) && isFreePlan(changedPlan);
        console.log({ isUpgradingUserAmount, isDowngradingToFree });
        /**
         * Premium Plan (Change/Update users)
         */
        if (isUpgradingUserAmount)
          return (
            <UpdateUserAmountFlow
              onContinue={handleContinue}
              subscription={subscription}
              providerCount={providerCount}
              changedUserAmountState={changedUserAmountState}
              annualCommitmentState={annualCommitmentState}
              currentUserAmountState={currentUserAmountState}
            />
          );
      if (isDowngradingToFree) {
        return (
          <DowngradeToFreeFlow
            onContinue={handleContinue}
            subscription={subscription}
            providerCount={providerCount}
            changedUserAmountState={changedUserAmountState}
            currentUserAmountState={currentUserAmountState}
            annualCommitmentState={annualCommitmentState}
          />
        );
      }
        return null;
    }
    return null;
  };

  return (
    <div className="PlanChange-editor">
      {
        <PlanSelector
          changedPlanState={changedPlanState}
          annualCommitmentState={annualCommitmentState}
        />
      }
      <PlanChangeViews />
    </div>
  );
};

/**
 * Free -> Premium Flow
 */
const UpgradeFromFreePlan = ({
  onContinue: handleContinue,
  changedUserAmountState,
  annualCommitmentState,
}) => {
  const [changedUserAmount] = changedUserAmountState;
  const [annualCommitment] = annualCommitmentState;

  const forwardContinue = () => {
    const plan = annualCommitment ? `premium-annual` : `premium-monthly`;
    handleContinue({
      action: 'upgrade-to-premium',
      plan,
      annualCommitment,
      userAmount: changedUserAmount,
    });
  };

  return (
    <>
      <PracticeUserIncrementer
        changedUserAmountState={changedUserAmountState}
      />
      <AnnualCommitment annualCommitmentState={annualCommitmentState} />
      <PremiumPlanSummary
        annualCommitment={annualCommitment}
        changedUserAmountState={changedUserAmountState}
      />
      <PlanChangeContinue onContinue={forwardContinue} />
    </>
  );
};

/**
 * View shown if a premium user is downgrading to the free plan
 */
const DowngradeToFreeFlow = ({
  onContinue: handleContinue,
  changedUserAmountState,
  subscription,
  providerCount,
}) => {
  const [changedUserAmount] = changedUserAmountState;
  console.log('DowngradeToFreeFlow Rendered');
  const forwardContinue = () => {
    handleContinue({
      action: 'downgrade-to-starter',
      plan: 'starter',
      userAmount: 1,
    });
  };
  return (
    <>
      <PracticeUserIncrementer
        isFreePlan={true}
        changedUserAmountState={changedUserAmountState}
      />
      <PlanChangeTotal
        isFreePlan={true}
        subscription={{ plan: { code: 'starter' } }}
        userAmount={changedUserAmount}
      />
      <PlanChangeContinue
        onContinue={forwardContinue}
        providerCount={providerCount}
        changedUserAmount={changedUserAmount}
      />
    </>
  );
};

/**
 * View shown if a premium user is changing user amounts
 */
const UpdateUserAmountFlow = ({
  onContinue: handleContinue,
  changedUserAmountState,
  annualCommitmentState,
  currentUserAmountState,
  subscription,
  providerCount,
}) => {
  const [annualCommitment] = annualCommitmentState;
  const [changedUserAmount] = changedUserAmountState;
  const [currentUserAmount] = currentUserAmountState;
  const amountHasChanged = currentUserAmount !== changedUserAmount;
  const planCode = annualCommitment ? `premium-annual` : `premium-monthly`;
  const forwardContinue = () => {
    handleContinue({
      action: 'update-user-amount',
      plan: planCode,
      userAmount: changedUserAmount,
      annualCommitment,
    });
  };
  return (
    <>
      <PracticeUserIncrementer
        providerCount={providerCount}
        changedUserAmountState={changedUserAmountState}
      />
      <PlanChangeTotal
        annualCommitment={annualCommitment}
        changedUserAmountState={changedUserAmountState}
        subscription={{ plan: { code: planCode } }}
        userAmount={changedUserAmount}
      />
      {amountHasChanged ? (
        <PlanChangeContinue
          onContinue={forwardContinue}
          providerCount={providerCount}
          changedUserAmount={changedUserAmount}
        />
      ) : null}
    </>
  );
};

// ============================ Plan Selector ================================ //

const PlanSelector = ({ changedPlanState, annualCommitmentState }) => {
  const [changedPlan, setChangedPlan] = changedPlanState;
  const [annualCommitment] = annualCommitmentState;
  console.log('PlanSelector', { selectedPlan: changedPlan, annualCommitment });
  // NOTE: Used to handle premium/free plan selection
  // useEffect(() => {
  //   setChangedPlan(cur => {
  //     if (isPremiumPlan(cur)) {
  //       return annualCommitment ? "premium-annual" : "premium-monthly"
  //     } else if (isFreePlan(cur)) {
  //       return "starter"
  //     } else return cur
  //   })
  // }, [annualCommitment, setChangedPlan])
  return (
    <div className="PlanChange-plans">
      <div className="PlanChange-plan">
        <label className="PlanChange-plan-label" htmlFor="premium-plan">
          <input
            className="PlanChange-plan-input"
            type="hidden"
            id="premium-plan"
            name="plan"
            value="premium"
            checked={
              changedPlan.includes('premium') ||
              changedPlan.includes('annual') ||
              changedPlan.includes('monthly')
            }
            onChange={() =>
              setChangedPlan(
                annualCommitment ? 'premium-annual' : 'premium-monthly'
              )
            }
          />
          Premium
        </label>
      </div>
    </div>
  );
};

// ============================ User Incrementer ================================ //

const PracticeUserIncrementer = ({
  isFreePlan,
  changedUserAmountState,
  providerCount,
}) => {
  const [changedUserAmount, setChangedUserAmount] = changedUserAmountState;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isFreePlan) return setChangedUserAmount(1);
  }, [isFreePlan, setChangedUserAmount]);

  /**
   * Increase Users
   */
  // eslint-disable-next-line consistent-return
  const increasePracticeUsers = () => {
    if (isFreePlan) return setChangedUserAmount(1);
    const value = changedUserAmount + 1;
    // eslint-disable-next-line consistent-return
    if (value > 75) return;
    setChangedUserAmount(value);
  };

  /**
   * Decrease Users
   */
  // eslint-disable-next-line consistent-return
  const decreasePracticeUsers = () => {
    if (isFreePlan) return setChangedUserAmount(1);
    const isZero = changedUserAmount - 1 === 0;
    // eslint-disable-next-line consistent-return
    if (isZero) return;
    const value = changedUserAmount - 1;
    setChangedUserAmount(value);
  };

  /**
   * Handle Input Change
   */
  // eslint-disable-next-line consistent-return
  const handleInputChange = ({ target }) => {
    if (isFreePlan) return setChangedUserAmount(1);
    const { value } = target;
    const isNotNum = isNaN(value);
    // eslint-disable-next-line consistent-return
    if (isNotNum) return;
    const belowZero = value < 0;
    // eslint-disable-next-line consistent-return
    if (belowZero) return;
    const int = Math.floor(value);
    if (!value) setChangedUserAmount('');
    else {
      setChangedUserAmount(int);
    }
  };

  /**
   * Handle Input Blur
   */
  const handleInputBlur = ({ target }) => {
    if (isFreePlan) return setChangedUserAmount(1);

    const { value } = target;
    const isNotNum = isNaN(value);
    if (isNotNum) return setChangedUserAmount(changedUserAmount);
    const belowMin = value < 1;
    const aboveMax = value > 72;
    const int = Math.floor(value);

    if (belowMin) {
      return setChangedUserAmount(1);
    }
    if (aboveMax) {
      return setChangedUserAmount(75);
    }
    return setChangedUserAmount(int);
  };

  return (
    <div className="PlanChange-userAmount">
      <span className="PlanChange-userAmount-label">Practice Users:</span>
      <button
        className="PlanChange-userAmount-button"
        onClick={decreasePracticeUsers}
        disabled={isFreePlan}
      >
        <MinusIcon />
      </button>
      <input
        className="PlanChange-userAmount-input"
        value={changedUserAmount}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        disabled={isFreePlan}
      />
      <button
        className="PlanChange-userAmount-button"
        onClick={increasePracticeUsers}
        disabled={isFreePlan}
      >
        <PlusIcon />
      </button>

      <p className="PlanChange-userAmount-message">
        {changedUserAmount < providerCount
          ? `${providerCount - changedUserAmount} providers will be removed`
          : ''}
      </p>
    </div>
  );
};

const AnnualCommitment = ({ annualCommitmentState }) => {
  const { variables } = useContext(StateContext);
  const { offerCommitmentOptions } = variables;
  const [annualCommitment, setAnnualCommitment] = annualCommitmentState;

  const handleCommitmentChange = ({ target }) => {
    const { checked } = target;
    setAnnualCommitment(checked);
  };

  return offerCommitmentOptions ? (
    <div className="PlanChange-annualCommitment">
      <input
        type="checkbox"
        id="annualCommitment"
        name="annualCommitment"
        checked={annualCommitment}
        className="PlanChange-annualCommitment-input"
        onChange={handleCommitmentChange}
      />
      <label
        htmlFor="annualCommitment"
        className="PlanChange-annualCommitment-label"
      >
        Save 15% with an Annual Commitment, Billed Monthly.
      </label>
    </div>
  ) : null;
};

const PremiumPlanSummary = ({ annualCommitment, changedUserAmountState }) => {
  const [changedUserAmount] = changedUserAmountState;
  const planSubtotal = calcTierPricing(changedUserAmount, false);
  const discount = getAnnualDiscount(planSubtotal.price, !annualCommitment);
  const totalMonthlyCost = getMoneyPrice(planSubtotal.price - discount.price);

  const MonthlyPayment = () => (
    <p className="PlanChange-summary-line">
      <span className="PlanChange-summary-line-label">Monthly Payment</span>
      <span className="PlanChange-summary-line-value">
        {planSubtotal.money}
      </span>
    </p>
  );

  const AnnualDiscount = () =>
    annualCommitment ? (
      <p className="PlanChange-summary-line">
        <span className="PlanChange-summary-line-label">Annual Discount</span>
        <span className="PlanChange-summary-line-value discount">
          - {discount.money}
        </span>
      </p>
    ) : null;

  const TotalMonthlyCost = () => (
    <p className="PlanChange-summary-line total">
      <span className="PlanChange-summary-line-label">Total Monthly Cost:</span>
      <span className="PlanChange-summary-line-value">
        {totalMonthlyCost.money}
      </span>
    </p>
  );

  return (
    <div className="PlanChange-summary">
      <div className="PlanChange-summary-subtotals">
        <MonthlyPayment />
        <AnnualDiscount />
      </div>
      <div className="PlanChange-summary-total">
        <TotalMonthlyCost />
      </div>
    </div>
  );
};

const PlanChangeTotal = ({ subscription, userAmount }) => {
  const monthlyPayment = subscription
    ? calcMonthlyPayment(subscription, userAmount)
    : null;

  const amount = monthlyPayment.price ? monthlyPayment.money : 'Free';
  return (
    <div className="PlanChange-total">
      <span className="PlanChange-total-label">New Monthly Payment:</span>
      <span className="PlanChange-total-amount">{amount}</span>
    </div>
  );
};

const PlanChangeContinue = ({ onContinue: handleContinue }) => (
  <>
    <button className="PlanChange-confirm" onClick={handleContinue}>
      Confirm Changes
    </button>

    <p className="PlanChange-disclaimer">
      Changes made here will be invoiced immediately.
    </p>
  </>
);
