import React from 'react';
import './MessagePopup.scss';

export default function MessagePopup({ message, setShowPopup }) {
  const button = React.useRef();

  const clickHandler = React.useCallback(
    () => {
      button.current.removeEventListener('mousedown', clickHandler);
      setShowPopup(false);
    },
    [setShowPopup]
  );

  React.useEffect(() => {
    button.current.addEventListener('mousedown', clickHandler);
  }, [button, clickHandler]);

  return (
    <>
      <div className="MessagePopup">
        <p>{message}</p>
        <button ref={button} className="primary-button">
          OK
        </button>
      </div>
      <div className="MessagePopupOverlay"></div>
    </>
  );
}
