import { navigate } from "gatsby"
import React, { useCallback, useState, useContext, useEffect } from "react"
import {
  CHECKOUT_BILLING_SECTION_KEY,
  CHECKOUT_CONTACT_SECTION_KEY,
  CHECKOUT_SIGNATURE_SECTION_KEY,
  CHECKOUT_PLAN_SECTION_KEY,
  CHECKOUT_PRACTICE_USER_SECTION_KEY,
} from "../../lib/events/checkout"
import { DispatchContext, StateContext } from "../../lib/providers"
import { CHECKOUT_INIT_FLOW } from "../../lib/events/checkout/types"
import { makeProviderObject } from "../../lib/utils"
import { filterValidUsers } from "../../lib/validators/ValidatePracticeUsers"
import './scss/CheckoutFlow.scss'
import { SplashScreen } from '../misc'
import {
  CheckoutOrderSummary,
  CheckoutBillingInformation,
  CheckoutContactInformation,
  CheckoutSignature,
  CheckoutSelectPlan,
  CheckoutPracticeUsers
} from "."

const testMode = false

export default function CheckoutFlow() {
  const [processing, setProcessing] = useState(false)
  const [checkoutError, setCheckoutError] = useState(false)
  const dispatch = useContext(DispatchContext)
  const { checkout } = useContext(StateContext)
  const planField = checkout[CHECKOUT_PLAN_SECTION_KEY]
  const contactField = checkout[CHECKOUT_CONTACT_SECTION_KEY]
  const signatureField = checkout[CHECKOUT_SIGNATURE_SECTION_KEY]
  const billingField = checkout[CHECKOUT_BILLING_SECTION_KEY]
  const usersField = checkout[CHECKOUT_PRACTICE_USER_SECTION_KEY]

  const { freePlan } = planField

  /**
   * [EXAMPLE PAYLOAD]
   *  {
   *    "firstName": "Test",
   *    "lastName": "Account",
   *    "email": "test@ripentester2buyer.com",
   *    "password": "Th1s!satest",
   *    "address": "111 Street Rd",
   *    "city": "Sometown",
   *    "country": "US",
   *    "state": "NJ",
   *    "postalCode": "08611",
   *    "tokenId": "",
   *    "planCode": "awn-freemium",
   *    "addOnCode" : ""
   *    "addOnQuantity" : 1
   *    "providers": [
   *        {
   *            "FirstName":"Test",
   *            "LastName":"Account",
   *            "Title":"Tester",
   *            "Seat_Status__c":"Active",
   *            "Email":"test@ripentester2provider.com"
   *        }
   *    ]
   *  }
   */
  const handleCheckout = useCallback(async () => {
    const { total, discount, subtotal, coupon } = checkout

    const { providerAccounts, subscription } = planField
    const { billingInfo, ccLastFour, ccBrand, recurlyToken } = billingField
    const { contactInfo } = contactField
    const { signature } = signatureField
    const { users } = usersField
    const { code, addOns } = subscription


    // Pull first addon item assigned to currently selected plan
    const [practiceUserAddOn] = addOns

    const handlePurchase = async payload => {
      setProcessing(true)

      localStorage.setItem("amwell-order", JSON.stringify(payload))

      if (testMode) {
        setTimeout(() => {
          setProcessing(false)
          navigate("/checkout/confirmation")
        }, 2500)
      } else {
        const res = await fetch(
          `${process.env.GATSBY_AMWELL_API_ENDPOINT}/checkout/purchase`,
          {
            method: "POST",
            body: JSON.stringify(payload),
          }
        )
        if (!res.ok) {
          console.error(res)
          const json = await res.json()
          console.log("Error Response", json)
          setCheckoutError(json.error)
          // TODO dispatch({type: CHECKOUT_SET_CHECKOUT_ERROR})
          setProcessing(false)
        } else {
          const json = await res.json()
          setProcessing(true)
          console.log("Success Response", json)
          setTimeout(() => {
            const data = window.dataLayer || [];
            const eventName = 'purchase';
            const item = {
              item_name: checkout.plan.subscription.name,
              item_id: checkout.plan.subscription.id,
              price: checkout.total.price,
              item_brand: '',
              item_category: '',
              item_variant: checkout.plan.subscription.code,
              quantity: checkout.plan.providerAccounts
            };

            data.push({
              event: eventName,
              ecommerce: {
                currency: "USD",
                value: checkout.total.price,
                tax: "",
                shipping: "",
                affiliation: "",
                transaction_id: "",
                coupon: "",
                items: [item]
              }
            });
          }, 50);
          // dispatch({type: CHECKOUT_CHECKOUT_COMPLETED})
          await navigate("/checkout/confirmation")
        }
      }
    }

    // Initial purchase payload
    let payload = {
      country: "US",
    }
    // Set account information
    payload = { ...payload, ...contactInfo }
    // Set address information
    const address = `${contactInfo.address1} ${contactInfo.address2}`
    payload.address = address.trim()

    // Set postal code
    payload.postalCode = contactInfo.zip

     payload.signature = signature;

    // Create a list of and set user/provider objects
    if (users.length) {
      const validUsers = filterValidUsers(users)
      payload.providers = validUsers.map(makeProviderObject)
    }

    // Set Recurly Subscription Object
    payload.subscription = subscription

    // Set Users Object
    payload.users = users

    // Set Billing Object
    payload.billing = {
      ccLastFour,
      ccBrand,
      billingInfo,
    }

    // Set Recurly token and tokenID
    if(recurlyToken){
      payload.token = recurlyToken
      payload.tokenId = recurlyToken.id
    }

    // Set Totals Object
    payload.total = total
    payload.subtotal = subtotal
    payload.discount = discount

    // Set Recurly Subscription code
    payload.planCode = code

    // Set Recurly addon "addOnCode" as item code and "addOnQuantity" as providerAccounts
    if (practiceUserAddOn) {
      payload.addOnCode = practiceUserAddOn.code
      payload.addOnQuantity = providerAccounts
    }

    if(coupon){
      payload.couponCode = coupon.code
    }

    await handlePurchase(payload)
  }, [usersField, planField, contactField, signatureField, billingField, checkout])

  /**
   * Fires on initial render
   */
  useEffect(() => {
    dispatch({ type: CHECKOUT_INIT_FLOW })
  }, [dispatch])

  return (
    <>
    <div className={`CheckoutFlow ${processing ? "processing" : ""}`}>
      <div className="Checkout-container">
        <div className="Checkout-flow">
          <CheckoutSelectPlan />
          <CheckoutContactInformation />
          <CheckoutSignature />
          {freePlan ? null : <CheckoutBillingInformation />}
          <CheckoutPracticeUsers />
        </div>
        <div className="Checkout-summary">
          <CheckoutOrderSummary onCheckout={handleCheckout} />
          <CheckoutErrorMessage checkoutError={checkoutError} />
        </div>
      </div>
    </div>
    {processing ? <SplashScreen/> : null }
    </>
  )
}

const CheckoutErrorMessage = ({checkoutError}) => checkoutError ? <p className="Checkout-summary-error">Error: {checkoutError}</p> : null
