import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from '../../misc';
import './PlanInformation.scss';
import { DispatchContext, StateContext } from '../../../lib/providers';
import { Amwell } from '../../../lib/services';
import { APP_SET_USER_SUBSCRIPTION_DATA } from '../../../lib/events/app/types';
import {
  calcMonthlyPayment,
  // getPlanDisplayName,
  sleep,
} from '../../../lib/utils';

export default function PlanInformation() {
  const [loading, setLoading] = useState(true);
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false);
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { user } = state;
  const { subscription } = user;
  const planName = subscription ? subscription.plan.name : null;
  const totalUsers = subscription ? subscription.addOns[0].quantity : null;
  const monthlyPayment = subscription
    ? calcMonthlyPayment(subscription, totalUsers)
    : null;
  const renewDate = subscription
    ? new Date(subscription.currentTermEndsAt).toLocaleDateString()
    : null;

  console.log('subscription', { subscription });
  // const isFreePlan = planName.toLowerCase().includes('free')

  const handlePlanCancelConfirm = async () => Amwell.cancelSubscription();

  const handlePlanCancelClose = () => {
    console.log('handlePlanCancelClose');
    setCancelPopupOpen(false);
  };

  useEffect(() => {
    if (!subscription) {
      (async () => {
        const subscriptionData = await Amwell.fetchSubscriptionInformation();
        dispatch({
          type: APP_SET_USER_SUBSCRIPTION_DATA,
          payload: { subscription: subscriptionData[0] },
        });
      })();
    }

    setLoading(!subscription);
  }, [subscription, dispatch]);

  return (
    <>
      <div className="PlanInformation">
        <h2 className="PlanInformation-heading">Plan Information</h2>

        <div className="PlanInformation-container">
          <div className="PlanInformation-info">
            <PlanInformationInfoLine
              label="Plan:"
              content={!loading ? `${planName}` : <LoadingSpinner />}
            />
            <PlanInformationInfoLine
              label="Total Users:"
              content={!loading ? `${totalUsers}` : <LoadingSpinner />}
            />
            <PlanInformationInfoLine
              label="Monthly Payment:"
              content={
                !loading ? `${monthlyPayment.money}` : <LoadingSpinner />
              }
            />
            <PlanInformationInfoLine label="Renews on:" content={renewDate} />
          </div>
          {!loading ? (
            <PlanInformationOptions
              setCancelPopupOpen={setCancelPopupOpen}
              isFree={subscription.isFree}
            />
          ) : null}
        </div>
      </div>
      <PlanInformationCancelModal
        openStatus={cancelPopupOpen}
        onConfirm={handlePlanCancelConfirm}
        onCancel={handlePlanCancelClose}
      />
    </>
  );
}

const PlanInformationOptions = ({ isFree, setCancelPopupOpen }) => (
  <div className="PlanInformation-planOptions">
    {isFree ? (
      <Link
        className="PlanInformation-planOptions-button"
        to="/dashboard/plan/change"
      >
        Change Plan
      </Link>
    ) : (
      <Link
        className="PlanInformation-planOptions-button"
        to="/dashboard/plan/change"
      >
        Change Plan or User Count
      </Link>
    )}
    <button
      className="PlanInformation-planOptions-cancel"
      onClick={() => setCancelPopupOpen(true)}
    >
      Cancel plan
    </button>
  </div>
);

const PlanInformationInfoLine = ({ label, content }) => (
  <p className="PlanInformation-info-line">
    <span className="PlanInformation-info-line-label">{label}</span>
    <span className="PlanInformation-info-line-content">{content}</span>
  </p>
);

const PlanInformationCancelModal = ({
  openStatus,
  onConfirm: handleConfirm,
  onCancel: handleCancel,
}) => {
  const [cancelState, setCancelState] = useState('cancel-confirm');

  /**
   * Handle Plan Cancel
   */
  const confirmPlanCancel = async () => {
    setCancelState('cancel-processing');
    try {
      await handleConfirm();
      setCancelState('cancel-success');
      await sleep(5000);
      await navigate('/signout');
      // TODO: handle sign out
    } catch (error) {
      setCancelState('cancel-failed');
    }
  };

  /**
   * Cancel Plan Cancel
   */
  const cancelPlanCancel = () => {
    handleCancel();
    setCancelState('cancel-confirm');
  };

  // eslint-disable-next-line consistent-return
  const InformationBox = () => {
    switch (cancelState) {
      /**
       * View to confirm cancellation
       */
      case 'cancel-confirm':
        return (
          <div className="PlanInformation-cancel-box">
            <button
              className="PlanInformation-cancel-box-close"
              onClick={cancelPlanCancel}
            >
              Close
            </button>
            <span className="PlanInformation-cancel-box-heading">
              We’re sorry to see you go!
            </span>
            <span className="PlanInformation-cancel-box-copy">
              Are you sure you want to cancel your plan? Changes will take
              effect immediately.
            </span>
            <div className="PlanInformation-cancel-box-methods">
              <button
                className="PlanInformation-cancel-box-button confirmPlanCancel"
                onClick={confirmPlanCancel}
              >
                Yes, Cancel My Plan
              </button>
              <button
                className="PlanInformation-cancel-box-button cancelPlanCancel"
                onClick={cancelPlanCancel}
              >
                No, Keep My Plan
              </button>
            </div>
          </div>
        );
      /**
       * View to give cancel process indication
       */
      case 'cancel-processing':
        return (
          <div className="PlanInformation-cancel-box">
            <LoadingSpinner message={'Processing'} />
          </div>
        );
      /**
       * View to inform cancel success
       */
      case 'cancel-success':
        return (
          <div className="PlanInformation-cancel-box">
            <button
              className="PlanInformation-cancel-box-close"
              onClick={cancelPlanCancel}
            >
              Close
            </button>
            <span className="PlanInformation-cancel-box-heading">
              Your plan has been cancelled
            </span>
            <span className="PlanInformation-cancel-box-copy">
              You will now be signed out
            </span>
          </div>
        );
      /**
       * View to inform cancellation failure
       */
      case 'cancel-failed':
        return (
          <div className="PlanInformation-cancel-box">
            <button
              className="PlanInformation-cancel-box-close"
              onClick={cancelPlanCancel}
            >
              Close
            </button>
            <span className="PlanInformation-cancel-box-heading">
              Sorry, there seems to be an issue
            </span>
            <span className="PlanInformation-cancel-box-copy">
              We’re unable to cancel your plan because you have an annual
              commitment. You may cancel your plan within the last month.
            </span>
            <span className="PlanInformation-cancel-box-copy">
              For more information or to get help please email{' '}
              <a href="emailto:private.practice@amwell.com">
                private.practice@amwell.com
              </a>
            </span>
          </div>
        );

      default:
        break;
    }
  };

  return openStatus ? (
    <div className="PlanInformation-cancel">
      <InformationBox />
    </div>
  ) : null;
};
