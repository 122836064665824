import React, {
  useContext,
  useEffect,
  useState,
  memo,
  useCallback,
} from 'react';
import {
  CHECKOUT_CONTACT_SECTION_KEY,
  CHECKOUT_PLAN_SECTION_KEY,
  CHECKOUT_PRACTICE_USER_SECTION_KEY,
} from '../../lib/events/checkout';
import { CaretIcon } from '../icons';
import { practitionerSpecialty, practitionerTitles } from './misc';
import { DispatchContext, StateContext } from '../../lib/providers';
import {
  CHECKOUT_PROGRESS_TO_NEXT_SECTION,
  CHECKOUT_REVISE_SECTION,
  CHECKOUT_SET_USERS,
} from '../../lib/events/checkout/types';
import { generateNewUserObj } from '../../lib/utils';
import { ValidatePracticeUsers } from '../../lib/validators';
import { validateUser } from '../../lib/validators/ValidatePracticeUsers';
import CheckoutFormField from './CheckoutFormField';
import CheckoutSectionContinue from './CheckoutSectionContinue';
import CheckoutSectionHeading from './CheckoutSectionHeading';
import './scss/CheckoutPracticeUsers.scss';

const sectionKey = CHECKOUT_PRACTICE_USER_SECTION_KEY;

export default function CheckoutPracticeUsers() {
  const [invalidUsers, setInvalidUsers] = useState({});
  const [users, setUsers] = useState([]);
  const [shownUsersLimit, setShownUsersLimit] = useState(5);
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { checkout } = state;
  const checkoutPlan = checkout[CHECKOUT_PLAN_SECTION_KEY];
  const checkoutContact = checkout[CHECKOUT_CONTACT_SECTION_KEY];
  const { freePlan, providerAccounts } = checkoutPlan;
  const { contactInfo } = checkoutContact;
  const { status, skipUsersSetup } = checkout[sectionKey];
  const completed = status === 'completed';

  /**
   * Trigger section editing
   */
  const handleSectionEdit = () => {
    const payload = { sectionKey };
    dispatch({ type: CHECKOUT_REVISE_SECTION, payload });
  };

  /**
   * Trigger section editing
   */
  const handleOnContinue = useCallback(
    ({ skipSetup }) => {
      const usersField = checkout[sectionKey];
      usersField.skipUsersSetup = skipSetup;

      if (freePlan) {
        users[0].firstName = contactInfo.firstName;
        users[0].lastName = contactInfo.lastName;
        users[0].email = contactInfo.email;
      }

      // (Performance Note): the users list are stored at a component level then dispatched to state
      usersField.users = users;
      dispatch({ type: CHECKOUT_SET_USERS, payload: { users } });

      const validation = ValidatePracticeUsers(state, usersField);
      setInvalidUsers(validation.users);

      if (validation.isValid) {
        const payload = { sectionKey };
        dispatch({ type: CHECKOUT_PROGRESS_TO_NEXT_SECTION, payload });
      } else console.error(validation.error);
    },
    [users, dispatch, state, checkout, contactInfo, freePlan]
  );

  /**
   * Increase shown user forms
   */
  const handleShowMore = () => {
    setShownUsersLimit((val) => val + 5);
  };

  /**
   * Manages user field creation
   */
  useEffect(() => {
    if (!users.length) {
      const initialUser = generateNewUserObj();
      setUsers((val) => [...val, initialUser]);
    } else {
      /**
       * Remove excess users
       */
      if (users.length > providerAccounts) {
        setUsers((val) => val.splice(providerAccounts));
      }

      /**
       * Add additional users
       */
      if (users.length < providerAccounts) {
        const amount = providerAccounts - users.length;
        const newUsers = [];
        for (let i = 0; i < amount; i++) {
          newUsers.push(generateNewUserObj());
        }
        setUsers((val) => [...val, ...newUsers]);
      }
    }
  }, [providerAccounts, users]);

  const InfoText = () =>
    freePlan ? (
      <p className="CheckoutPracticeUsers-text">
        Please create your 1 FREE Practice User. You can always upgrade to more
        users later in your Amwell Account.
      </p>
    ) : (
      <>
        <p className="CheckoutPracticeUsers-text">
          Tell us about your Practice User(s). You can always upgrade to more
          users later in your account.
        </p>
      </>
    );

  return (
    <div className={`CheckoutPracticeUsers checkoutCard ${status}`}>
      <CheckoutSectionHeading
        step={freePlan ? 4 : 5}
        label={'Set Up Practice Users'}
        status={status}
        onEdit={handleSectionEdit}
      />
      <div className="CheckoutPracticeUsers-container">
        <InfoText />
        <div className="CheckoutPracticeUsers-users">
          {freePlan ? (
            <SinglePracticeUserForm
              users={users}
              invalidUsers={invalidUsers}
              freePlan={freePlan}
              setUsers={setUsers}
            />
          ) : (
            <>
              {users.map((user, index) =>
                index < shownUsersLimit ? (
                  <PracticeUserDropdownForm
                    key={`PracticeUserDropdownForm-${index}`}
                    user={user}
                    invalidUsers={invalidUsers}
                    index={index}
                    setUsers={setUsers}
                  />
                ) : null
              )}
              <ShowMoreUsers
                onClick={handleShowMore}
                users={users}
                shownUsersLimit={shownUsersLimit}
              />
            </>
          )}
        </div>
        <CheckoutContinue onContinue={handleOnContinue} freePlan={freePlan} />
      </div>
      <PracticeUserPreview
        users={users}
        completed={completed}
        skipUsersSetup={skipUsersSetup}
      />
    </div>
  );
}

const ShowMoreUsers = ({ onClick: handleClick, users, shownUsersLimit }) => {
  const shouldShow = users.length > shownUsersLimit;
  return shouldShow ? (
    <button
      className="CheckoutPracticeUsers-users-showMore"
      onClick={handleClick}
    >
      <span className="CheckoutPracticeUsers-users-showMore-label">
        Show More
      </span>
      <span className="CheckoutPracticeUsers-users-showMore-icon">
        <CaretIcon />
      </span>
    </button>
  ) : null;
};

const SinglePracticeUserForm = memo(
  ({ users, freePlan, setUsers, invalidUsers }) =>
    users.map((user, index) => (
      <PracticeUserForm
        key={`PracticeUserForm-${index}`}
        index={index}
        user={user}
        freePlan={freePlan}
        setUsers={setUsers}
        userValidation={invalidUsers[index]}
      />
    ))
);

const PracticeUserDropdownForm = memo(
  ({ user, index, setUsers, invalidUsers }) => {
    const [isOpen, setIsOpen] = useState(!index);
    const openClass = isOpen ? 'open' : '';
    const userValidation = invalidUsers[index];
    const hasError =
      userValidation && userValidation.foundErrors.length ? 'hasError' : null;
    const handleHandleClick = () => {
      setIsOpen((val) => !val);
    };

    return (
      <div className={`CheckoutPracticeUsers-user ${openClass} ${hasError}`}>
        <div className="CheckoutPracticeUsers-user-handle">
          <div className="CheckoutPracticeUsers-user-handle-label">
            <button
              className="CheckoutPracticeUsers-user-handle-label-user"
              onClick={handleHandleClick}
            >
              Practice User {index + 1}:
            </button>
            {isOpen ? null : (
              <button
                className="CheckoutPracticeUsers-user-handle-label-edit"
                onClick={handleHandleClick}
              >
                Edit
              </button>
            )}
          </div>
        </div>
        <div className="CheckoutPracticeUsers-user-container">
          <PracticeUserForm
            key={`PracticeUserForm-${index}`}
            index={index}
            user={user}
            setUsers={setUsers}
            userValidation={userValidation}
          />
        </div>
      </div>
    );
  }
);

const PracticeUserForm = memo(
  ({ index, freePlan, user, setUsers, userValidation }) => {
    const { checkout } = useContext(StateContext);
    const { contactInfo } = checkout[CHECKOUT_CONTACT_SECTION_KEY];
    const practiceUser = user;
    const { errorFields } = userValidation || { errorFields: {} };

    const handleChange = (evt) => {
      const { target } = evt;
      // eslint-disable-next-line prefer-const
      let { name, value } = target;

      switch (name) {
        case 'npi':
          if (value.length > 10) value = value.substring(0, 10);
          break;
        default:
      }

      setUsers((users) => {
        const newUsers = [...users];
        const newUser = { ...newUsers[index] };
        newUser[name] = value;
        newUsers[index] = newUser;
        return newUsers;
      });
    };

    return (
      <div className="CheckoutPracticeUsers-form">
        {freePlan && contactInfo ? (
          <div className="CheckoutPracticeUsers-form-group free">
            <p>
              <span>Name:</span>
              {`${contactInfo.firstName} ${contactInfo.lastName[0]}.`.trim()}
            </p>
            <p>
              <span>Email Address:</span>
              {contactInfo.email}
            </p>
          </div>
        ) : (
          <>
            <div className="CheckoutPracticeUsers-form-group name">
              <CheckoutFormField
                label={'First Name'}
                name={'firstName'}
                value={practiceUser.firstName}
                id={`practiceUser-${index}-firstName`}
                onChange={handleChange}
                required={true}
                errors={errorFields.firstName}
              />
              <CheckoutFormField
                label={'Last Name'}
                name={'lastName'}
                value={practiceUser.lastName}
                id={`practiceUser-${index}-lastName`}
                onChange={handleChange}
                required={true}
                errors={errorFields.lastName}
              />
            </div>
            <CheckoutFormField
              label={'Email Address'}
              name={'email'}
              value={practiceUser.email}
              id={`practiceUser-${index}-email`}
              onChange={handleChange}
              required={true}
              errors={errorFields.email}
            />
          </>
        )}
        <div className="CheckoutPracticeUsers-form-npi">
          <CheckoutFormField
            label={'National Provider ID'}
            name={'npi'}
            value={practiceUser.npi}
            id={`practiceUser-${index}-npi`}
            onChange={handleChange}
            required={false}
            optionalText={'(optional)'}
            errors={errorFields.npi}
          />
          <button className="info-link">
            Why are we asking for this?
            <div className="info-bubble">
              Your NPI, Credentials / Title and Medical Specialty information
              can help patients identify your practice and verify your medical
              standing. Amwell will not share this information with 3rd parties
              or use it outside of the Amwell platform.
            </div>
          </button>
        </div>

        <CheckoutFormField
          label={'Credentials / Title'}
          name={'title'}
          type={'select'}
          value={practiceUser.title}
          id={`practiceUser-${index}-title`}
          onChange={handleChange}
          required={false}
          optionalText={'(optional - this is to show your patients)'}
          selectOptions={[
            {
              label: '- SELECT CREDENTIALS / TITLE -',
              value: '',
              disabled: true,
              selected: true,
            },
            ...practitionerTitles,
          ]}
          errors={errorFields.title}
        />
        <CheckoutFormField
          label={'Medical Specialty'}
          name={'specialty'}
          type={'select'}
          value={practiceUser.specialty}
          id={`practiceUser-${index}-specialty`}
          onChange={handleChange}
          required={false}
          optionalText={'(optional - this is to show your patients)'}
          selectOptions={[
            {
              label: '- SELECT MEDICAL SPECIALTY -',
              value: '',
              disabled: true,
              selected: true,
            },
            ...practitionerSpecialty,
          ]}
          errors={errorFields.specialty}
        />
      </div>
    );
  }
);

const PracticeUserPreview = ({ users, completed }) => {
  const [showAll, setShowAll] = useState(false);
  const completionClass = completed ? 'show' : '';
  const isOpenClass = showAll ? 'open' : '';
  const validUsers = users.filter((user) => {
    const validation = validateUser(user);
    return !validation.foundErrors.length;
  });
  const incompleteUsers = users.filter((user) => {
    const validation = validateUser(user);
    return validation.foundErrors.length;
  });

  const ShowAllUsers = () =>
    validUsers.length > 2 ? (
      <button
        className={`CheckoutPracticeUsers-userPreviews-showAll ${isOpenClass}`}
        onClick={() => setShowAll(!showAll)}
      >
        <span className="CheckoutPracticeUsers-userPreviews-showAll-label">
          {showAll ? 'Show less' : `See All ${users.length} Practice Users`}
        </span>
        <span className="CheckoutPracticeUsers-userPreviews-showAll-icon">
          <CaretIcon />
        </span>
      </button>
    ) : null;

  const ShortSummary = () => {
    const allUsersAreIncomplete = incompleteUsers.length === users.length;
    const shouldShow =
      allUsersAreIncomplete ||
      (users.length < 2 && incompleteUsers.length) ||
      (validUsers.length < 3 && incompleteUsers.length) ||
      (incompleteUsers.length && showAll);
    return shouldShow ? (
      <div className="CheckoutPracticeUsers-userPreview">
        <span className="CheckoutPracticeUsers-userPreview-line">
          Practice user ({validUsers.length + 1}-{users.length})
        </span>
        <span className="CheckoutPracticeUsers-userPreview-line">
          Log in to complete sign up
        </span>
      </div>
    ) : null;
  };

  return (
    <div className={`CheckoutPracticeUsers-userPreviews ${completionClass}`}>
      {validUsers.map((user, idx) => {
        if (!showAll && idx > 1) return null;
        return (
          <div
            key={`userPreview-${idx}`}
            className="CheckoutPracticeUsers-userPreview"
          >
            <span className="CheckoutPracticeUsers-userPreview-line">
              Practice user ({idx + 1} of {users.length})
            </span>
            <span className="CheckoutPracticeUsers-userPreview-line">
              {`${user.firstName} ${user.lastName}`.trim()}
            </span>
            <span className="CheckoutPracticeUsers-userPreview-line">
              {user.email}
            </span>
          </div>
        );
      })}

      <ShortSummary />
      <ShowAllUsers />
    </div>
  );
};

const CheckoutContinue = ({ onContinue, freePlan, showContinueButton=true }) => {
  const SkipUserSetup = () =>
    freePlan ? null : (
      <button
        className="CheckoutPracticeUsers-continue-skipUsers"
        onClick={() => onContinue({ skipSetup: true })}
      >
        Skip and setup later
      </button>
    );

  return (
    <div className="CheckoutPracticeUsers-continue">
      <SkipUserSetup />
      { showContinueButton ? <CheckoutSectionContinue label={'Continue ›'} onClick={onContinue} /> : ''}
    </div>
  );
};
