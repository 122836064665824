export default function addToAccountQueue({ queueProvider }, user) {
  if (!queueProvider) throw new Error('"queueProvider" was not provided');
  const {
    currentUser: { username },
  } = user;
  const storageKey = `amwell-queue-${username}`;
  console.log('Adding to Account Queue', { queueProvider, user, storageKey });
  let accountQueue;
  accountQueue = JSON.parse(localStorage.getItem(storageKey));
  accountQueue = accountQueue || [];
  accountQueue.push(queueProvider);
  localStorage.setItem(storageKey, JSON.stringify(accountQueue));
  return accountQueue;
}
