import React from 'react';
import './scss/CheckoutSectionContinue.scss';

export default function CheckoutSectionContinue({
  label,
  onClick: handleClick,
  type,
}) {
  switch (type) {
    case 'submit':
      return (
        <button className={'CheckoutSectionContinue'} type="submit">
          {label}
        </button>
      );
    default:
      return (
        <button className={'CheckoutSectionContinue'} onClick={handleClick}>
          {label}
        </button>
      );
  }
}
