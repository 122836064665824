import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import SignaturePad from 'signature_pad';
import { CHECKOUT_SIGNATURE_SECTION_KEY } from '../../lib/events/checkout';
import './scss/CheckoutSignature.scss';
import CheckoutSectionContinue from './CheckoutSectionContinue';
import CheckoutSectionHeading from './CheckoutSectionHeading';
import { DispatchContext, StateContext } from '../../lib/providers';
import {
  CHECKOUT_PROGRESS_TO_NEXT_SECTION,
  CHECKOUT_REVISE_SECTION,
  CHECKOUT_SET_SIGNATURE_INFO,
} from '../../lib/events/checkout/types';
import { ValidateSignature } from '../../lib/validators';

const sectionKey = CHECKOUT_SIGNATURE_SECTION_KEY;
export default function CheckoutSignature() {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { checkout } = state;
  let signaturePad = '';
  const { currentSection } = checkout;
  const { status } = checkout[sectionKey];
  const completed = status === 'completed';
  const [shown, setShown] = useState(false);

  /**
   * Signature Section Edit
   */
  const handleSectionEdit = () => {
    const payload = { sectionKey };
    dispatch({ type: CHECKOUT_REVISE_SECTION, payload });
  };

  /**
   * Returns Signature Validation
   */
  const getValidation = useCallback(
    () => ValidateSignature(state, checkout[sectionKey]),
    [state, checkout]
  );

  /**
   * Progresses the checkout flow
   */
  const progressCheckoutFlow = useCallback(() => {
    const payload = { sectionKey };
    dispatch({
      type: CHECKOUT_SET_SIGNATURE_INFO,
      payload: document.getElementById('signature').value,
    });
    dispatch({ type: CHECKOUT_PROGRESS_TO_NEXT_SECTION, payload });
  }, [dispatch]);

  /**
   * Signature Section Continue
   */
  const handleOnContinue = useCallback(() => {
    const validation = getValidation();
    if (validation && !signaturePad.isEmpty()) {
      document.getElementById('signature-error').style.display = 'none';
      document.getElementById('signature').value = signaturePad.toDataURL();
    }
    //
    if (!signaturePad.isEmpty()) progressCheckoutFlow();
    else {
      document.getElementById('signature-error').style.display = 'block';
      console.error('Empty Canvas');
    }
  }, [progressCheckoutFlow, getValidation, signaturePad]);

  /**
   * Clear canvas
   */
  const handleClear = (evt) => {
    evt.preventDefault();
    if (currentSection !== sectionKey) return;
    signaturePad.clear();
  };

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      if (currentSection !== sectionKey) return;
      handleOnContinue();
    },
    [currentSection, handleOnContinue]
  );

  useEffect(() => {
    const canvas = document.getElementById('signature-canvas');
    if (canvas) {
      if (!signaturePad) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        signaturePad = new SignaturePad(canvas, {
          minWidth: 0.25,
          maxWidth: 0.5,
          dotSize: 0.25,
        });

        signaturePad.clear();

        if (document.getElementById('signature').value) {
          signaturePad.fromDataURL(document.getElementById('signature').value);
        }
      }
    }
  });

  return (
    <>
      <div
        className={`CheckoutSignature checkoutCard ${status}`}
        onSubmit={handleSubmit}
      >
        <CheckoutSectionHeading
          step={3}
          label={'Sign to Obtain HIPAA BAA Agreement'}
          status={status}
          onEdit={handleSectionEdit}
          showInfoIcon={true}
        />
        <div className={'CheckoutSignature-container'}>
          <button
            className="CheckoutSignature-label-link"
            onClick={() => setShown(true)}
          >
            Preview BAA Document
          </button>
          <div className="checkoutCard">
            <canvas id="signature-canvas" width="550" />
          </div>
          <input
            type={'hidden'}
            name={'signature'}
            id={'signature'}
            required={true}
          />
          <span
            className="CheckoutFormField-label-errorMessage"
            id={'signature-error'}
          >
            This field is required
          </span>
          <CheckoutClear onClick={handleClear} />
          <CheckoutContinue onClick={handleSubmit} />
        </div>
        <CheckoutSignaturePreview completed={completed} />
      </div>
      {shown && ReactDOM.createPortal(ModalBody(setShown), document.body)}
    </>
  );
}

const CheckoutSignaturePreview = ({ completed }) => {
  const completionClass = completed ? 'show' : '';

  return (
    <div className={`CheckoutSignature-planPreview ${completionClass}`}>
      <span className="CheckoutSignature-planPreview-line">Link</span>
    </div>
  );
};

const CheckoutContinue = ({ onClick: handleClick }) => (
  <div className="CheckoutSignature-continue">
    <CheckoutSectionContinue label={'Continue ›'} onClick={handleClick} />
  </div>
);

const CheckoutClear = ({ onClick: handleClear }) => (
  <div className="CheckoutSignature-clear">
    <button className={'CheckoutSectionClear'} onClick={handleClear}>
      {'Clear ›'}
    </button>
  </div>
);

const ModalBody = (setShown) => (
  <div
    style={{
      backgroundColor: '#fff',

      /* Fixed position */
      left: 0,
      position: 'fixed',
      top: 0,

      /* Take full size */
      height: '100%',
      width: '100%',

      /* Displayed on top of other elements */
      zIndex: 9999,

      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    }}
  >
    <button
      style={{
        backgroundColor: 'var(--blue)',
        border: 'none',
        borderRadius: '4px',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px',
      }}
      onClick={() => setShown(false)}
    >
      Close
    </button>
    <iframe
      title="BAA"
      style={{
        border: 0,
        top: '15px',
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
      src="https://business.amwell.com/siteassets/documents/private-practice/amwell_private_practice_terms_and_conditions.pdf"
    ></iframe>
  </div>
);
