import { throwEndpointError } from '../../../utils';

export default async function checkCouponCode(code) {
  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/checkout/coupon/${code}`,
    { method: 'POST' }
  ).catch((err) => throwEndpointError(err, res, 'Error checking coupon code'));

  if (!res.ok) {
    throwEndpointError({}, res, 'Error checking coupon code');
    return;
  }
  const data = await res.json();
  console.log('Coupon Information', data);
  // eslint-disable-next-line consistent-return
  return data;
}
