import React, { useContext, memo, useState } from 'react';
import {
  CHECKOUT_CONTACT_SECTION_KEY,
  CHECKOUT_PLAN_SECTION_KEY,
} from '../../lib/events/checkout';
import CheckoutFormField from './CheckoutFormField';
import './scss/CheckoutContactForm.scss';
import EyeIcon from '../icons/EyeIcon';
import { organizationSizeValues, usStateOptions } from './misc';
import { DispatchContext, StateContext } from '../../lib/providers';
import { CHECKOUT_SET_CONTACT_INFO } from '../../lib/events/checkout/types';

export default memo(({ fieldErrors }) => {
  const [revealPassword, setRevealPassword] = useState(true);
  const dispatch = useContext(DispatchContext);
  const { checkout } = useContext(StateContext);
  const contactSection = checkout[CHECKOUT_CONTACT_SECTION_KEY];
  const { freePlan } = checkout[CHECKOUT_PLAN_SECTION_KEY];
  const { contactInfo } = contactSection;

  /**
   * Destructures the target name and value to dispatch as state update event
   */
  const handleChange = (evt) => {
    const { target } = evt;
    const { name, value } = target;
    dispatch({ type: CHECKOUT_SET_CONTACT_INFO, payload: { name, value } });
  };

  const toggleRevealPassword = () => {
    setRevealPassword((val) => !val);
  };

  const handleInputFocus = ({ target }) => {
    if (contactInfo.password) {
      setRevealPassword(false);
    }
  };

  return (
    <fieldset className="CheckoutContactForm">
      <CheckoutFormField
        label={'Organization Name'}
        name={'company'}
        type={'text'}
        value={contactInfo.company}
        id={'contact-company'}
        onChange={handleChange}
        onFocus={handleInputFocus}
        required={true}
        optionalText={'(this may be shown to patients)'}
        errors={fieldErrors.company}
      />
      <CheckoutFormField
        label={'Organization Size'}
        name={'practiceSize'}
        type={'select'}
        value={contactInfo.practiceSize}
        id={'contact-practiceSize'}
        onChange={handleChange}
        onFocus={handleInputFocus}
        required={false}
        optionalText={'(optional)'}
        className={'practiceSize'}
        errors={fieldErrors.practiceSize}
        selectOptions={[
          {
            label: '- Select Organization Size -',
            value: '',
            disabled: true,
            selected: true,
          },
          ...organizationSizeValues,
        ]}
      />
      <div className="CheckoutContactForm-group name">
        <CheckoutFormField
          label={'First Name'}
          name={'firstName'}
          type={'text'}
          value={contactInfo.firstName}
          id={'contact-firstName'}
          onChange={handleChange}
          onFocus={handleInputFocus}
          required={true}
          errors={fieldErrors.firstName}
        />
        <CheckoutFormField
          label={'Last Name'}
          name={'lastName'}
          type={'text'}
          value={contactInfo.lastName}
          id={'contact-lastName'}
          onChange={handleChange}
          onFocus={handleInputFocus}
          required={true}
          errors={fieldErrors.lastName}
        />
      </div>

      <CheckoutFormField
        label={'Email Address'}
        type="email"
        name={'email'}
        value={contactInfo.email}
        id={'contact-email'}
        onChange={handleChange}
        onFocus={handleInputFocus}
        required={true}
        errors={fieldErrors.email}
      />
      <div className="CheckoutContactForm-password">
        <div className="CheckoutContactForm-password-input">
          <CheckoutFormField
            label={'Create Admin Password'}
            type={revealPassword ? 'text' : 'password'}
            name={'password'}
            value={contactInfo.password}
            id={'contact-password'}
            onChange={handleChange}
            onFocus={handleInputFocus}
            required={true}
            errors={fieldErrors.password}
          />

          <button
            className={`CheckoutContactForm-password-reveal ${revealPassword}`}
            onClick={toggleRevealPassword}
          >
            <EyeIcon title={'Toggle display of the password being entered.'} />
          </button>
        </div>

        <div className="CheckoutContactForm-password-requirements">
          <p>Password must include:</p>
          <p>- At least 10 characters</p>
          <p>- At least one letter and one number</p>
        </div>
      </div>
      {freePlan ? null : (
        <>
          <CheckoutFormField
            label={'Street Address'}
            name={'address1'}
            value={contactInfo.address1}
            id={'contact-address1'}
            onChange={handleChange}
            onFocus={handleInputFocus}
            required={true}
            errors={fieldErrors.address1}
          />
          <CheckoutFormField
            name={'address2'}
            value={contactInfo.address2}
            placeholder="Apt / Suite"
            id={'contact-address2'}
            onChange={handleChange}
            onFocus={handleInputFocus}
            required={false}
            errors={fieldErrors.address2}
          />
          <CheckoutFormField
            label={'City'}
            name={'city'}
            value={contactInfo.city}
            id={'contact-city'}
            onChange={handleChange}
            onFocus={handleInputFocus}
            required={true}
            errors={fieldErrors.city}
          />
        </>
      )}
      <div className="CheckoutContactForm-group state-zip">
        <CheckoutFormField
          label={'State'}
          name={'state'}
          type={'select'}
          value={contactInfo.state}
          id={'contact-state'}
          onChange={handleChange}
          onFocus={handleInputFocus}
          required={true}
          className={'stateField'}
          selectOptions={[
            {
              label: '- Select State -',
              value: '',
              disabled: true,
              selected: true,
            },
            ...usStateOptions,
          ]}
          errors={fieldErrors.state}
        />

        {freePlan ? null : (
          <CheckoutFormField
            label={'Zip Code'}
            name={'zip'}
            value={contactInfo.zip}
            id={'contact-zip'}
            onChange={handleChange}
            onFocus={handleInputFocus}
            required={true}
            className={'zipField'}
            errors={fieldErrors.zip}
          />
        )}
      </div>
    </fieldset>
  );
});
