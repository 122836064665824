import React, { memo } from 'react';
import { CheckoutNavigationBar, NavigationBar } from '..';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

/**
 * Layout for site
 *
 * @param children
 * @param type
 * @return {JSX.Element}
 * @constructor
 */
export default function ViewLayout({ children, type }) {
  const Navigation = memo(() => {
    switch (type) {
      case 'checkout':
        return <CheckoutNavigationBar />;
      case 'landing':
      case 'signin':
      case 'forgotPassword':
        return <Navbar />;
      default:
        return <NavigationBar />;
    }
  });

  const PageFooter = memo(() => {
    switch (type) {
      case 'checkout':
        return null;
      default:
        return <Footer />;
    }
  });

  return (
    <div className="layout">
      <Navigation />
      {children}
      <PageFooter />
    </div>
  );
}
