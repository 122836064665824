export default function removeFromAccountQueue(queueProvider, user) {
  const {
    currentUser: { username },
  } = user;
  const storageKey = `amwell-queue-${username}`;
  console.log('Removing From Account Queue', {
    queueProvider,
    user,
    storageKey,
  });
  let accountQueue;
  // eslint-disable-next-line prefer-const
  accountQueue = JSON.parse(localStorage.getItem(storageKey));
  if (accountQueue) {
    const index = accountQueue.findIndex(
      (item) => item.email === queueProvider.email
    );
    accountQueue.splice(index, 1);
    localStorage.setItem(storageKey, JSON.stringify(accountQueue));
  }
  return accountQueue;
}
