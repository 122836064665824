/**
 * Use primarily to validate billing information
 */
export default function ValidateBillingInformation(information, options = {}) {
  const { useContactInfo, billingInfo, recurlyToken, freePlan } = information;

  const validation = {
    isValid: false,
    error: '',
    fields: {},
  };

  if (!useContactInfo) {
    for (const key in billingInfo) {
      if (billingInfo[key]) {
        const value = billingInfo[key];
        let foundErrors = [];
        console.log('Validating:', { key, value });
        switch (key) {
          case 'firstName':
            foundErrors = isNotEmpty(value, foundErrors);
            break;
          case 'lastName':
            foundErrors = isNotEmpty(value, foundErrors);
            break;
          case 'address1':
            foundErrors = isNotEmpty(value, foundErrors);
            break;
          case 'city':
            foundErrors = isNotEmpty(value, foundErrors);
            break;
          case 'state':
            foundErrors = isNotEmpty(value, foundErrors);
            break;
          case 'zip':
            foundErrors = isNotEmpty(value, foundErrors);
            foundErrors = isValidZipCode(value, foundErrors);
            break;
          default:
        }

        if (foundErrors.length) {
          validation.isValid = false;
          validation.fields[key] = foundErrors;
        }
      } else {
        validation.isValid = false;
      }
    }
  }

  if (options.checkToken && !freePlan) {
    const foundErrors = [];
    if (!recurlyToken) {
      foundErrors.push('This field is required');
    }
    if (foundErrors.length) {
      validation.isValid = false;
      validation.fields.recurlyToken = foundErrors;
    }
  }

  if (!Object.keys(validation.fields).length || freePlan) {
    validation.isValid = true;
  } else {
    validation.error = 'Invalid billing information';
  }

  console.log('ValidateCheckoutBillingInformation', {
    validation,
    freePlan,
  });

  return validation;
}

const isNotEmpty = (value, foundErrors) => {
  if (!value || value.trim() === '' || value === '0') {
    foundErrors.push('This field is required');
  }
  return foundErrors;
};

const isValidZipCode = (value, foundErrors) => {
  const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  if (!isValidZip.test(value)) {
    foundErrors.push('Please provide a valid zip code');
  }
  return foundErrors;
};
