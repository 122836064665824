import removeFromAccountQueue from "./removeFromAccountQueue"

export default function getUpdatingQueuedProviders(providers, queuedProviders, user) {
  const updatingQueuedProviders = queuedProviders
    .filter(provider => provider.updating)
    .map(obj => {
      // eslint-disable-next-line no-param-reassign
      delete obj.updating
      return obj
    })

  updatingQueuedProviders.forEach((updatingProvider, i) => {
    const targetProvider = providers.find(
      provider => provider.Email === updatingProvider.Email
    )
    // Attempt to compare cache and fetched provider values
    if (targetProvider) {
      let unfinished
      console.log("A updating provider has been found", targetProvider)
      for (const key in updatingProvider) {
        if (!unfinished) {
          const updatedField = updatingProvider[key]
          const currentField = targetProvider[key]
          const isSimilar = currentField === updatedField
          if (!isSimilar) unfinished = true
        } else {
          break
        }
      }

      if (!unfinished) {
        console.log("A updating provider has finished updating", targetProvider)
        updatingQueuedProviders.splice(i, 1)
        removeFromAccountQueue(targetProvider, user)
      }
    }
  })

  return updatingQueuedProviders
}
