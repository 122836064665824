import React, { memo, useContext, useEffect, useState } from 'react';
import {
  CHECKOUT_BILLING_SECTION_KEY,
  CHECKOUT_CONTACT_SECTION_KEY,
  CHECKOUT_PLAN_SECTION_KEY,
  CHECKOUT_PRACTICE_USER_SECTION_KEY,
  CHECKOUT_SUMMARY_SECTION_KEY,
} from '../../lib/events/checkout';
import './scss/CheckoutOrderSummary.scss';
import { CheckIcon2 } from '../icons';
import { DispatchContext, StateContext } from '../../lib/providers';
import { Amwell } from '../../lib/services';
import {
  CHECKOUT_SET_COUPON,
  CHECKOUT_TOGGLE_TOS,
} from '../../lib/events/checkout/types';

// eslint-disable-next-line no-unused-vars
const sectionKey = CHECKOUT_SUMMARY_SECTION_KEY;

export default function CheckoutOrderSummary({ onCheckout }) {
  const [checkoutReady, setCheckoutReady] = useState(false);
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { checkout } = state;
  const { total, discount, coupon, acceptsToS, planSubtotal, couponDiscount } =
    checkout;
  const planField = checkout[CHECKOUT_PLAN_SECTION_KEY];
  const contactField = checkout[CHECKOUT_CONTACT_SECTION_KEY];
  const billingField = checkout[CHECKOUT_BILLING_SECTION_KEY];
  const usersField = checkout[CHECKOUT_PRACTICE_USER_SECTION_KEY];
  const { subscription, billingPeriod, freePlan } = planField;

  const handleCheckoutCompletion = (evt) => {
    if (!checkoutReady) return;
    onCheckout(evt);
  };

  /**
   * Checks if all form information is valid and complete
   */
  useEffect(() => {
    const conditions = [
      planField.status === 'completed',
      contactField.status === 'completed',
      freePlan || billingField.status === 'completed',
      usersField.status === 'completed',
      acceptsToS,
    ];
    const allReady = conditions.every((val) => val);
    setCheckoutReady(allReady);
  }, [
    checkout,
    planField.status,
    contactField.status,
    billingField.status,
    usersField.status,
    freePlan,
    acceptsToS,
  ]);

  const CouponInput = () => {
    const [couponError, setCouponError] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const couponAddedClass = coupon ? 'validCoupon' : '';
    const couponErrorClass = couponError ? 'hasError' : '';
    const submitCoupon = async () => {
      setCouponError('');
      try {
        const coupon = await Amwell.checkCouponCode(couponCode);
        dispatch({ type: CHECKOUT_SET_COUPON, payload: { coupon } });
        setCouponCode(coupon.code);
      } catch (error) {
        handleCouponError();
      }
    };

    const handleCouponError = () => {
      setCouponError('This coupon is invalid');
    };

    const handleCouponRemoval = () => {
      dispatch({ type: CHECKOUT_SET_COUPON, payload: { coupon: null } });
    };

    const handleSubmit = (evt) => {
      evt.preventDefault();
      if (coupon) handleCouponRemoval();
      else submitCoupon();
    };

    const SubmitButtonLabel = () => (coupon ? <CheckIcon2 /> : 'Add ›');

    useEffect(() => {
      if (coupon) {
        setCouponCode(coupon.code);
      } else {
        setCouponCode('');
      }
    }, []);

    return !freePlan ? (
      <fieldset
        className={`CheckoutOrderSummry-coupon ${couponErrorClass} ${couponAddedClass}`}
        onSubmit={handleSubmit}
      >
        <input
          className="CheckoutOrderSummry-coupon-input"
          placeholder={'Coupon Code'}
          value={couponCode}
          onChange={({ target }) => setCouponCode(target.value)}
        />
        <button
          className="CheckoutOrderSummry-coupon-submit"
          type="submit"
          onClick={handleSubmit}
        >
          <SubmitButtonLabel />
        </button>
        <button
          className="CheckoutOrderSummry-coupon-remove"
          onClick={handleCouponRemoval}
        >
          Remove Coupon
        </button>
      </fieldset>
    ) : null;
  };

  return (
    <div className="CheckoutOrderSummry checkoutCard">
      <div className="CheckoutOrderSummry-heading">Order Summary</div>

      <div className="CheckoutOrderSummry-cart">
        {subscription ? <CheckoutOrderCartItem plan={planField} /> : null}
      </div>

      <CouponInput />

      <div className="CheckoutOrderSummry-subtotal">
        <TotalMonthlyPriceLine subtotal={planSubtotal} />
        <DiscountPriceLine
          discount={discount}
          freePlan={freePlan}
          billingPeriod={billingPeriod}
        />
        <CouponPriceLine coupon={coupon} couponDiscount={couponDiscount} />
      </div>

      <div className="CheckoutOrderSummry-total">
        <TotalPriceLine total={total} />
        <TotalPriceLineDuringTrial />
      </div>

      <TermsAndConditions />
      <button
        className={`CheckoutOrderSummry-checkout ${
          checkoutReady ? 'ready' : ''
        }`}
        onClick={handleCheckoutCompletion}
      >
        Complete Sign Up ›
      </button>
    </div>
  );
}

const TotalMonthlyPriceLine = ({ subtotal }) => {
  const { checkout } = useContext(StateContext);
  const { subscription } = checkout[CHECKOUT_PLAN_SECTION_KEY];

  return (
    <div className="CheckoutOrderSummry-subtotal-line">
      <span className="CheckoutOrderSummry-subtotal-line-label">
        {subscription && subscription.trialLength > 0
          ? 'Subtotal after trial:'
          : 'Subtotal:'}
      </span>
      <span className="CheckoutOrderSummry-subtotal-line-price">
        {subtotal.money}
      </span>
    </div>
  );
};

const DiscountPriceLine = ({ discount, freePlan, billingPeriod }) =>
  discount.price && !freePlan && billingPeriod === 'annual' ? (
    <div className="CheckoutOrderSummry-subtotal-line">
      <span className="CheckoutOrderSummry-subtotal-line-label">
        Annual Discount:
      </span>
      <span className="CheckoutOrderSummry-subtotal-line-price discount">
        - {discount.money}
      </span>
    </div>
  ) : null;

const CouponPriceLine = memo(({ coupon, couponDiscount }) =>
  coupon && couponDiscount ? (
    <div className="CheckoutOrderSummry-subtotal-line">
      <span className="CheckoutOrderSummry-subtotal-line-label">
        Coupon Code:
      </span>
      <span className="CheckoutOrderSummry-subtotal-line-price discount">
        - {couponDiscount.money}
      </span>
    </div>
  ) : null
);

const TotalPriceLine = ({ total }) => {
  const { checkout } = useContext(StateContext);
  const { subscription } = checkout[CHECKOUT_PLAN_SECTION_KEY];

  return (
    <div className="CheckoutOrderSummry-total-line">
      <span className="CheckoutOrderSummry-total-line-label">
        {subscription && subscription.trialLength > 0
          ? 'Monthly cost after trial:'
          : 'Total Monthly Cost'}
      </span>
      <span className="CheckoutOrderSummry-total-line-price">
        {total.money}
      </span>
    </div>
  );
};

const TotalPriceLineDuringTrial = () => {
  const { checkout } = useContext(StateContext);
  const { subscription } = checkout[CHECKOUT_PLAN_SECTION_KEY];

  if (subscription && subscription.trialLength > 0) {
    return (
      <div className="CheckoutOrderSummry-total-line">
        <span className="CheckoutOrderSummry-total-line-label">
          Monthly cost during trial:
        </span>
        <span className="CheckoutOrderSummry-total-line-price">$0.00</span>
      </div>
    );
  }
  return null;
};

const CheckoutOrderCartItem = () => {
  const state = useContext(StateContext);
  const { checkout } = state;
  const { planSubtotal } = checkout;
  const { subscription, providerAccounts } =
    checkout[CHECKOUT_PLAN_SECTION_KEY];
  let fixedName;
  if (subscription && subscription.name.includes('Amwell')) {
    fixedName = subscription.name.split('Amwell')[1].trim();
  } else if (subscription) {
    fixedName = subscription.name;
  } else {
    fixedName = '';
  }

  return (
    <div className="CheckoutOrderSummry-cart-item">
      <span className="CheckoutOrderSummry-cart-item-plan">{fixedName}</span>
      <span className="CheckoutOrderSummry-cart-item-practiceUsers">
        {providerAccounts} Practice User{providerAccounts > 1 ? 's' : ''}
      </span>
      <span className="CheckoutOrderSummry-cart-item-total">
        {planSubtotal.money}
      </span>
    </div>
  );
};

const TermsAndConditions = () => {
  const dispatch = useContext(DispatchContext);
  const { checkout } = useContext(StateContext);
  const { acceptsToS } = checkout;
  const handleTermsAndConditionsChange = () => {
    // setAcceptTermsAndConditions(bol => !bol)
    dispatch({ type: CHECKOUT_TOGGLE_TOS });
  };

  return (
    <div className="CheckoutOrderSummry-tos">
      <div className="CheckoutOrderSummry-tos-setting">
        <input
          className="CheckoutOrderSummry-tos-setting-box"
          type="checkbox"
          checked={acceptsToS}
          id="tos"
          name="tos"
          onChange={handleTermsAndConditionsChange}
        />
        <label htmlFor="tos" className="CheckoutOrderSummry-tos-setting-label">
          Accept{' '}
          <a
            className="CheckoutOrderSummry-tos-setting-label-link"
            href="https://business.amwell.com/siteassets/documents/private-practice/amwell_private_practice_terms_and_conditions.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
        </label>
      </div>
    </div>
  );
};
