import React, { useCallback, useContext, useState } from 'react';
import { CHECKOUT_CONTACT_SECTION_KEY } from '../../lib/events/checkout';
import CheckoutContactForm from './CheckoutContactForm';
import './scss/CheckoutContactInformation.scss';
import CheckoutSectionContinue from './CheckoutSectionContinue';
import CheckoutSectionHeading from './CheckoutSectionHeading';
import { usStateOptions } from './misc';
import { DispatchContext, StateContext } from '../../lib/providers';
import {
  CHECKOUT_PROGRESS_TO_NEXT_SECTION,
  CHECKOUT_REVISE_SECTION,
} from '../../lib/events/checkout/types';
import { ValidateCheckoutContactInformation } from '../../lib/validators';

const sectionKey = CHECKOUT_CONTACT_SECTION_KEY;
export default function CheckoutContactInformation() {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [fieldErrors, setFieldErrors] = useState({});
  const { checkout } = state;
  const { currentSection } = checkout;
  const { status } = checkout[sectionKey];
  const completed = status === 'completed';

  /**
   * Contact Section Edit
   */
  const handleSectionEdit = () => {
    const payload = { sectionKey };
    dispatch({ type: CHECKOUT_REVISE_SECTION, payload });
  };

  /**
   * Returns Contact Validation
   */
  const getValidation = useCallback(
    () => ValidateCheckoutContactInformation(state, checkout[sectionKey]),
    [state, checkout]
  );

  /**
   * Progresses the checkout flow
   */
  const progressCheckoutFlow = useCallback(() => {
    const payload = { sectionKey };
    dispatch({ type: CHECKOUT_PROGRESS_TO_NEXT_SECTION, payload });
  }, [dispatch]);

  /**
   * Contact Section Continue
   */
  const handleOnContinue = useCallback(() => {
    const validation = getValidation();
    setFieldErrors(validation.fields);
    if (validation.isValid) progressCheckoutFlow();
    else {
      console.error(validation.error);
      const orderedKeys = [
        'company',
        'firstName',
        'lastName',
        'email',
        'password',
        'address1',
        'city',
        'state',
        'zip',
      ];
      let firstErrorField;
      for (const key of orderedKeys) {
        if (firstErrorField) break;
        firstErrorField = validation.fields[key] ? key : null;
      }
      const inputElem = document.getElementById(`contact-${firstErrorField}`);
      if (inputElem) {
        inputElem.focus();
        inputElem.scrollIntoView({ block: 'center' });
      }
    }
  }, [progressCheckoutFlow, getValidation]);

  /**
   * Contact Section Submit
   */
  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      if (currentSection !== sectionKey) return;
      handleOnContinue();
    },
    [currentSection, handleOnContinue]
  );

  return (
    <div
      noValidate="novalidate"
      className={`CheckoutContactInformation checkoutCard ${status}`}
      onSubmit={handleSubmit}
    >
      <CheckoutSectionHeading
        step={2}
        label={'Account Information'}
        status={status}
        onEdit={handleSectionEdit}
      />
      <div className={'CheckoutContactInformation-container'}>
        <CheckoutContactForm fieldErrors={fieldErrors} />
        <CheckoutContinue onClick={handleSubmit} />
      </div>
      <CheckoutContactPreview completed={completed} />
    </div>
  );
}

const CheckoutContactPreview = ({ completed }) => {
  const { checkout } = useContext(StateContext);
  const { contactInfo } = checkout[sectionKey];
  const { firstName, lastName, company, email, state } = contactInfo;
  const stateName = usStateOptions.find((option) => option.value === state);
  const completionClass = completed ? 'show' : '';

  return (
    <div
      className={`CheckoutContactInformation-planPreview ${completionClass}`}
    >
      <span className="CheckoutContactInformation-planPreview-line">
        {`${company}`}
      </span>
      <span className="CheckoutContactInformation-planPreview-line">
        {`${firstName} ${lastName}`.trim()}
      </span>
      <span className="CheckoutContactInformation-planPreview-line">
        {`${email}`}
      </span>
      <span className="CheckoutContactInformation-planPreview-line">
        {`${stateName ? stateName.label : ''}`}
      </span>
    </div>
  );
};

const CheckoutContinue = ({ onClick: handleClick }) => (
  <div className="CheckoutContactInformation-continue">
    <CheckoutSectionContinue label={'Continue ›'} onClick={handleClick} />
  </div>
);
