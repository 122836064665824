import React, { useCallback, useContext, useEffect, useState } from "react"
import "./ChangeUsersAmount.scss"
import { MinusIcon, PlusIcon } from "../../icons"
import { LoadingSpinner } from "../../misc"
import { DispatchContext, StateContext } from "../../../lib/providers"
import { Amwell } from "../../../lib/services"
import { APP_SET_USER_SUBSCRIPTION_DATA } from "../../../lib/events/app/types"
import { calcMonthlyPayment } from "../../../lib/utils"

export default function ChangeUsersAmount() {
  const [practiceUsers, setPracticeUsers] = useState(0)
  const [amountHasChanged, setAmountHasChanged] = useState(false)
  const [monthlyPayment, setMonthlyPayment] = useState({})
  const [isUpdating, setIsUpdating] = useState(false)
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const { user } = state
  const { subscription } = user
  const { isFree } = subscription
  const planName = subscription
    ? subscription.plan.name // getPlanDisplayName(subscription.plan.name)
    : null
  const totalUsers = subscription ? subscription.addOns[0].quantity : null
  const isUpdatingClass = isUpdating ? "updating" : ""

  /**
   * Resets amount to users set on subscription
   */
  const handleCancelUserAmountChanges = useCallback(async () => {
    setPracticeUsers(totalUsers)
  }, [totalUsers])

  /**
   *  Will send request (method: PUT) to update subscription
   */
  const handleUpdateUserAmountChanges = useCallback(async () => {
    const updatedFields = {
      addOns: [
        {
          id: subscription.addOns[0].id,
          quantity: practiceUsers,
        },
      ],
    }
    setIsUpdating(true)

    try {
      await Amwell.updateSubscription(updatedFields)
      // Fetch and dispatch updated subscription info
      const subscriptionData = await Amwell.fetchSubscriptionInformation()
      const type = APP_SET_USER_SUBSCRIPTION_DATA
      const payload = { subscription: subscriptionData[0] }
      dispatch({ type, payload })
      setIsUpdating(false)
    } catch (error) {
      console.error("handleUpdateUserAmountChanges:error", error)
      setIsUpdating(false)
    }
  }, [subscription, practiceUsers, dispatch])

  // Set the initial practice users as what's attached to the subscription plan
  useEffect(() => {
    setPracticeUsers(totalUsers)
  }, [totalUsers])

  // Watch adjusted practice users. Detect if amount has changed
  useEffect(() => {
    setAmountHasChanged(totalUsers !== practiceUsers)
    setMonthlyPayment(calcMonthlyPayment(subscription, practiceUsers))
  }, [practiceUsers, totalUsers, subscription])

  return (
    <div className={`ChangeUsersAmount ${isUpdatingClass}`}>
      <h2 className="ChangeUsersAmount-heading">Change User Count</h2>
      <div className="ChangeUsersAmount-container">
        <ChangeUsersAmountLine label="Plan:" content={planName} />
        <ChangeUsersAmountUpdater
          label="Total Users:"
          freePlan={isFree}
          usePracticeUsers={[practiceUsers, setPracticeUsers]}
        />
        <ChangeUsersAmountLine
          label="New monthly payment:"
          content={monthlyPayment.money}
        />

        {amountHasChanged ? (
          <ChangeUsersAmountOptions
            onConfirm={handleUpdateUserAmountChanges}
            onCancel={handleCancelUserAmountChanges}
          />
        ) : null}
      </div>
      {isUpdating ? <LoadingSpinner message={"Processing changes..."} /> : null}
    </div>
  )
}

const ChangeUsersAmountLine = ({ label, content }) => (
  <div className="ChangeUsersAmount-line">
    <span className="ChangeUsersAmount-line-label">{label}</span>
    <span className="ChangeUsersAmount-line-content">{content}</span>
  </div>
)

const ChangeUsersAmountOptions = ({ onConfirm, onCancel }) => (
    <>
      <div className="ChangeUsersAmount-controls">
        <button className="ChangeUsersAmount-confirm" onClick={onConfirm}>
          Confirm Changes
        </button>
        <button className="ChangeUsersAmount-cancel" onClick={onCancel}>
          Cancel Changes
        </button>
      </div>
      <p className="ChangeUsersAmount-disclaimer">
        Changes made here will be invoiced immediately.
      </p>
    </>
  )

const ChangeUsersAmountUpdater = ({ label, freePlan, usePracticeUsers }) => {
  const [practiceUsers, setPracticeUsers] = usePracticeUsers

  const decreasePracticeUsers = () => {
    const isZero = practiceUsers - 1 === 0
    if (isZero) return
    const value = practiceUsers - 1
    setPracticeUsers(value)
  }

  const increasePracticeUsers = () => {
    const value = practiceUsers + 1
    if (value > 75) return
    setPracticeUsers(value)
  }

  const handleInputChange = ({ target }) => {
    const { value } = target
    const isNotNum = isNaN(value)
    if (isNotNum) return
    const belowMin = value < 1
    if (belowMin) return
    setPracticeUsers(value)
  }

  return (
    <div className="ChangeUsersAmount-line">
      <span className="ChangeUsersAmount-line-label">{label}</span>
      <div className={`ChangeUsersAmount-editor ${freePlan ? "free" : ""}`}>
        <button
          className="ChangeUsersAmount-editor-button"
          onClick={decreasePracticeUsers}
          disabled={freePlan}
        >
          <MinusIcon />
        </button>
        <input
          className="ChangeUsersAmount-editor-input"
          value={practiceUsers}
          type="number"
          onChange={handleInputChange}
          disabled={freePlan}
        />
        <button
          className="ChangeUsersAmount-editor-button"
          onClick={increasePracticeUsers}
          disabled={freePlan}
        >
          <PlusIcon />
        </button>
      </div>
    </div>
  )
}
