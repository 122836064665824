import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { CHECKOUT_PLAN_SECTION_KEY } from '../../lib/events/checkout';
import {
  CHECKOUT_SET_SUBSCRIPTION_PLAN,
  CHECKOUT_REVISE_SECTION,
  CHECKOUT_SET_PROVIDER_ACCOUNTS,
  CHECKOUT_SET_SUBSCRIPTION_BILLING_PERIOD,
  CHECKOUT_UPDATE_PAYMENT_SUMMARY,
  CHECKOUT_SET_SUBSCRIPTION_TIER,
  CHECKOUT_PROGRESS_TO_NEXT_SECTION,
} from '../../lib/events/checkout/types';
import { isCodeFreemium } from '../../lib/utils';
import { ValidateCheckoutPlan } from '../../lib/validators';
import { CaretIcon, MinusIcon, PlusIcon } from '../icons';
import { DispatchContext, StateContext } from '../../lib/providers';
import './scss/CheckoutSelectPlan.scss';
import CheckoutSectionContinue from './CheckoutSectionContinue';
import CheckoutSectionHeading from './CheckoutSectionHeading';

const sectionKey = CHECKOUT_PLAN_SECTION_KEY;

export default function CheckoutSelectPlan() {
  const checkedSearchParam = useRef(false);
  const [autoContinue, setAutoContinue] = useState(false);
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { checkout, variables } = state;
  const { status, subscription, freePlan } = checkout[sectionKey];
  const { availableSubscriptions } = checkout;
  const completed = status === 'completed';

  /**
   * Dispatch Section "Edit" Event
   */
  const handleSectionEdit = () => {
    const payload = { sectionKey };
    dispatch({ type: CHECKOUT_REVISE_SECTION, payload });
  };

  /**
   * Handle Section Continue Event
   */
  const handleOnContinue = useCallback(() => {
    const validation = ValidateCheckoutPlan(state, checkout[sectionKey]);
    if (validation.isValid) {
      const payload = { sectionKey };
      dispatch({ type: CHECKOUT_PROGRESS_TO_NEXT_SECTION, payload });
    } else console.error(validation.error);
    setAutoContinue(false);
  }, [checkout, state, dispatch]);

  /**
   * [Runs Once]
   *
   * Will fallback to finding a plan with "annual" in  the code
   * Will fallback to first subscription
   */
  const initSubscriptionPlan = useCallback(() => {
    // Stop if already ran
    if (checkedSearchParam.current) return;
    let preSelectPlan;
    let preSelectSubscription;

    /**
     * Checks for "plan" search parameters to search for in subscription list
     */
    if (window) {
      const params = new URLSearchParams(window.location.search);
      preSelectPlan = variables.allowPrefilledCheckoutPlan
        ? params.get('plan')
        : null;
      preSelectPlan = null;
      if (preSelectPlan) {
        preSelectSubscription = availableSubscriptions.find((subscription) =>
          subscription.code.includes(preSelectPlan)
        );
      }
    }

    /**
     * Will fallback to finding a plan with "annual" in  the code as a default subscription
     */
    const defaultSubscription = availableSubscriptions.find((subscription) =>
      subscription.code.includes('awn-pp')
    );

    /**
     * handles prefilling subscription plan
     */
    if (preSelectSubscription) {
      dispatch({
        type: CHECKOUT_SET_SUBSCRIPTION_PLAN,
        payload: { subscription: preSelectSubscription },
      });

      /**
       * Will check if free plan, autofill provider accounts and continue
       */
      if (isCodeFreemium(preSelectSubscription.code)) {
        dispatch({
          type: CHECKOUT_SET_PROVIDER_ACCOUNTS,
          payload: { value: 1 },
        });
        setAutoContinue(true);
      }
    } else if (defaultSubscription) {
      /**
       * Falls back to filling a default subscription if available
       */
      dispatch({
        type: CHECKOUT_SET_SUBSCRIPTION_PLAN,
        payload: { subscription: defaultSubscription },
      });

      dispatch({
        type: CHECKOUT_SET_SUBSCRIPTION_BILLING_PERIOD,
        payload: { billingPeriod: variables.defaultBillingPeriod || 'monthly' },
      });
    } else {
      /**
       * Falls back to filling a the first subscription is list
       */
      dispatch({
        type: CHECKOUT_SET_SUBSCRIPTION_PLAN,
        payload: { subscription: availableSubscriptions[0] },
      });
    }

    checkedSearchParam.current = true;
  }, [availableSubscriptions, dispatch, checkedSearchParam, variables]);

  /**
   * Watches for "availableSubscriptions" to hydrate and init a subscription plan
   */
  useEffect(() => {
    if (!availableSubscriptions.length) return;
    initSubscriptionPlan();
  }, [availableSubscriptions, initSubscriptionPlan]);

  /**
   * Watches for "autoContinue" update to handle continue
   */
  useEffect(() => {
    if (autoContinue) handleOnContinue();
  }, [autoContinue, handleOnContinue]);

  return (
    <div className={`CheckoutSelectPlan checkoutCard ${status}`}>
      <CheckoutSectionHeading
        step={1}
        label={'Select Plan'}
        status={status}
        onEdit={handleSectionEdit}
      />
      <div className="CheckoutSelectPlan-container">
        {subscription ? (
          <>
            <SelectPlanEditor />
            {!freePlan ? <SelectPlanPaymentSummary /> : null}
            <SelectPlanPaymentDue onContinueClick={handleOnContinue} />
          </>
        ) : null}
      </div>
      <SelectPlanPreview completed={completed} />
    </div>
  );
}

/**
 * Plan Preview
 */
const SelectPlanPreview = ({ completed }) => {
  const state = useContext(StateContext);
  const { checkout } = state;
  const { total } = checkout;
  const { subscription, providerAccounts, freePlan, billingPeriod } =
    checkout[sectionKey];
  let fixedName;
  if (subscription && subscription.name.includes('Amwell')) {
    fixedName = subscription.name.split('Amwell')[1].trim();
  } else if (subscription) {
    fixedName = subscription.name;
  } else {
    fixedName = '';
  }
  const commitment =
    billingPeriod === 'annual' ? 'Annual Commitment' : 'Monthly Commitment';
  const completionClass = completed ? 'show' : '';
  return freePlan ? (
    <div className={`CheckoutSelectPlan-planPreview ${completionClass}`}>
      <span className="CheckoutSelectPlan-planPreview-line">{fixedName}</span>
      <span className="CheckoutSelectPlan-planPreview-line">
        {providerAccounts} Practice User{providerAccounts > 1 ? 's' : ''}
      </span>
    </div>
  ) : (
    <div className={`CheckoutSelectPlan-planPreview ${completionClass}`}>
      <span className="CheckoutSelectPlan-planPreview-line">Premium</span>
      <span className="CheckoutSelectPlan-planPreview-line">
        {providerAccounts} Practice User{providerAccounts > 1 ? 's' : ''}
      </span>
      <span className="CheckoutSelectPlan-planPreview-line">{commitment}</span>
      <span className="CheckoutSelectPlan-planPreview-line">
        Total Monthly Cost: {total.money}
      </span>
    </div>
  );
};

/**
 * Plan Editor
 */
const SelectPlanEditor = () => {
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { checkout, variables } = state;
  const { providerAccounts, freePlan } = checkout[sectionKey];
  const [userAmount, setUserAmount] = useState(providerAccounts);

  const decreasePracticeUsers = () => {
    const isZero = userAmount - 1 === 0;
    if (isZero) return;
    const value = userAmount - 1;
    setUserAmount(value);

    dispatch({ type: CHECKOUT_SET_PROVIDER_ACCOUNTS, payload: { value } });
  };

  const increasePracticeUsers = () => {
    const value = userAmount + 1;
    if (value > 75) return;
    setUserAmount(value);

    dispatch({ type: CHECKOUT_SET_PROVIDER_ACCOUNTS, payload: { value } });
  };

  const handleInputChange = ({ target }) => {
    const { value } = target;
    const isNotNum = isNaN(value);
    if (isNotNum) return;
    const belowZero = value < 0;
    if (belowZero) return;
    const int = Math.floor(value);
    if (!value) setUserAmount(undefined);
    else {
      setUserAmount(int);
      dispatch({
        type: CHECKOUT_SET_PROVIDER_ACCOUNTS,
        payload: { value: int },
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const handleInputBlur = ({ target }) => {
    const { value } = target;
    const isNotNum = isNaN(value);
    if (isNotNum) return setUserAmount(providerAccounts);
    const belowMin = value < 1;
    const aboveMax = value > 72;
    const int = Math.floor(value);
    if (belowMin) {
      dispatch({ type: CHECKOUT_SET_PROVIDER_ACCOUNTS, payload: { value: 1 } });
      return setUserAmount(1);
    }
    if (aboveMax) {
      dispatch({
        type: CHECKOUT_SET_PROVIDER_ACCOUNTS,
        payload: { value: 75 },
      });
      return setUserAmount(75);
    }
    dispatch({
      type: CHECKOUT_SET_PROVIDER_ACCOUNTS,
      payload: { value: int },
    });
  };

  useEffect(() => {
    setUserAmount(providerAccounts);
  }, [providerAccounts]);

  return (
    <div className="CheckoutSelectPlan-editor">
      <SelectPlanPeriodSelector />

      {/* {copy ? (
        <span className="CheckoutSelectPlan-editor-copy">
          {copy.line1} <br /> <b>{copy.line2}</b>
        </span>
      ) : null} */}

      <div
        className={`CheckoutSelectPlan-editor-userAmount ${
          freePlan ? 'free' : ''
        }`}
      >
        <span className="CheckoutSelectPlan-editor-userAmount-label">
          Practice Users:
        </span>
        <button
          className="CheckoutSelectPlan-editor-userAmount-button"
          onClick={decreasePracticeUsers}
          disabled={freePlan}
        >
          <MinusIcon />
        </button>
        <input
          className="CheckoutSelectPlan-editor-userAmount-input"
          value={userAmount}
          // type="number"
          onChange={handleInputChange}
          disabled={freePlan}
          onBlur={handleInputBlur}
        />
        <button
          className="CheckoutSelectPlan-editor-userAmount-button"
          onClick={increasePracticeUsers}
          disabled={freePlan}
        >
          <PlusIcon />
        </button>
      </div>
      {!freePlan && variables.offerCommitmentOptions ? (
        <AnnualCommitment />
      ) : null}
      {!freePlan ? <SelectPlanPriceBreakdown /> : null}
    </div>
  );
};

/**
 * Practice users selector
 */
const SelectPlanPeriodSelector = () => {
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { checkout, variables } = state;
  const { freePlan, providerAccounts, billingPeriod } = checkout[sectionKey];

  const setStarterPlan = () => {
    dispatch({
      type: CHECKOUT_SET_SUBSCRIPTION_TIER,
      payload: { tier: 'starter' },
    });
  };

  const setPremiumPlan = () => {
    dispatch({
      type: CHECKOUT_SET_SUBSCRIPTION_TIER,
      payload: { tier: 'premium' },
    });
  };

  useEffect(() => {
    dispatch({ type: CHECKOUT_UPDATE_PAYMENT_SUMMARY });
  }, [providerAccounts, freePlan, billingPeriod, dispatch]);

  return (
    <div className={`CheckoutSelectPlan-editor-tier`}>
      {variables.offerFreePlan ? (
        <button
          className={`CheckoutSelectPlan-editor-tier-plan ${
            freePlan ? 'active' : ''
          }`}
          onClick={setStarterPlan}
        >
          Starter - Free
        </button>
      ) : null}
      <button
        className={`CheckoutSelectPlan-editor-tier-plan ${
          !freePlan ? 'active' : ''
        }`}
        onClick={setPremiumPlan}
      >
        Premium
      </button>
    </div>
  );
};

const AnnualCommitment = () => {
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { checkout } = state;
  const { billingPeriod } = checkout[sectionKey];

  // const [annualCommitment, setAnnualCommitment] = useState(
  //   false
  // )
  const handleAnnualCommitmentChange = () => {
    // setAnnualCommitment(bol => !bol)
    dispatch({
      type: CHECKOUT_SET_SUBSCRIPTION_BILLING_PERIOD,
      payload: { toggle: true },
    });
  };

  return (
    <div className="CheckoutSelectPlan-editor-periodSelector">
      <div className="CheckoutSelectPlan-editor-periodSelector-setting">
        <input
          className="CheckoutSelectPlan-editor-periodSelector-setting-box"
          type="checkbox"
          id="annualPeriod"
          name="annualPeriod"
          checked={billingPeriod === 'annual'}
          onChange={handleAnnualCommitmentChange}
        />
        <label
          htmlFor="annualPeriod"
          className="CheckoutSelectPlan-editor-periodSelector-setting-label"
        >
          Save 15% with an Annual Commitment
        </label>
      </div>
    </div>
  );
};

/**
 * Plan Breakdown Section
 */

const SelectPlanPriceBreakdown = () => {
  const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
  const statusClass = showPriceBreakdown ? 'open' : '';
  const handlePriceBreakdownToggle = () =>
    setShowPriceBreakdown(!showPriceBreakdown);
  const state = useContext(StateContext);
  const { checkout } = state;
  const { providerAccounts } = checkout[sectionKey];

  function tier1Number() {
    return providerAccounts <= 10 ? providerAccounts : 10;
  }
  function tier2Number() {
    if (providerAccounts <= 10) {
      return 0;
    }
    if (providerAccounts > 10 && providerAccounts <= 20) {
      return providerAccounts - 10;
    }
    return 10;
  }
  function tier3Number() {
    if (providerAccounts <= 20) {
      return 0;
    }
    return providerAccounts - 20;
  }
  function tier1Price() {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
      .format(tier1Number() * 49.0)
      .replace(/\D00$/, '');
  }
  function tier2Price() {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
      .format(tier2Number() * 44.0)
      .replace(/\D00$/, '');
  }
  function tier3Price() {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
      .format(tier3Number() * 39.0)
      .replace(/\D00$/, '');
  }

  return (
    <div className={`CheckoutSelectPlan-editor-priceBreakdown ${statusClass}`}>
      <button
        className="CheckoutSelectPlan-editor-priceBreakdown-handle"
        onClick={handlePriceBreakdownToggle}
      >
        <span>See Pricing Breakdown</span>
        <CaretIcon />
      </button>
      <div className="CheckoutSelectPlan-editor-priceBreakdown-container">
        <div className="CheckoutSelectPlan-editor-priceBreakdown-line">
          <span className="CheckoutSelectPlan-editor-priceBreakdown-line-left">
            Users 1-10: $49
          </span>
          <span className="CheckoutSelectPlan-editor-priceBreakdown-line-right">
            {tier1Number()} User{tier1Number() !== 1 && 's'} ={' '}
            <strong>{tier1Price()}</strong>
          </span>
        </div>
        <div className="CheckoutSelectPlan-editor-priceBreakdown-line">
          <span className="CheckoutSelectPlan-editor-priceBreakdown-line-left">
            Users 11-20: $44
          </span>
          <span className="CheckoutSelectPlan-editor-priceBreakdown-line-right">
            {tier2Number()} User{tier2Number() !== 1 && 's'} ={' '}
            <strong>{tier2Price()}</strong>
          </span>
        </div>
        <div className="CheckoutSelectPlan-editor-priceBreakdown-line">
          <span className="CheckoutSelectPlan-editor-priceBreakdown-line-left">
            Users 21-75: $39
          </span>
          <span className="CheckoutSelectPlan-editor-priceBreakdown-line-right">
            {tier3Number()} User{tier3Number() !== 1 && 's'} ={' '}
            <strong>{tier3Price()}</strong>
          </span>
        </div>
      </div>
    </div>
  );
};

/**
 * Plan Payment Summary
 */
const SelectPlanPaymentSummary = () => {
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { checkout } = state;
  const { planSubtotal, discount } = checkout;
  const { providerAccounts, billingPeriod, freePlan } = checkout[sectionKey];

  useEffect(() => {
    dispatch({ type: CHECKOUT_UPDATE_PAYMENT_SUMMARY });
  }, [providerAccounts, freePlan, dispatch]);

  const AnnualDiscount = () =>
    billingPeriod === 'annual' && !freePlan ? (
      <div className="CheckoutSelectPlan-paymentSummary-line">
        <span className="CheckoutSelectPlan-paymentSummary-line-label">
          Annual Discount:
        </span>
        <span className="CheckoutSelectPlan-paymentSummary-line-price discount">
          - {discount.money}
        </span>
      </div>
    ) : null;

  const SubtotalCost = () => (
    <div className="CheckoutSelectPlan-paymentSummary-line">
      <span className="CheckoutSelectPlan-paymentSummary-line-label">
        Total {billingPeriod === 'monthly' ? 'Monthly' : 'Annual'} Cost:
      </span>
      <span className="CheckoutSelectPlan-paymentSummary-line-price">
        {planSubtotal.money}
      </span>
    </div>
  );

  return (
    <div className="CheckoutSelectPlan-paymentSummary">
      <SubtotalCost />
      <AnnualDiscount />
    </div>
  );
};

const SelectPlanPaymentDue = ({ onContinueClick }) => {
  const state = useContext(StateContext);
  const { checkout } = state;
  const { subtotal } = checkout;

  return (
    <div className="CheckoutSelectPlan-paymentDue">
      <div className="CheckoutSelectPlan-paymentDue-line">
        <span className="CheckoutSelectPlan-paymentDue-line-label">
          Total Monthly Cost:
        </span>
        <span className="CheckoutSelectPlan-paymentDue-line-price">
          {subtotal.money}
        </span>
      </div>

      <CheckoutSectionContinue label={'Continue ›'} onClick={onContinueClick} />
    </div>
  );
};
