import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import './DashboardPageHeading.scss';

export default function DashboardPageHeading({
  heading,
  links,
  partiallyActive,
}) {
  const [pathname, setPathname] = useState('');
  useEffect(() => {
    if (window) {
      setPathname(window.location.pathname);
    }
  }, []);

  const DashboardPageLinks = () =>
    links.map((link, i) => {
      const { label, to } = link;
      const shouldRender = label && to;
      const shouldBePartiallyActive = to === pathname || `${to}/` === pathname;
      return shouldRender ? (
        <Link
          className="DashboardPageHeading-linklist-link"
          activeClassName="active"
          key={`DashboardPageLink-${i}`}
          partiallyActive={partiallyActive || shouldBePartiallyActive}
          to={to}
        >
          {label}
        </Link>
      ) : null;
    });

  return (
    <div className="DashboardPageHeading">
      <h1 className="DashboardPageHeading-heading">{heading}</h1>
      <ul className="DashboardPageHeading-linklist">
        <DashboardPageLinks />
      </ul>
    </div>
  );
}
