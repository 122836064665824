import React, { useState, useEffect } from 'react';
import './DashboardPracticeUser.scss';
import CheckoutFormField from '../../checkout/CheckoutFormField';
import { CaretIcon2 } from '../../icons';
import { practitionerSpecialty, practitionerTitles } from '../../checkout/misc';
import { LoadingSpinner } from '../../misc';
import { ValidatePracticeUser } from '../../../lib/validators';

export default function DashboardPracticeUser({
  user: practiceUser,
  index,
  editableUser,
  isFree,
  onSave: handleSave,
  onEdit: handleEdit,
  onEditClose: handleEditClose,
  onDelete: handleDelete,
  updating,
}) {
  const [modalAction, setModalAction] = useState('');
  const [provider, setProvider] = useState(practiceUser);
  const [errorFields, setErrorFields] = useState({});
  const [isUpdating, setIsUpdating] = useState(updating);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isEditing = editableUser === index;
  const {
    title,
    firstName,
    lastName,
    specialty,
    email,
    npi,
    unregistered,
    id,
  } = provider;
  const updatingClass = isUpdating ? 'updating' : '';
  const name = `${firstName} ${lastName}`.trim();
  const shortName = `${name}${title ? `, ${title}` : ''}`;
  const dropdownOpenClass = dropdownOpen ? 'preview' : '';
  const isEditingClass = isEditing ? 'editing' : '';
  const notEditingClass =
    editableUser !== null && !isEditing ? 'notEditing' : '';
  const unregisteredClass = unregistered ? 'unregistered' : '';
  const freeUserClass = isFree ? 'freeUser' : '';

  useEffect(() => {
    setProvider(practiceUser);
    setIsUpdating(updating);
  }, [practiceUser, updating]);

  /**
   * Toggle Edit
   */
  const toggleEdit = () => {
    handleEdit({ index, user: provider });
  };

  /**
   * Cancel Edit
   */
  const cancelEdit = () => {
    setErrorFields({});
    handleEditClose({ save: false });
  };

  /**
   * Toggle Dropdown
   */
  const toggleDropdown = () => {
    setErrorFields({});
    setDropdownOpen((val) => !val);
  };

  /**
   * Updates field value
   */
  const handleInputChange = (evt) => {
    // eslint-disable-next-line prefer-const
    let { name, value } = evt.target;
    switch (name) {
      case 'npi':
        if (value.length > 10) value = value.substring(0, 10);
        break;
      default:
    }
    setProvider((val) => ({ ...val, [name]: value }));
  };

  const clearModal = () => setModalAction(null);

  /**
   * Updates field value
   */
  const handleUserDelete = async () => {
    console.log('Deleting user', { index, user: provider });
    setIsUpdating(true);
    return handleDelete({ user: provider });
  };

  const confirmUserDelete = () => {
    setModalAction({
      type: 'confirm-delete',
      onConfirm: handleUserDelete,
      onCancel: clearModal,
      onClose: clearModal,
      headingText: 'Confirm Delete',
      copyText: 'Are you sure you want to delete this provider?',
      confirmButtonText: 'Yes, delete this provider',
      cancelButtonText: 'No, never mind',
    });
  };

  /**
   * Submit save
   */
  const handleUserSave = async () => {
    // Validate
    const validation = ValidatePracticeUser(provider);
    setErrorFields(validation.fields);

    // TODO: cancel if no changes made

    if (validation.isValid) {
      console.log('Saving provider', { index, provider, validation });

      setIsUpdating(true);
      setDropdownOpen(false);
      handleEditClose({ save: true });
      await handleSave({ index, provider, id });
    }
  };

  const confirmUserSave = () => {
    // setModalAction({
    //   type: "confirm-save",
    //   onConfirm: handleUserSave,
    //   onCancel: clearModal,
    //   onClose: clearModal,
    //   headingText: "Confirm Save",
    //   copyText: "Are you sure you want to save your changes?",
    //   confirmButtonText: "Yes, save my changes",
    //   cancelButtonText: "No, never mind",
    // })
    handleUserSave();
  };

  /**
   * Submit
   */
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    confirmUserSave();
  };

  return (
    <>
      <PracticeUserConfirmationModal modalAction={modalAction} />
      {!isEditing && !dropdownOpen ? (
        <div
          className={`DashboardPracticeUser closed ${freeUserClass} ${updatingClass} ${notEditingClass} ${unregisteredClass}`}
        >
          <div className="DashboardPracticeUser-cell name">{name}</div>
          <div className="DashboardPracticeUser-cell email">{email}</div>
          <div className="DashboardPracticeUser-cell short">{shortName}</div>
          <div className="DashboardPracticeUser-cell title">{title}</div>
          <div className="DashboardPracticeUser-cell specialty">
            {specialty}
          </div>
          <div className="DashboardPracticeUser-cell npi">{npi}</div>
          <button
            className="DashboardPracticeUser-cell dropdown"
            onClick={toggleDropdown}
          >
            <CaretIcon2 />
          </button>
          {!isFree ? (
            <div className="DashboardPracticeUser-cell edit">
              <button onClick={toggleEdit}>Edit</button>
            </div>
          ) : null}
          {isUpdating ? <LoadingIndication /> : null}
        </div>
      ) : (
        <form
          className={`DashboardPracticeUser form ${freeUserClass} ${updatingClass} ${isEditingClass} ${dropdownOpenClass} ${unregisteredClass}`}
          onSubmit={handleSubmit}
        >
          <PracticeUserTopBar
            isFreeAccount={isFree}
            isEditing={isEditing}
            onEditToggle={toggleEdit}
            onEditClose={cancelEdit}
            onDropdownToggle={toggleDropdown}
          />
          <div className="DashboardPracticeUser-cell firstName">
            <CheckoutFormField
              label={'First Name'}
              name={'firstName'}
              value={firstName}
              id={`practiceUser-${index}-firstName`}
              onChange={handleInputChange}
              required={true}
              errors={errorFields.firstName}
              disabled={!isEditing}
            />
          </div>
          <div className="DashboardPracticeUser-cell lastName">
            <CheckoutFormField
              label={'Last Name'}
              name={'lastName'}
              value={lastName}
              id={`practiceUser-${index}-lastName`}
              onChange={handleInputChange}
              required={true}
              errors={errorFields.lastName}
              disabled={!isEditing}
            />
          </div>
          <div className="DashboardPracticeUser-cell email">
            <CheckoutFormField
              label={'Email Address'}
              name={'email'}
              type={'email'}
              value={email}
              id={`practiceUser-${index}-email`}
              onChange={handleInputChange}
              required={true}
              errors={errorFields.email}
              disabled={!isEditing}
            />
          </div>
          <div className="DashboardPracticeUser-cell title">
            <CheckoutFormField
              label={'Credentials'}
              name={'title'}
              type={'select'}
              value={title}
              id={`practiceUser-${index}-title`}
              onChange={handleInputChange}
              required={false}
              errors={errorFields.title}
              disabled={!isEditing}
              selectOptions={[
                {
                  label: '- SELECT -',
                  value: '',
                  disabled: true,
                  selected: !title,
                },
                ...practitionerTitles,
              ]}
            />
          </div>
          <div className="DashboardPracticeUser-cell specialty">
            <CheckoutFormField
              label={'Care Specialty'}
              name={'specialty'}
              type={'select'}
              value={specialty}
              id={`practiceUser-${index}-specialty`}
              onChange={handleInputChange}
              required={false}
              errors={errorFields.specialty}
              disabled={!isEditing}
              selectOptions={[
                {
                  label: '- SELECT MEDICAL SPECIALTY -',
                  value: '',
                  disabled: true,
                  selected: !specialty,
                },
                ...practitionerSpecialty,
              ]}
            />
          </div>
          <div className="DashboardPracticeUser-cell npi">
            <CheckoutFormField
              label={'NPI'}
              name={'npi'}
              value={npi}
              id={`practiceUser-${index}-npi`}
              onChange={handleInputChange}
              required={false}
              errors={errorFields.npi}
              disabled={!isEditing}
            />
          </div>

          <PracticeUserEditControls
            status={isEditing}
            onSave={confirmUserSave}
            onDelete={confirmUserDelete}
            onEditClose={cancelEdit}
          />
        </form>
      )}
    </>
  );
}

// ====================================== //

const PracticeUserTopBar = ({
  isFreeAccount,
  isEditing,
  onEditToggle,
  onEditClose,
  onDropdownToggle,
}) => {
  const label = !isEditing ? 'Edit' : 'Cancel';

  const handleDropdownToggle = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (isEditing) return;
    onDropdownToggle();
  };

  const handleEdit = (evt) => {
    evt.preventDefault();
    if (!isEditing) onEditToggle();
    else onEditClose();
  };

  return (
    <div className="DashboardPracticeUser-top">
      <button
        className={`DashboardPracticeUser-cell dropdown ${
          isEditing ? 'hide' : ''
        }`}
        onClick={handleDropdownToggle}
        disabled={isEditing}
        type="button"
      >
        <CaretIcon2 />
      </button>
      {!isFreeAccount ? (
        <div className="DashboardPracticeUser-cell edit">
          <button onClick={handleEdit} type="button">
            {label}
          </button>
        </div>
      ) : null}
    </div>
  );
};

// ====================================== //

const PracticeUserEditControls = ({ status, onSave, onDelete, onEditClose }) =>
  status ? (
    <div className="DashboardPracticeUser-controls">
      <button className="DashboardPracticeUser-controls-save" onClick={onSave}>
        Save Changes
      </button>
      <button
        className="DashboardPracticeUser-controls-delete"
        onClick={onDelete}
      >
        Delete User
      </button>
      <button
        className="DashboardPracticeUser-controls-cancel"
        onClick={onEditClose}
      >
        Cancel
      </button>
    </div>
  ) : null;

// ====================================== //

const LoadingIndication = () => (
  <div className="DashboardPracticeUser-loading">
    <LoadingSpinner />
    <span className="DashboardPracticeUser-loading-message">
      This account is being modified
    </span>
  </div>
);

// ====================================== //

const PracticeUserConfirmationModal = ({ modalAction }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  if (!modalAction) return null;

  const {
    onClose: handleClose,
    onCancel: handleCancel,
    onConfirm: handleConfirm,
    headingText = '',
    copyText = '',
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
  } = modalAction;

  /**
   * Handle Plan Cancel
   */
  const confirmUserSaving = async () => {
    console.log('confirmUserSaving');
    setIsProcessing(true);
    await handleConfirm();
    handleClose();
  };

  /**
   * Cancel Plan Cancel
   */
  const cancelUserSaving = () => {
    console.log('cancelUserSaving');
    handleCancel();
  };

  return modalAction ? (
    <div className="DashboardPracticeUser-modal">
      <div className="DashboardPracticeUser-modal-box">
        {isProcessing ? (
          <LoadingSpinner message={'Processing'} />
        ) : (
          <>
            <button
              className="DashboardPracticeUser-modal-box-close"
              onClick={cancelUserSaving}
            >
              Close
            </button>
            <span className="DashboardPracticeUser-modal-box-heading">
              {headingText}
            </span>
            <span className="DashboardPracticeUser-modal-box-copy">
              {copyText}
            </span>
            <div className="DashboardPracticeUser-modal-box-methods">
              <button
                className="DashboardPracticeUser-modal-box-button confirm"
                onClick={confirmUserSaving}
              >
                {confirmButtonText}
              </button>
              <button
                className="DashboardPracticeUser-modal-box-button cancel"
                onClick={cancelUserSaving}
              >
                {cancelButtonText}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
};
