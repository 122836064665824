import removeFromAccountQueue from './removeFromAccountQueue';

export default function getUnregisteredQueuedProviders(
  providers,
  queuedProviders,
  user
) {
  console.log('getUnregisteredQueuedProviders', {
    providers,
    queuedProviders,
    user,
  });
  const unregisteredQueuedProviders = queuedProviders.filter(
    (provider) => provider.unregistered
  );

  unregisteredQueuedProviders.forEach((unregisteredProvider, i) => {
    const foundAsRegistered = providers.find(
      (provider) => provider.Email === unregisteredProvider.Email
    );
    if (foundAsRegistered) {
      console.log(
        'An Unregistered Provider now appears registered',
        foundAsRegistered
      );
      unregisteredQueuedProviders.splice(i, 1);
      removeFromAccountQueue(foundAsRegistered, user);
    }
  });

  return unregisteredQueuedProviders;
}
