import React from 'react';
import './scss/CheckoutSectionHeading.scss';
import { CheckIcon } from '../icons';
import BAAInfoToolTip from './misc/BAAInfoToolTip';

export default function CheckoutSectionHeading({
  flow,
  step,
  label,
  status,
  onEdit,
  showInfoIcon
}) {
  const completedClass = status;
  const isCompleted = status === 'completed';

  const handleEdit = () => {
    onEdit({ flow });
  };

  const EditButton = () =>
    isCompleted ? (
      <button className="CheckoutFlowHeading-edit" onClick={handleEdit}>
        Edit
      </button>
    ) : null;

  const HeadingIcon = () =>
    isCompleted ? (
      <CheckIcon />
    ) : (
      <span className="CheckoutFlowHeading-icon-text">{step}</span>
    );

  const LabelDisplay = () =>
    showInfoIcon ? (
      <>
        <span>{label}</span>
        <BAAInfoToolTip />
      </>
    ) : (
      <span>{label}</span>
    );

  return (
    <div className={`CheckoutFlowHeading ${completedClass}`}>
      <div className="CheckoutFlowHeading-icon">
        <HeadingIcon />
      </div>
      <div className="CheckoutFlowHeading-label">
        <LabelDisplay />
      </div>
      <EditButton />
    </div>
  );
}
