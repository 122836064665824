import { getAuthInfo, fetchSubscriptionInformation } from '.';
import { throwEndpointError } from '../../../utils';

export default async function cancelSubscription() {
  const { jwt } = await getAuthInfo();
  const subscriptionInfo = await fetchSubscriptionInformation();
  const [subscription] = subscriptionInfo;
  const { id: subscriptionId } = subscription;
  console.log('subscriptionInfo', subscriptionInfo);
  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/subscriptions`,
    {
      method: 'DELETE',
      headers: new Headers({
        Authorization: jwt,
      }),
      body: JSON.stringify({
        subscriptionId,
      }),
    }
  ).catch((err) =>
    throwEndpointError(err, res, 'Error cancelling subscription')
  );

  if (!res.ok) {
    throwEndpointError({}, res, 'Error cancelling subscription');
    return;
  }

  const data = await res.json();
  console.log('Subscription Cancelled', data);
  // eslint-disable-next-line consistent-return
  return data;
}
