import React, { useCallback, useContext, useEffect, useState } from 'react';
import './AccountInformation.scss';
import CheckoutFormField from '../../checkout/CheckoutFormField';
import { organizationSizeValues, usStateOptions } from '../../checkout/misc';
import { StateContext } from '../../../lib/providers';
import { Amwell } from '../../../lib/services';
import { MessagePopup } from '..';

export default function AccountInformation() {
  const [isEditing, setIsEditing] = useState(false);
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [editedFields, setEditedFields] = useState({});
  const [originalAccountInfo, setOriginalAccountInfo] = useState({});
  const [accountInfo, setAccountInfo] = useState({
    company: '',
    firstName: '',
    lastName: '',
    address1: '',
    city: '',
    region: '',
    postalCode: '',
    Eligible_Private_Practice_Providers__c: '',
    ...originalAccountInfo,
  });
  const state = useContext(StateContext);
  const { user } = state;
  const { data } = user;

  const toggleEdit = () => {
    setEditedFields({});
    setIsEditing((val) => !val);
  };

  const canEditAccount = () => data.salesforce && typeof data.salesforce.AcctID__c !== 'undefined';

  const handleInputChange = (evt) => {
    const { name, value, required } = evt.target;

    if (required && value.length < 1) {
      setErrorFields((val) => ({ ...val, [name]: [true] }));
    } else if (required && value.length > 0 && [name] in errorFields) {
      setErrorFields((val) => {
        // eslint-disable-next-line no-param-reassign
        delete val[name];
        return val;
      });
    }
    setAccountInfo((val) => ({ ...val, [name]: value }));
    setEditedFields((val) => ({ ...val, [name]: value }));
  };

  const saveChanges = useCallback(async () => {
    const changesMade = Object.values(editedFields).length;
    if (!changesMade) {
      setEditedFields({});
      setIsEditing(false);
      return;
    }
    console.log('saving account changes', editedFields);
    try {
      setSaving(true);

      await Amwell.updateAccountInformation({ fields: editedFields });

      for (const field in editedFields) {
        if (field === 'address1') {
          data.recurly.address.street1 = editedFields[field];
        } else if (field === 'city') {
          data.recurly.address.city = editedFields[field];
        } else if (field === 'region') {
          data.recurly.address.region = editedFields[field];
        } else if (field === 'postalCode') {
          data.recurly.address.postalCode = editedFields[field];
        } else if (field === 'Eligible_Private_Practice_Providers__c') {
          data.salesforce.Eligible_Private_Practice_Providers__c =
            editedFields[field];
        } else {
          data.recurly[field] = editedFields[field];
        }
      }

      setSaving(false);
      setIsEditing(false);
      setSaved(true);
      setTimeout(() => {
        setSaved(false);
      }, 3000);
      setEditedFields({});
    } catch (err) {
      setSaving(false);
      setIsEditing(false);
      setShowPopup(true);
      setAccountInfo(originalAccountInfo);
      setEditedFields({});
    }
  }, [editedFields, data, originalAccountInfo]);

  /**
   * On mount, set initial account info fields
   */
  useEffect(() => {
    const company = data.recurly.company || '';
    const firstName = data.recurly.firstName || '';
    const lastName = data.recurly.lastName || '';
    const address1 = data.recurly.address ? data.recurly.address.street1 : '';
    const city = data.recurly.address ? data.recurly.address.city : '';
    const region = data.recurly.address ? data.recurly.address.region : '';
    const postalCode = data.recurly.address
      ? data.recurly.address.postalCode
      : '';
    // eslint-disable-next-line camelcase
    const Eligible_Private_Practice_Providers__c = data.salesforce
      ? data.salesforce.Eligible_Private_Practice_Providers__c
      : '';
    const info = {
      company,
      firstName,
      lastName,
      address1,
      city,
      region,
      postalCode,
      Eligible_Private_Practice_Providers__c,
    };

    console.log('Current Account Info', info);

    setOriginalAccountInfo((val) => ({ ...val, ...info }));

    setAccountInfo((val) => ({ ...val, ...info }));
  }, [data]);

  const handleSubmit = useCallback(
    async (evt) => {
      evt.preventDefault();
      await saveChanges();
    },
    [saveChanges]
  );

  return (
    <div className="AccountInformation">
      <h2 className="AccountInformation-heading">Personal Details</h2>
      <div className="AccountInformation-container">
        <EditButton
          isEditing={isEditing}
          saved={saved}
          onClick={toggleEdit}
          canEdit={canEditAccount()}
        />

        <div className={`AccountInformation-saved${saved ? ' active' : ''}`}>
          Saved
        </div>

        <form className="AccountInformation-info" onSubmit={handleSubmit}>
          {!canEditAccount() && (
            <blockquote>
              <p>Your account is currently being set up and cannot be edited, please allow a few minutes for the account setup to complete.</p>
            </blockquote>
          )}

          <CheckoutFormField
            label={'Organization Name*:'}
            name={'company'}
            value={accountInfo.company}
            id={`account-company`}
            onChange={handleInputChange}
            required={true}
            errors={errorFields.company}
            disabled={!isEditing}
          />

          {data.salesforce ? (
            <CheckoutFormField
              type={'select'}
              label={'Organization Size:'}
              name={'Eligible_Private_Practice_Providers__c'}
              value={accountInfo.Eligible_Private_Practice_Providers__c}
              id={`account-Eligible_Private_Practice_Providers__c`}
              onChange={handleInputChange}
              required={false}
              errors={errorFields.Eligible_Private_Practice_Providers__c}
              disabled={!isEditing}
              selectOptions={[
                {
                  label: '- Select Organization Size -',
                  value: '',
                  disabled: true,
                },
                ...organizationSizeValues,
              ]}
            />
          ) : null}
          <CheckoutFormField
            label={'First Name*:'}
            name={'firstName'}
            value={accountInfo.firstName}
            id={`account-firstName`}
            onChange={handleInputChange}
            required={true}
            errors={errorFields.firstName}
            disabled={!isEditing}
          />
          <CheckoutFormField
            label={'Last Name*:'}
            name={'lastName'}
            value={accountInfo.lastName}
            id={`account-lastName`}
            onChange={handleInputChange}
            required={true}
            errors={errorFields.lastName}
            disabled={!isEditing}
          />
          {/* {accountInfo.address1 ? ( */}
          <>
            <CheckoutFormField
              label={'Street Address:'}
              name={'address1'}
              value={accountInfo.address1}
              id={`account-address`}
              onChange={handleInputChange}
              required={false}
              errors={errorFields.address1}
              disabled={!isEditing}
            />
            <CheckoutFormField
              label={'City'}
              name={'city'}
              value={accountInfo.city}
              id={`account-city`}
              onChange={handleInputChange}
              required={false}
              errors={errorFields.city}
              disabled={!isEditing}
            />
            <CheckoutFormField
              type={'select'}
              label={'State*:'}
              name={'region'}
              value={accountInfo.region}
              id={`account-region`}
              onChange={handleInputChange}
              required={true}
              errors={errorFields.region}
              disabled={!isEditing}
              selectOptions={[
                {
                  label: '- Select State -',
                  value: '',
                  disabled: true,
                  selected: true,
                },
                ...usStateOptions,
              ]}
            />
            <CheckoutFormField
              label={'Zip Code:'}
              name={'postalCode'}
              value={accountInfo.postalCode}
              id={`account-postalCode`}
              onChange={handleInputChange}
              required={false}
              errors={errorFields.postalCode}
              disabled={!isEditing}
            />
          </>
          {/* ) : null} */}
        </form>
        <SaveButton
          isEditing={isEditing}
          saving={saving}
          onClick={saveChanges}
          errorFields={errorFields}
        />
        {showPopup && (
          <MessagePopup
            setShowPopup={setShowPopup}
            message={
              'Your account is currently being set up and should be completed in a few minutes.'
            }
          />
        )}
      </div>
    </div>
  );
}

const SaveButton = ({ isEditing, saving, onClick: handleClick, errorFields }) =>
  isEditing ? (
    <div className="AccountInformation-controls bottom">
      <button
        className="AccountInformation-controls-save"
        onClick={handleClick}
        disabled={Object.keys(errorFields).length > 0}
      >
        {saving ? (
          <>
            <span>Saving</span>
            <span className="dot1">.</span>
            <span className="dot2">.</span>
            <span className="dot3">.</span>
          </>
        ) : (
          'Save Changes'
        )}
      </button>
    </div>
  ) : null;

const EditButton = ({ isEditing, saved, onClick: handleClick, canEdit }) => {
  const label = !isEditing ? 'Edit' : 'Cancel';
  return (
    <div className="AccountInformation-controls top">
      <button
        className={`AccountInformation-controls-edit${
          canEdit ? '' : ' hidden'
        }`}
        onClick={handleClick}
      >
        {label}
      </button>
    </div>
  );
};
