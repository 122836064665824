import React, { useContext, memo } from 'react';
import {
  CHECKOUT_BILLING_SECTION_KEY,
  CHECKOUT_CONTACT_SECTION_KEY,
} from '../../lib/events/checkout';
import CheckoutFormField from './CheckoutFormField';
import './scss/CheckoutBillingForm.scss';
import { usStateOptions } from './misc';
import { DispatchContext, StateContext } from '../../lib/providers';
import { CHECKOUT_SET_BILLING_INFO } from '../../lib/events/checkout/types';

export default memo(({ fieldErrors }) => {
  const dispatch = useContext(DispatchContext);
  const { checkout } = useContext(StateContext);
  const billingSectionState = checkout[CHECKOUT_BILLING_SECTION_KEY];
  const contactSectionState = checkout[CHECKOUT_CONTACT_SECTION_KEY];
  const { billingInfo, useContactInfo } = billingSectionState;
  const { contactInfo } = contactSectionState;

  const handleChange = (evt) => {
    const { target } = evt;
    const { name, value } = target;
    if (useContactInfo) return;
    dispatch({ type: CHECKOUT_SET_BILLING_INFO, payload: { name, value } });
  };

  return (
    <div className="CheckoutBillingForm">
      {useContactInfo ? (
        <>
          <input
            type="hidden"
            name="firstName"
            value={contactInfo.firstName}
            data-recurly="first_name"
          />
          <input
            type="hidden"
            name="lastName"
            value={contactInfo.lastName}
            data-recurly="last_name"
          />
          <input
            type="hidden"
            name="address1"
            value={contactInfo.address1}
            data-recurly="address1"
          />
          <input
            type="hidden"
            name="address2"
            value={contactInfo.address2}
            data-recurly="address2"
          />
          <input
            type="hidden"
            name="city"
            value={contactInfo.city}
            data-recurly="city"
          />
          <input
            type="hidden"
            name="state"
            value={contactInfo.state}
            data-recurly="state"
          />
          <input
            type="hidden"
            name="zip"
            value={contactInfo.zip}
            data-recurly="postal_code"
          />
          <input
            type="hidden"
            name="country"
            value="US"
            data-recurly="country"
          />
        </>
      ) : (
        <>
          <div className="CheckoutContactForm-group name">
            <CheckoutFormField
              label={'First Name'}
              name={'firstName'}
              dataRecurly="first_name"
              value={
                useContactInfo ? contactInfo.firstName : billingInfo.firstName
              }
              id={'billing-firstName'}
              onChange={handleChange}
              required={true}
              errors={fieldErrors.firstName}
            />
            <CheckoutFormField
              label={'Last Name'}
              name={'lastName'}
              dataRecurly="last_name"
              value={
                useContactInfo ? contactInfo.lastName : billingInfo.lastName
              }
              id={'billing-lastName'}
              onChange={handleChange}
              required={true}
              errors={fieldErrors.lastName}
            />
          </div>
          <CheckoutFormField
            label={'Street Address'}
            name={'address1'}
            dataRecurly="address1"
            value={useContactInfo ? contactInfo.address1 : billingInfo.address1}
            id={'billing-address1'}
            onChange={handleChange}
            required={true}
            errors={fieldErrors.address1}
          />
          <CheckoutFormField
            name={'address2'}
            dataRecurly="address2"
            value={useContactInfo ? contactInfo.address2 : billingInfo.address2}
            placeholder="Apt / Suite"
            id={'billing-address2'}
            onChange={handleChange}
            required={false}
          />
          <CheckoutFormField
            label={'City'}
            name={'city'}
            dataRecurly="city"
            value={useContactInfo ? contactInfo.city : billingInfo.city}
            id={'billing-city'}
            onChange={handleChange}
            required={true}
            errors={fieldErrors.city}
          />
          <div className="CheckoutContactForm-group state-zip">
            <CheckoutFormField
              label={'State'}
              name={'state'}
              type={'select'}
              dataRecurly="state"
              value={useContactInfo ? contactInfo.state : billingInfo.state}
              id={'billing-state'}
              onChange={handleChange}
              required={true}
              className={'stateField'}
              selectOptions={[
                {
                  label: '- Select State -',
                  value: '',
                  disabled: true,
                  selected: true,
                },
                ...usStateOptions,
              ]}
              errors={fieldErrors.state}
            />
            <CheckoutFormField
              label={'Zip Code'}
              name={'zip'}
              dataRecurly="postal_code"
              value={useContactInfo ? contactInfo.zip : billingInfo.zip}
              id={'billing-zip'}
              onChange={handleChange}
              required={true}
              className={'zipField'}
              errors={fieldErrors.zip}
            />
          </div>
          <input
            type="hidden"
            name="country"
            value="US"
            data-recurly="country"
          />
        </>
      )}
    </div>
  );
});
