import React, { useCallback, useContext, useRef, useState } from "react"
import { Auth } from "aws-amplify"
import "./AdminInformation.scss"
import { StateContext } from "../../../lib/providers"
import { isNotEmpty, isValidPassword } from "../../../lib/validators"

const changePasswordFormFields = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
}
const changePasswordValidationFields = {
  currentPassword: [],
  newPassword: [],
  confirmNewPassword: [],
}
export default function AdminInformation() {
  const state = useContext(StateContext)
  const [editingPassword, setEditingPassword] = useState(false)
  const [validation, setValidation] = useState({
    status: "ok",
    fields: { ...changePasswordValidationFields },
    frontMessage: "",
  })
  const resettingPassword = useRef(false)
  const [passwordForm, setPasswordForm] = useState({
    ...changePasswordFormFields,
  })
  const { user } = state
  const { data } = user
  const {email} = data.recurly
  const isEditingClass = editingPassword ? 'editing' : ''

  const handleFormChange = evt => {
    if (resettingPassword.current) return
    const { value, name } = evt.target
    setPasswordForm(val => ({ ...val, [name]: value }))
  }

  const handleCancel = () => {
    if (resettingPassword.current) return
    setEditingPassword(false)
    setPasswordForm({ ...changePasswordFormFields })
  }

  const handleSubmit = evt => {
    const fields = {}
    evt.preventDefault()
    if (resettingPassword.current) return

    if (isValidPassword(passwordForm.currentPassword, []).length) {
      fields.currentPassword = ["This password is invalid"]
      // eslint-disable-next-line consistent-return
      return setValidation({
        status: "error",
        fields,
        frontMessage: Object.values(fields)[0][0],
      })
    }

    // Check if not empty (Highlight all affected)
    Object.entries(passwordForm).forEach(field => {
      const [key, value] = field
      let foundErrors = []
      foundErrors = isNotEmpty(value, foundErrors)
      if (foundErrors.length) fields[key] = foundErrors
    })

    if (Object.keys(fields).length) {
      // eslint-disable-next-line consistent-return
      return setValidation({
        status: "error",
        fields,
        frontMessage: Object.values(fields)[0][0],
      })
    }

    // Check if new password is valid password (Highlight proposed password fields)
    const newPasswords = { ...passwordForm }
    delete newPasswords.currentPassword
    delete newPasswords.confirmNewPassword
    Object.entries(newPasswords).forEach(field => {
      const [key, value] = field
      let foundErrors = []
      foundErrors = isValidPassword(value, foundErrors)
      if (foundErrors.length) fields[key] = foundErrors
    })
    if (Object.keys(fields).length)
      // eslint-disable-next-line consistent-return
      return setValidation({
        status: "error",
        fields,
        frontMessage: Object.values(fields)[0][0],
      })

    // Check if confirmNewPassword === newPassword (Highlight proposed password fields)
    if (passwordForm.confirmNewPassword !== passwordForm.newPassword) {
      fields.newPassword = ["Passwords do not match"]
      fields.confirmNewPassword = ["Passwords do not match"]
      // eslint-disable-next-line consistent-return
      return setValidation({
        status: "error",
        fields,
        frontMessage: Object.values(fields)[0][0],
      })
    }

    const hasError = Object.keys(fields).length
    if (hasError) {
      setValidation({ status: "error", fields })
    } else {
      setValidation({ status: "ok", fields })
    }

    if (hasError) return
    resettingPassword.current = true
    changeAmplifyPassword()
  }

  const changeAmplifyPassword = useCallback(async () => {
    const { currentPassword, newPassword } = passwordForm
    await Auth.currentAuthenticatedUser()
      .then(user => Auth.changePassword(user, currentPassword, newPassword))
      .then(() => {
        notifySuccess()
      })
      .catch(err => {
        notifyError(err)
      })
  }, [passwordForm])

  const notifySuccess = () => {
    setValidation({
      status: "success",
      fields: {},
      frontMessage: "Password was successfully changed",
    })
    setTimeout(() => {
      setValidation({
        status: "success",
        fields: {},
        frontMessage: "Password was successfully changed",
      })
      setEditingPassword(false)
      setPasswordForm({ ...changePasswordFormFields })
      resettingPassword.current = false
    }, 3000)
  }

  const notifyError = (err) => {
    const passwordIncorrect = err.message && err.message.includes("Incorrect")
    if (passwordIncorrect) {
      setValidation({
        status: "error",
        fields: {
          currentPassword: ["Current password is incorrect"],
        },
        frontMessage: "Current password is incorrect",
      })
    } else {
      setValidation({
        status: "error",
        fields: {},
        frontMessage: "An unknown error occurred",
      })
    }
    resettingPassword.current = false
  }

  return (
    <div className={`AdminInformation ${isEditingClass}`}>
      <h2 className="AdminInformation-heading">Administrator Log In</h2>
      <div className="AdminInformation-container">
        <div className="AdminInformation-info">
          <AdminInformationInfoLine label="Email Address" content={email} />
          <AdminInformationChangePassword
            isEditing={editingPassword}
            passwordForm={passwordForm}
            onEdit={setEditingPassword}
            onChange={handleFormChange}
            onSubmit={handleSubmit}
            validation={validation}
          />
        </div>

        <EditPasswordControls
          isEditing={editingPassword}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </div>
  )
}

/**
 * Standard Information Line
 */
const AdminInformationInfoLine = ({ label, content }) => (
  <p className="AdminInformation-info-line">
    <span className="AdminInformation-info-line-label">{label}</span>
    <span className="AdminInformation-info-line-content">{content}</span>
  </p>
)

/**
 * Change password button ana form
 */
const AdminInformationChangePassword = ({
  onEdit: handleEdit,
  isEditing,
  onChange: handleChange,
  passwordForm,
  onSubmit: handleSubmit,
  validation,
}) => {
  const handleEditPassword = () => handleEdit(true)

  return !isEditing ? (
    <div className="AdminInformation-changePassword">
      <button
        className="AdminInformation-changePassword-button"
        onClick={handleEditPassword}
      >
        Change Password
      </button>
    </div>
  ) : (
    <form className="AdminInformation-changePassword" onSubmit={handleSubmit}>
      <div
        className={`AdminInformation-changePassword-line ${
          validation.status === "error" && validation.fields.currentPassword
            ? "hasError"
            : ""
        }`}
      >
        <label
          htmlFor="currentPassword"
          className="AdminInformation-changePassword-line-label"
        >
          Current Password:
        </label>
        <input
          className="AdminInformation-changePassword-line-input"
          name="currentPassword"
          id="currentPassword"
          type="password"
          onChange={handleChange}
          value={passwordForm.currentPassword}
        />
      </div>
      <div
        className={`AdminInformation-changePassword-line ${
          validation.status === "error" && validation.fields.newPassword
            ? "hasError"
            : ""
        }`}
      >
        <label
          htmlFor="newPassword"
          className="AdminInformation-changePassword-line-label"
        >
          New Password:
        </label>
        <input
          className="AdminInformation-changePassword-line-input"
          name="newPassword"
          id="newPassword"
          type="password"
          onChange={handleChange}
          value={passwordForm.newPassword}
        />
      </div>
      <div
        className={`AdminInformation-changePassword-line ${
          validation.status === "error" && validation.fields.confirmNewPassword
            ? "hasError"
            : ""
        }`}
      >
        <label
          htmlFor="confirmNewPassword"
          className="AdminInformation-changePassword-line-label"
        >
          Confirm New Password:
        </label>
        <input
          className="AdminInformation-changePassword-line-input"
          name="confirmNewPassword"
          id="confirmNewPassword"
          type="password"
          onChange={handleChange}
          value={passwordForm.confirmNewPassword}
        />
      </div>

      <div
        className={`AdminInformation-changePassword-line error-message ${validation.status}`}
      >
        <span className="AdminInformation-changePassword-line-label">
          {validation.frontMessage}
        </span>
      </div>
    </form>
  )
}

/**
 * Password Form Controls
 */
const EditPasswordControls = ({
  isEditing,
  onSubmit: handleSubmit,
  onCancel: handleCancel,
}) => isEditing ? (
    <>
      <button className="AdminInformation-cancel" onClick={handleCancel}>
        Cancel
      </button>
      <button
        className="AdminInformation-save"
        onClick={handleSubmit}
        type="submit"
      >
        Save Changes
      </button>
    </>
  ) : null
