import React from 'react';
import { Link } from 'gatsby';
import './HelpCard.scss';

export default function HelpCard({ headline, copy, icon, to }) {
  return (
    <Link to={to} className="HelpCard">
      <div className="HelpCard-icon">{icon}</div>
      <Headline headline={headline} />
      <Copy copy={copy} />
    </Link>
  );
}

const Headline = ({ headline }) =>
  headline ? <div className="HelpCard-headline">{headline}</div> : null;

const Copy = ({ copy }) =>
  copy ? <div className="HelpCard-copy">{copy}</div> : null;
