import React from 'react';
import './DashboardMenuCard.scss';

export default function DashboardMenuCard({ headline, copy, icon, url }) {
  return (
    <a className="DashboardMenuCard" href={url}>
      <div className="DashboardMenuCard-icon">{icon()}</div>
      <Headline headline={headline} />
      <Copy copy={copy} />
    </a>
  );
}

const Headline = ({ headline }) =>
  headline ? (
    <div className="DashboardMenuCard-headline">{headline}</div>
  ) : null;

const Copy = ({ copy }) =>
  copy ? <div className="DashboardMenuCard-copy">{copy}</div> : null;
