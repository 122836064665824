export const usStateOptions = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const practitionerTitles = [
  { label: "AAP American Academy of Pediatrics'", value: 'AAP' },

  { label: 'ABAI American Board of Allergy and Immunology', value: 'ABAI' },

  { label: 'ABFP American Board of Family Practitioners', value: 'ABFP' },

  { label: 'ABO American Board of Otolaryngology', value: 'ABO' },

  { label: 'ABPN American Board of Psychiatry and Neurology', value: 'ABPN' },

  { label: 'AK Acupuncturist (Pennsylvania)', value: 'AK' },

  {
    label: 'AOBFP American Osteopathic Board of Family Physicians',
    value: 'AOBFP',
  },

  {
    label:
      'AOBSPOMM American Osteopathic Board of Special Proficiency in Osteopathic Manipulative Medicine',
    value: 'AOBSPOMM',
  },

  { label: 'AP Acupuncture Physician', value: 'AP' },

  { label: 'ASG Affiliated Study Group', value: 'ASG' },

  { label: 'BHMS Bachelor of Homeopathic Medicine and Surgery', value: 'BHMS' },

  { label: 'BSN Bachelor of Science, Nursing', value: 'BSN' },

  {
    label: 'BVScAH Bachelor of Veterinary Science and Animal Husbandry',
    value: 'BVScAH',
  },

  { label: 'CA Certified Acupuncturist', value: 'CA' },

  {
    label:
      'CAAPM Clinical Associate of the American Academy of Pain Management',
    value: 'CAAPM',
  },

  { label: 'CAC Certified Animal Chiropractor', value: 'CAC' },

  { label: 'CCH Certified in Classical Homeopathy', value: 'CCH' },

  { label: 'CCSP Certified Chiropractic Sports Physician', value: 'CCSP' },

  { label: 'CRNP Certified Registered Nurse Practitioner', value: 'CRNP' },

  { label: 'CRRN Certified Rehabilitation Registered Nurse', value: 'CRRN' },

  {
    label:
      'CSPOMM Certified Specialty of Proficiency in Osteopathic Manipulation Medicine',
    value: 'CSPOMM',
  },

  { label: 'CVA Certified Veterinary Acupuncturist', value: 'CVA' },

  {
    label: 'DAAPM Diplomate of American Academy of Pain Management',
    value: 'DAAPM',
  },

  {
    label: 'DABFP Diplomate of the American Board of Family Practice',
    value: 'DABFP',
  },

  {
    label: 'DABIM Diplomate of the American Board of Internal Medicine',
    value: 'DABIM',
  },

  { label: 'DAc Diplomate in Acupuncture', value: 'DAc' },

  { label: 'DAc (RI) Doctor of Acupuncture, Rhode Island', value: 'DAc(RI)' },

  { label: 'DAc (WV) Doctor of Acupuncture, West Virginia', value: 'DAc(WV)' },

  {
    label: 'DACBN Diplomate of American Chiropractic Board of Nutrition',
    value: 'DACBN',
  },

  {
    label: 'DACVD Diplomate of the American College of Veterinary Dermatology',
    value: 'DACVD',
  },

  { label: 'DC Doctor of Chiropractic', value: 'DC' },

  { label: 'DDS Doctor of Dentistry', value: 'DDS' },

  {
    label:
      'DHANP Diplomate of the Homeopathic Academy of Naturopathic Physicians',
    value: 'DHANP',
  },

  { label: 'DHt Diplomate in Homeotherapeutics', value: 'DHt' },

  { label: 'DMD Doctor of Medical Dentistry', value: 'DMD' },

  {
    label: 'DNBHE Diplomate of the National Board of Homeopathic Examiners',
    value: 'DNBHE',
  },

  { label: 'DO Doctor of Osteopathy', value: 'DO' },

  { label: 'DOM Doctor of Oriental Medicine ', value: 'DOM' },

  { label: 'DPM Doctor of Podiatric Medicine', value: 'DPM' },

  { label: 'DVM Doctor of Veterinary Medicine', value: 'DVM' },

  {
    label: 'FAAEM Fellow of the American Academy of Environmental Medicine',
    value: 'FAAEM',
  },

  {
    label: 'FAAFP Fellow of the American Academy of Family Practitioners',
    value: 'FAAFP',
  },

  {
    label: 'FAAP Fellow of the Association of American Pediatrics',
    value: 'FAAP',
  },

  {
    label: 'FACFO Fellow of the American College of Foot Orthopedics',
    value: 'FACFO',
  },

  {
    label: 'FACOG Fellow of the American College of Obstetrics and Gynecology',
    value: 'FACOG',
  },

  { label: 'FAGD Fellow of the Academy of General Dentistry', value: 'FAGD' },

  {
    label: 'FIACA Fellow of the International Academy of Clinical Acupuncture',
    value: 'FIACA',
  },

  {
    label:
      'FIAOMT Fellow of the International Academy of Oral Medicine and Toxicology',
    value: 'FIAOMT',
  },

  {
    label:
      'FICCMO Fellow of the International College of Cranio Mandibular Orthopedics',
    value: 'FICCMO',
  },

  { label: 'FNP Family Nurse Practitioner', value: 'FNP' },

  { label: 'HASG Homeopathic Affiliated Study Group', value: 'HASG' },

  { label: "HLL Homoeopathic Laymen's League", value: 'HLL' },

  { label: 'HMD Homeopathic Medical Doctor (Nevada)', value: 'HMD' },

  { label: 'HSG Homoeopathic Study Group', value: 'HSG' },

  { label: 'LAc Licensed Acupuncturist', value: 'LAc' },

  { label: 'LicAc Licensed Acupuncturist', value: 'LicAc' },

  { label: "LL Laymen's League", value: 'LL' },

  { label: 'LN Licensed Nutritionist', value: 'LN' },

  { label: 'LNC Licensed Nutritionist Counselor', value: 'LNC' },

  { label: 'MD Doctor of Medicine', value: 'MD' },

  { label: 'MD(H) Homeopathic Medical Doctor (Arizona)', value: 'MD(H)' },

  { label: 'MFCC Marriage, Family and Child Counselor', value: 'MFCC' },

  { label: 'MNNP Master of Nursing, Nurse Practitioner', value: 'MNNP' },

  { label: 'MPH Master of Public Health', value: 'MPH' },

  { label: 'MSN Master of Nursing', value: 'MSN' },

  { label: 'MSW Master of Social Work', value: 'MSW' },

  {
    label: 'NCCA National Commission for the Certification of Acupuncturists',
    value: 'NCCA',
  },

  { label: 'ND Doctor of Naturopathy', value: 'ND' },

  { label: 'NMD Doctor of Naturopathy (Arizona)', value: 'NMD' },

  { label: 'NP Nurse Practitioner', value: 'NP' },

  { label: 'OD Doctor of Optometry', value: 'OD' },

  { label: 'OMD Doctor of Oriental Medicine', value: 'OMD' },

  {
    label: 'Other - to be used if professional designation is not on this list',
    value: 'Other',
  },

  { label: 'PA Physician Assistant', value: 'PA' },

  { label: 'PA-C Physician Assistant Certified', value: 'PA' },

  { label: 'PhD Doctor of Philosophy', value: 'PhD' },

  { label: 'PsyD Doctor of Psychology', value: 'PsyD' },

  { label: 'PT Physical Therapist', value: 'PT' },

  { label: 'RN Registered Nurse ', value: 'RN' },

  { label: 'RN-C Registered Nurse Certified', value: 'RN' },

  { label: 'RNCS Registered Nurse Clinical Specialist', value: 'RNCS' },

  { label: 'RN/NP Registered Nurse, Nurse Practitioner', value: 'RN' },

  { label: 'RPh Registered Pharmacist', value: 'RPh' },

  { label: 'RS Hom Registered with the Society of Homeopaths', value: 'RS' },

  { label: 'SG Study Group', value: 'SG' },

  { label: 'VMD Veterinary Medical Doctor', value: 'VMD' },
];

export const practitionerSpecialty = [
  { label: 'Anesthesiology', value: 'Anesthesiology' },

  { label: 'Addiction Medicine', value: 'Addiction Medicine' },

  { label: 'General Practice', value: 'General Practice' },

  { label: 'General Surgery', value: 'General Surgery' },

  { label: 'Allergy/Immunology', value: 'Allergy/Immunology' },

  { label: 'Otolaryngology ', value: 'Otolaryngology ' },

  { label: 'Cardiology', value: 'Cardiology' },

  { label: 'Dermatology', value: 'Dermatology' },

  { label: 'Family Practice', value: 'Family Practice' },

  {
    label: 'Interventional Pain Management',
    value: 'Interventional Pain Management',
  },

  { label: 'Gastroenterology', value: 'Gastroenterology' },

  { label: 'Internal Medicine', value: 'Internal Medicine' },

  {
    label: 'Osteopathic Manipulative Therapy',
    value: 'Osteopathic Manipulative Therapy',
  },

  { label: 'Neurology', value: 'Neurology' },

  { label: 'Neurosurgery', value: 'Neurosurgery' },

  { label: 'Obstetrics/Gynecology', value: 'Obstetrics/Gynecology' },

  { label: 'Ophthalmology', value: 'Ophthalmology' },

  { label: 'Oral Surgery (dentists only)', value: 'Oral Surgery' },

  { label: 'Orthopedic Surgery', value: 'Orthopedic Surgery' },

  { label: 'Pathology', value: 'Pathology' },

  {
    label: 'Plastic and Reconstructive Surgery',
    value: 'Plastic and Reconstructive Surgery',
  },

  {
    label: 'Physical Medicine and Rehabilitation',
    value: 'Physical Medicine and Rehabilitation',
  },

  { label: 'Psychiatry', value: 'Psychiatry' },

  {
    label: 'Colorectal Surgery (formerly proctology)',
    value: 'Colorectal Surgery',
  },

  { label: 'Pulmonary Disease', value: 'Pulmonary Disease' },

  { label: 'Diagnostic Radiology', value: 'Diagnostic Radiology' },

  { label: 'Unassigned', value: 'Unassigned' },

  { label: 'Thoracic Surgery', value: 'Thoracic Surgery' },

  { label: 'Urology', value: 'Urology' },

  { label: 'Chiropractic', value: 'Chiropractic' },

  { label: 'Nuclear Medicine', value: 'Nuclear Medicine' },

  { label: 'Pediatric Medicine', value: 'Pediatric Medicine' },

  { label: 'Geriatric Medicine', value: 'Geriatric Medicine' },

  { label: 'Nephrology', value: 'Nephrology' },

  { label: 'Hand Surgery ', value: 'Hand Surgery ' },

  { label: 'Optometry', value: 'Optometry' },

  { label: 'Infectious Disease', value: 'Infectious Disease' },

  { label: 'Endocrinology', value: 'Endocrinology' },

  { label: 'Podiatry', value: 'Podiatry' },

  { label: 'Rheumatology', value: 'Rheumatology' },

  {
    label: 'Multispecialty Clinic or Group Practice',
    value: 'Multispecialty Clinic or Group Practice',
  },

  { label: 'Pain Management', value: 'Pain Management' },

  {
    label: 'Peripheral Vascular Disease',
    value: 'Peripheral Vascular Disease',
  },

  { label: 'Vascular Surgery', value: 'Vascular Surgery' },

  { label: 'Cardiac Surgery', value: 'Cardiac Surgery' },

  { label: 'Critical Care (Intensivists)', value: 'Critical Care' },

  { label: 'Hematology', value: 'Hematology' },

  { label: 'Hematology/Oncology', value: 'Hematology/Oncology' },

  { label: 'Preventive Medicine', value: 'Preventive Medicine' },

  { label: 'Maxillofacial Surgery', value: 'Maxillofacial Surgery' },

  { label: 'Neuropsychiatry', value: 'Neuropsychiatry' },

  { label: 'Medical Oncology', value: 'Medical Oncology' },

  { label: 'Surgical Oncology', value: 'Surgical Oncology' },

  { label: 'Radiation Oncology', value: 'Radiation Oncology' },

  { label: 'Emergency Medicine', value: 'Emergency Medicine' },

  { label: 'Interventional Radiology', value: 'Interventional Radiology' },

  { label: 'Gynecological/Oncology', value: 'Gynecological/Oncology' },

  {
    label: 'Unknown Physician Specialty',
    value: 'Unknown Physician Specialty',
  },
].sort((a, b) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
});

export const organizationSizeValues = [
  { label: '1 Provider', value: '1' },
  { label: '2-10 providers', value: '2' },
  { label: '11-20 providers', value: '11' },
  { label: '21-30 providers', value: '21' },
  { label: '31-40 providers', value: '31' },
  { label: '41-50 providers', value: '41' },
  { label: '51-60 providers', value: '51' },
  { label: '61-70 providers', value: '61' },
  { label: '70+ providers', value: '70' },
];
