import React from 'react';

export default function EyeIcon({ title }) {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="privacy-icon-id"
    >
      {title ? <title id="privacy-icon-id">{title}</title> : null}
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.173 6.95657C12.173 8.157 11.1995 9.13048 9.99911 9.13048C8.79867 9.13048 7.8252 8.157 7.8252 6.95657C7.8252 5.75613 8.79867 4.78265 9.99911 4.78265C11.1995 4.78265 12.173 5.75613 12.173 6.95657Z"
          stroke="#E0E0E0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.869141 6.95652C2.17218 3.56913 6.55479 0.869568 10.0161 0.869568C13.4778 0.869568 17.7861 3.52392 19.13 6.87174"
          stroke="#E0E0E0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.869141 6.95648C2.17218 10.3439 6.55479 13.0434 10.0161 13.0434C13.4778 13.0434 17.7861 10.3891 19.13 7.04126"
          stroke="#E0E0E0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="13.913" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
