import { getAuthInfo } from '.';
import { throwEndpointError } from '../../../utils';

export default async function updateBillingInformation(payload) {
  const { jwt } = await getAuthInfo();
  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/billingInfo`,
    {
      method: 'PUT',
      headers: new Headers({
        Authorization: jwt,
      }),
      body: JSON.stringify(payload),
    }
  ).catch((err) =>
    throwEndpointError(err, res, 'Error Updating Billing Information')
  );

  if (!res.ok) {
    throwEndpointError({}, res, 'Error Updating Billing Information');
    return;
  }

  const data = await res.json();
  // eslint-disable-next-line consistent-return
  return data;
}
