import { getAuthInfo } from '.';
import { throwEndpointError } from '../../../utils';

export default async function fetchProviderAccounts() {
  const { jwt, currentUser } = await getAuthInfo();

  if (!jwt || !currentUser) {
    console.log('User currently not logged in');
    return;
  }

  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/providers/${currentUser.attributes.email}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: jwt,
      }),
    }
  ).catch((err) =>
    throwEndpointError(err, res, 'Error fetching provider accounts')
  );

  if (!res.ok) {
    throwEndpointError({}, res, 'Error fetching provider accounts');
    return;
  }

  const data = await res.json();
  if (data) {
    console.log(`Contacts`, data);

    // Find the "Buyer" account
    const buyer = data.find(
      // eslint-disable-next-line camelcase
      ({ AWPP_Relationship__c }) => AWPP_Relationship__c === 'Buyer'
    );
    // Filter provider accounts
    const providers = data.filter(
      // eslint-disable-next-line camelcase
      ({ AWPP_Relationship__c }) => AWPP_Relationship__c === 'Provider'
    );
    console.log(`Buyer's Account`, buyer);
    console.log(`Provider Accounts`, providers);
    // eslint-disable-next-line consistent-return
    return data;
  }
  // eslint-disable-next-line consistent-return
  return null;
}
