import React, { useRef, useEffect } from 'react';
import './scss/CheckoutFormField.scss';
import { CaretIcon2 } from '../icons';

export default function CheckoutFormField({
  label,
  name,
  id,
  placeholder,
  type,
  value,
  onChange,
  onBlur,
  className,
  required,
  dataRecurly,
  hideSymbol,
  onFocus: handleFocus,
  errors,
  selectOptions,
  optionalText,
  disabled,
}) {
  const hasLabelClass = label ? '' : 'noLabel';
  const hasErrorClass = errors && errors.length ? 'error' : '';
  const disabledClass = disabled ? 'disabled' : '';
  const handleChange = (evt) => {
    if (disabled) return;
    onChange(evt);
  };
  // eslint-disable-next-line no-param-reassign
  value = value || '';

  return (
    <div
      className={`CheckoutFormField ${hasLabelClass} ${hasErrorClass} ${
        className || ''
      } ${disabledClass}`}
    >
      {label ? (
        <label className="CheckoutFormField-label" htmlFor={id}>
          <span className="CheckoutFormField-label-box">
            {label}
            {required ? <i>*</i> : null}
            {optionalText ? (
              <span className="CheckoutFormField-label-optional">
                {optionalText}
              </span>
            ) : null}
          </span>
          {hasErrorClass ? (
            <span className="CheckoutFormField-label-errorMessage">
              {errors[0]}
            </span>
          ) : null}
        </label>
      ) : null}

      {type === 'select' ? (
        <SelectInput
          id={id}
          onChange={handleChange}
          onFocus={handleFocus}
          value={value}
          name={name}
          options={selectOptions}
          dataRecurly={dataRecurly}
          disabled={disabled}
          onBlur={onBlur}
        />
      ) : (
        <input
          type={type || 'text'}
          className="CheckoutFormField-input"
          placeholder={placeholder || ''}
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={onBlur}
          required={required}
          data-recurly={dataRecurly || ''}
          disabled={disabled}
        />
      )}
    </div>
  );
}

const SelectInput = ({
  options,
  name,
  id,
  onChange,
  value,
  dataRecurly,
  onBlur,
  disabled,
  onFocus: handleFocus,
}) => {
  const selectRef = useRef(null);
  const disabledClass = disabled ? 'disabled' : '';

  const handleOnChange = (evt) => {
    if (disabled) return;
    checkDisabledValue();
    onChange(evt);
  };

  const checkDisabledValue = () => {
    const { current } = selectRef;
    const { selectedOptions } = current;
    if (selectedOptions && selectedOptions.length) {
      const selectedOption = selectedOptions[0];
      const isDisabled = selectedOption.disabled;
      if (isDisabled) selectRef.current.classList.add('disabledOption');
      else selectRef.current.classList.remove('disabledOption');
    }
  };

  useEffect(() => {
    checkDisabledValue();
  }, [value]);

  return (
    <div className={`CheckoutFormField-input-select-wrapper ${disabledClass}`}>
      <select
        name={name}
        ref={selectRef}
        className="CheckoutFormField-input select"
        id={id}
        onChange={handleOnChange}
        onBlur={onBlur}
        value={value !== undefined ? value : ''}
        data-recurly={dataRecurly || ''}
        disabled={disabled}
        onFocus={handleFocus}
      >
        {options
          ? options.map((option, idx) => (
              <option
                key={`${name}-option-${idx}`}
                value={option.value}
                disabled={option.disabled}
                // selected={option.value === value}
              >
                {option.label}
              </option>
            ))
          : null}
      </select>
      <span className="CheckoutFormField-input-select-caret">
        <CaretIcon2 />
      </span>
    </div>
  );
};
