export default function getAccountQueue(user) {
  const {
    currentUser: { username },
  } = user;
  const storageKey = `amwell-queue-${username}`;
  let accountQueue;
  // eslint-disable-next-line prefer-const
  accountQueue = JSON.parse(localStorage.getItem(storageKey));
  // TODO: Add "expire" mechanism and filter expired accounts
  console.log('Retrieving Account Queue', { accountQueue, user, storageKey });
  return accountQueue || [];
}
