import { getAuthInfo } from '.';
import { throwEndpointError } from '../../../utils';

export default async function updateAccountInformation({ fields }) {
  if (!fields) throw new Error('Update fields not provided');
  if (!Object.keys(fields).length)
    throw new Error('Update fields not provided');

  const { jwt } = await getAuthInfo();
  console.log('updateAccountInformation', fields);
  // PUT /subscription/account
  // {
  //     "street1": "123 Back St",
  //     "city": "Anytown",
  //     "region": "CA",
  //     "postalCode": "90210",
  //     "company": "Ripen",
  //     "Eligible_Private_Practice_Providers__c": "10"
  // }
  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/account`,
    {
      method: 'PUT',
      headers: new Headers({
        Authorization: jwt,
      }),
      body: JSON.stringify(fields),
    }
  ).catch((err) =>
    throwEndpointError(err, res, 'Error Updating Account Information')
  );

  if (!res.ok) {
    throwEndpointError({}, res, 'Error Updating Account Information');
    return;
  }

  const data = await res.json();
  console.log('Account Information Update', data);
  // eslint-disable-next-line consistent-return
  return data;
}
