import MD5 from 'crypto-js/md5';
import { Link } from 'gatsby';
import React, { memo, useContext, useState } from 'react';
import { AmwellLogo2, CaretIcon } from '../../icons';
import DefaultProfileIcon from '../../../assets/img/DefaultProfile.png';
import './NavigationBar.scss';
import { StateContext } from '../../../lib/providers';

export default function NavigationBar() {
  const state = useContext(StateContext);
  const { user } = state || {};
  return (
    <div className="NavigationBar">
      <div className="NavigationBar-brand">
        <Link className="NavigationBar-brand-logo" to="/">
          <AmwellLogo2 />
        </Link>
      </div>
      {user ? <NavigationAccount user={user} /> : <NavigationLinks />}
    </div>
  );
}

const NavigationAccount = memo(({ user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle menu state when button is clicked
  const handleProfileOnClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu when click occurs outside of the menu button
  document.addEventListener('click', (ev) => {
    if (ev.target.parentNode) {
      if (
        isMenuOpen &&
        !ev.target.parentNode.classList.contains(
          'NavigationBar-account-icon'
        ) &&
        !ev.target.parentNode.classList.contains('NavigationBar-account-card')
      ) {
        setIsMenuOpen(false);
      }
    }
  });

  return (
    <div className="NavigationBar-account">
      <ProfileCard user={user} onClick={handleProfileOnClick} />
      <ProfileMenu isMenuOpen={isMenuOpen} />
    </div>
  );
});

const ProfileCard = memo(({ user, onClick: handleClick }) => {
  let name = '';
  if (user.data && user.data.salesforce) {
    name = user.data.salesforce.Name;
  } else if (user.data && user.data.recurly) {
    name = user.data.recurly.company;
  }

  return (
    <button className="NavigationBar-account-card" onClick={handleClick}>
      <ProfileIcon user={user} />
      <ProfileLabel label={name} />
      <span className="NavigationBar-account-menuIcon">
        <CaretIcon />
      </span>
    </button>
  );
});

const ProfileIcon = memo(({ user }) => {
  let customProfileIcon;
  if (user.data && user.data.recurly) {
    const userHash = MD5(user.data.recurly.email).toString();
    customProfileIcon = `https://www.gravatar.com/avatar/${userHash}?d=mp`;
  }

  return (
    <span className="NavigationBar-account-icon">
      {customProfileIcon ? (
        <img src={customProfileIcon} alt="Profile Icon" />
      ) : (
        <img src={DefaultProfileIcon} alt="Profile Icon" />
      )}
    </span>
  );
});

const ProfileLabel = memo(({ label }) => (
  <span className="NavigationBar-account-label"> {label}</span>
));

const ProfileMenu = memo(({ isMenuOpen }) =>
  isMenuOpen ? (
    <div className="NavigationBar-account-menu">
      <ul className="NavigationBar-account-menu-list">
        <Link
          className="NavigationBar-account-menu-link"
          to="/dashboard/account"
        >
          <span className="NavigationBar-account-menu-link-label">
            My Account
          </span>
        </Link>
        <Link className="NavigationBar-account-menu-link" to="/signout">
          <span className="NavigationBar-account-menu-link-label">
            Sign Out
          </span>
        </Link>
      </ul>
    </div>
  ) : null
);

const NavigationLinks = memo(() => (
  <div className="NavigationBar-links">
    <Link className="NavigationBar-link" to="/signin">
      Sign In
    </Link>
    <Link className="NavigationBar-link" to="/help">
      Help
    </Link>
  </div>
));
