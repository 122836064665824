import { getAuthInfo } from '.';
import { throwEndpointError } from '../../../utils';

/**
 {
    plan_code,
    addOns: [
        {
          id: subscription.addOns[0].id,
          quantity: practiceUsers,
        },
      ],
    }
 */
export default async function updateSubscription(updatedFields) {
  const { jwt } = await getAuthInfo();
  const payload = { ...updatedFields };
  console.log('updateSubscription', { payload, jwt });

  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/subscriptions`,
    {
      method: 'PUT',
      headers: new Headers({
        Authorization: jwt,
      }),
      body: JSON.stringify(payload),
    }
  ).catch((err) =>
    throwEndpointError(
      err,
      res,
      'Error updating subscription account information'
    )
  );

  if (!res.ok) {
    throwEndpointError(
      {},
      res,
      'Error updating subscription account information'
    );
    return;
  }

  const data = await res.json();
  // eslint-disable-next-line consistent-return
  return data;
}
