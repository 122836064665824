import { getAuthInfo } from '.';
import { throwEndpointError } from '../../../utils';

export default async function addNewProvider(user, { provider }) {
  if (!provider) throw new Error('Provider not provided');
  const { jwt, currentUser } = await getAuthInfo();
  const payload = {
    email: provider.Email,
    username: provider.Email,
    password: 'L3tm31n!!!',
    parentId: user.data.salesforce.Id,
    providerObject: provider,
  };

  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/provider/${currentUser.attributes.email}`,
    {
      method: 'POST',
      headers: new Headers({
        Authorization: jwt,
      }),
      body: JSON.stringify(payload),
    }
  ).catch((err) => throwEndpointError(err, res, 'Error adding new provider'));

  if (!res.ok) {
    throwEndpointError({}, res, 'Error adding new provider');
    return;
  }

  const data = await res.json();
  console.log('Provider Creation Data', data);
  // eslint-disable-next-line consistent-return
  return data;
}
