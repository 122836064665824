import React from 'react';
import './IdlePopup.scss';

export default function IdlePopup({ setShowIdlePopup }) {
  const button = React.useRef();

  const clickHandler = React.useCallback(
    (e) => {
      button.current.removeEventListener('mousedown', clickHandler);
      setShowIdlePopup(false);
    },
    [setShowIdlePopup]
  );

  React.useEffect(() => {
    button.current.addEventListener('mousedown', clickHandler);
  }, [button, clickHandler]);

  return (
    <div className="IdlePopupOverlay">
      <div className="IdlePopup">
        <p>
          For your security, you will be signed out in 1 minute. Interact to
          stay signed in.
        </p>
        <button ref={button} id="idle-popup-button" className="primary-button">
          Stay
        </button>
      </div>
    </div>
  );
}
