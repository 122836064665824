import React, { useCallback, useContext, useRef, useState } from 'react';
import { CardElement, useRecurly } from '@recurly/react-recurly';
import { CHECKOUT_BILLING_SECTION_KEY } from '../../lib/events/checkout';
import './scss/CheckoutBillingInformation.scss';
import CheckoutBillingForm from './CheckoutBillingForm';
import CheckoutSectionContinue from './CheckoutSectionContinue';
import CheckoutSectionHeading from './CheckoutSectionHeading';
import PaymentVendorsImage from '../../assets/img/payment-vendors.png';
import { DispatchContext, StateContext } from '../../lib/providers';
import {
  CHECKOUT_PROGRESS_TO_NEXT_SECTION,
  CHECKOUT_REVISE_SECTION,
  CHECKOUT_SET_BILLING_CARD_BRAND,
  CHECKOUT_SET_BILLING_CARD_LAST_FOUR,
  CHECKOUT_SET_BILLING_CARD_VALIDATION,
  CHECKOUT_SET_BILLING_RECURLY_TOKEN,
  CHECKOUT_TOGGLE_CONTACT_AS_BILLING_INFO,
} from '../../lib/events/checkout/types';
import { ValidateCheckoutBillingInformation } from '../../lib/validators';

const sectionKey = CHECKOUT_BILLING_SECTION_KEY;

export default function CheckoutBillingInformation() {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const formRef = useRef(null);
  const [cardInfo, setCardInfo] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const { checkout } = state;
  const { currentSection } = checkout;
  const { status } = checkout[sectionKey];
  const completed = status === 'completed';
  const recurly = useRecurly();

  /**
   * Uses payment form (including data-recurly fields) to request a token
   */
  const getRecurlyToken = useCallback(
    async (formRef) =>
      new Promise((resolve, reject) => {
        recurly.token(formRef.current, (err, token) => {
          setFieldErrors((fields) => ({ ...fields, cc: err }));
          if (err) {
            reject(err);
          } else {
            resolve(token);
          }
        });
      }),
    [recurly]
  );

  /**
   * Handles section edit event
   */
  const handleSectionEdit = () => {
    const payload = { sectionKey };
    dispatch({ type: CHECKOUT_REVISE_SECTION, payload });
  };

  /**
   * Handles section continue event
   */
  const handleOnContinue = useCallback(async () => {
    const validation = ValidateCheckoutBillingInformation(
      state,
      checkout[sectionKey]
    );
    const payload = { sectionKey };

    setFieldErrors(validation.fields);
    // TODO: dispatch({type: CHECKOUT_VALIDATE_SECTION, payload: {sectionKey }})
    if (validation.isValid) {
      const token = await getRecurlyToken(formRef);
      if (token) {
        const { brand, lastFour } = cardInfo;
        dispatch({
          type: CHECKOUT_SET_BILLING_RECURLY_TOKEN,
          payload: { recurlyToken: token },
        });
        dispatch({ type: CHECKOUT_SET_BILLING_CARD_BRAND, payload: { brand } });
        dispatch({
          type: CHECKOUT_SET_BILLING_CARD_LAST_FOUR,
          payload: { lastFour },
        });
        dispatch({ type: CHECKOUT_PROGRESS_TO_NEXT_SECTION, payload });
      }
    } else {
      console.error(validation.error);
    }
    setTimeout(() => {
      const data = window.dataLayer || [];
      const eventName = 'add_payment_info';
      const item = {
        item_name: checkout.plan.subscription.name,
        item_id: checkout.plan.subscription.id,
        price: checkout.planSubtotal.price,
        item_brand: '',
        item_category: '',
        item_variant: checkout.plan.subscription.code,
        quantity: checkout.plan.providerAccounts
      };

      data.push({
        event: eventName,
        ecommerce: {
          payment_type: 'Credit Card',
          items: [item]
        }
      });
    }, 50);
  }, [formRef, checkout, dispatch, getRecurlyToken, state, cardInfo]);

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      if (currentSection !== sectionKey) return;
      handleOnContinue();
    },
    [handleOnContinue, currentSection]
  );

  return (
    <form
      className={`CheckoutPaymentInformation checkoutCard ${status}`}
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <CheckoutSectionHeading
        step={4}
        label={'Payment Information'}
        status={status}
        onEdit={handleSectionEdit}
      />
      <div className="CheckoutPaymentInformation-container">
        <PaymentBilling fieldErrors={fieldErrors} />
        <PaymentCardVendors />
        <PaymentCardForm fieldErrors={fieldErrors} setCardInfo={setCardInfo} />
        <CheckoutContinue type={'submit'} />
      </div>
      <PaymentPreview completed={completed} />
    </form>
  );
}

const PaymentPreview = ({ completed }) => {
  const state = useContext(StateContext);
  const { checkout } = state;
  const { ccBrand, ccLastFour } = checkout[sectionKey];
  const completionClass = completed ? 'show' : '';

  return (
    <div
      className={`CheckoutPaymentInformation-planPreview ${completionClass}`}
    >
      <span className="CheckoutPaymentInformation-planPreview-line">
        {ccBrand ? String(ccBrand).toUpperCase() : ''}
      </span>
      <span className="CheckoutPaymentInformation-planPreview-line">
        xxxx-xxxx-xxxx-{ccLastFour}
      </span>
    </div>
  );
};

const PaymentBilling = ({ onStateChange, fieldErrors }) => {
  const dispatch = useContext(DispatchContext);
  const { checkout } = useContext(StateContext);
  const billingSectionState = checkout[sectionKey];
  const { useContactInfo } = billingSectionState;

  const handleBillingInfoCheckboxChange = () => {
    dispatch({ type: CHECKOUT_TOGGLE_CONTACT_AS_BILLING_INFO });
  };

  return (
    <div className="CheckoutPaymentInformation-billing">
      <div className="CheckoutPaymentInformation-billing-setting">
        <input
          className="CheckoutPaymentInformation-billing-setting-box"
          type="checkbox"
          id="contactAddressIsBilling"
          checked={useContactInfo}
          onChange={handleBillingInfoCheckboxChange}
        />
        <label
          className="CheckoutPaymentInformation-billing-setting-label"
          htmlFor="contactAddressIsBilling"
        >
          Billing address is the same as contact address
        </label>
      </div>
      <div
        className={`CheckoutPaymentInformation-billing-formContainer ${
          useContactInfo ? 'useContact' : ''
        }`}
      >
        <CheckoutBillingForm fieldErrors={fieldErrors} />
      </div>
    </div>
  );
};

const PaymentCardVendors = () => (
  <div className="CheckoutPaymentInformation-vendors">
    <img src={PaymentVendorsImage} alt="Accepted Credit Card Vendors" />
  </div>
);

const PaymentCardForm = ({ fieldErrors, setCardInfo }) => {
  const dispatch = useContext(DispatchContext);
  const recurlyError = fieldErrors.cc;
  const handleCcChange = (evt) => {
    const { valid, brand, lastFour } = evt;
    dispatch({
      type: CHECKOUT_SET_BILLING_CARD_VALIDATION,
      payload: { valid },
    });
    setCardInfo({ brand, lastFour });
  };

  const PaymentError = () => {
    let str = 'Credit Card';
    if (recurlyError && recurlyError.fields) {
      const numOfErrors = recurlyError.fields.length;
      recurlyError.fields.forEach((field, i) => {
        if (i === 0) {
          str += ` ${field}`;
        } else if (numOfErrors === 2) {
          str += ` and ${field}`;
        } else if (numOfErrors === i + 1) {
          str += `, and ${field}`;
        } else {
          str += `, ${field}`;
        }
      });
      str = numOfErrors === 1 ? `${str} is invalid` : `${str} are invalid`;
    }
    return recurlyError ? (
      <div className="CheckoutPaymentInformation-ccForm-error">{str}</div>
    ) : null;
  };

  return (
    <div className="CheckoutPaymentInformation-ccForm">
      <label
        className="CheckoutPaymentInformation-ccForm-label"
        htmlFor={'cardNumber'}
      >
        Credit Card Information
      </label>
      <div className="CheckoutPaymentInformation-ccForm-ccContainer">
        <CardElement onChange={handleCcChange} />
      </div>
      <PaymentError />
    </div>
  );
};

const CheckoutContinue = ({ type }) => (
  <div className="CheckoutPaymentInformation-continue">
    <CheckoutSectionContinue
      label={'View Order Summary ›'}
      // onClick={onContinue}
      type={type}
    />
  </div>
);
