import { getAuthInfo } from '.';
import { throwEndpointError } from '../../../utils';

export default async function fetchBillingInformation() {
  const { jwt, currentUser } = await getAuthInfo();
  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/billingInfo/${currentUser.attributes.email}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: jwt,
      }),
    }
  ).catch((err) =>
    throwEndpointError(err, res, 'Error fetching billing information')
  );

  if (!res.ok) {
    throwEndpointError({}, res, 'Error fetching billing information');
    return;
  }

  const data = await res.json();
  console.log('Billing Info', data);
  // eslint-disable-next-line consistent-return
  return data;
}
