import React, { memo, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import {
  AccountIcon,
  AmwellLogo2,
  BillingIcon,
  DashboardIcon,
  HelpIcon,
  UserIcon,
  DocumentsIcon
} from '../../icons';
import './SideNavigation.scss';

export default function SideNavigation() {
  const [pathname, setPathname] = useState('');
  useEffect(() => {
    if (window) {
      setPathname(window.location.pathname);
    }
  }, []);
  return (
    <div className="SideNavigation">
      <div className="SideNavigation-brand">
        <AmwellLogo2 />
      </div>
      <ul className="SideNavigation-menu">
        <MenuItem
          to={'/dashboard'}
          label={'Dashboard'}
          icon={<DashboardIcon />}
          partiallyActive={
            pathname === '/dashboard/' || pathname === '/dashboard'
          }
        />
        <MenuItem
          to={'/dashboard/account'}
          label={'My Account'}
          icon={<AccountIcon />}
          partiallyActive={true}
        />
        <MenuItem
          to={'/dashboard/users'}
          label={'Practice Users'}
          icon={<UserIcon />}
          partiallyActive={true}
        />
        <MenuItem
          to={'/dashboard/plan'}
          label={'Plan & Payment'}
          icon={<BillingIcon />}
          partiallyActive={true}
        />
        <MenuItem
          to={'/dashboard/documents'}
          label={'My Documents'}
          icon={<DocumentsIcon />}
          partiallyActive={true}
        />
        <MenuItem
          to={'/dashboard/help'}
          label={'Resources'}
          icon={<HelpIcon />}
          partiallyActive={true}
        />
      </ul>
      <Link className="SideNavigation-signout" to={'/signout'}>
        Sign Out
      </Link>
    </div>
  );
}

const MenuItem = memo(({ label, icon, to, partiallyActive }) => (
  <Link
    to={to}
    className="SideNavigation-menu-item"
    activeClassName="active"
    partiallyActive={partiallyActive}
    // partiallyActive={to === '/dashboard/' ? false : true}
  >
    <span className="SideNavigation-menu-item-icon">{icon}</span>
    <span className="SideNavigation-menu-item-label">{label}</span>
  </Link>
));
