import React, { Component } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import './Footer.scss';
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
  AppStore,
  GooglePlay,
} from '../../icons';

class Footer extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query FooterQuery {
            strapiFooter {
              appstore
              copyright
              privacyUrl
              facebook
              googleplay
              instagram
              linkedin
              termsUrl
              twitter
              youtube
              Logo {
                width
                height
                url
                alternativeText
              }
              LinkLists {
                headerTitle
                headerUrl
                Links {
                  name
                  url
                }
              }
            }
          }
        `}
        render={(footerData) => (
          <footer>
            <div className="footer-container">
              <div className="logo-area">
                <div className="logo-container">
                  <Link to="/" aria-label="Return to Home Page">
                    <img
                      src={footerData.strapiFooter.Logo.url}
                      alt={footerData.strapiFooter.Logo.alternativeText}
                      width={footerData.strapiFooter.Logo.width}
                      height={footerData.strapiFooter.Logo.height}
                    />
                  </Link>
                </div>
                <div className="social-icons">
                  {/* eslint-disable-next-line consistent-return */}
                  {(() => {
                    if (footerData.strapiFooter.facebook !== '') {
                      return (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={footerData.strapiFooter.facebook}
                          aria-label="Visit our Facebook Page"
                        >
                          <Facebook />
                        </a>
                      );
                    }
                  })()}
                  {/* eslint-disable-next-line consistent-return */}
                  {(() => {
                    if (footerData.strapiFooter.instagram !== '') {
                      return (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={footerData.strapiFooter.instagram}
                          aria-label="Visit our Instagram Page"
                        >
                          <Instagram />
                        </a>
                      );
                    }
                  })()}
                  {/* eslint-disable-next-line consistent-return */}
                  {(() => {
                    if (footerData.strapiFooter.linkedin !== '') {
                      return (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={footerData.strapiFooter.linkedin}
                          aria-label="Visit our LinkedIn Page"
                        >
                          <LinkedIn />
                        </a>
                      );
                    }
                  })()}
                  {/* eslint-disable-next-line consistent-return */}
                  {(() => {
                    if (footerData.strapiFooter.twitter !== '') {
                      return (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={footerData.strapiFooter.twitter}
                          aria-label="Visit our Twitter Page"
                        >
                          <Twitter />
                        </a>
                      );
                    }
                  })()}
                  {/* eslint-disable-next-line consistent-return */}
                  {(() => {
                    if (footerData.strapiFooter.youtube !== '') {
                      return (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={footerData.strapiFooter.youtube}
                          aria-label="Visit our YouTube Page"
                        >
                          <YouTube />
                        </a>
                      );
                    }
                  })()}
                </div>
                <div className="app-links">
                  {/* eslint-disable-next-line consistent-return */}
                  {(() => {
                    if (footerData.strapiFooter.appstore !== '') {
                      return (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={footerData.strapiFooter.appstore}
                          aria-label="Download Our App"
                        >
                          <AppStore />
                        </a>
                      );
                    }
                  })()}
                  {/* eslint-disable-next-line consistent-return */}
                  {(() => {
                    if (footerData.strapiFooter.googleplay !== '') {
                      return (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={footerData.strapiFooter.googleplay}
                          aria-label="Download Our App"
                        >
                          <GooglePlay />
                        </a>
                      );
                    }
                  })()}
                </div>
              </div>

              <div className="links-area">
                {footerData.strapiFooter.LinkLists.map((list, i) => (
                  <ul className="link-list" key={i}>
                    <li className="list-title">
                      <a target="_blank" rel="noreferrer" href={list.headerUrl}>
                        {list.headerTitle}
                      </a>
                    </li>

                    {list.Links.map((link, i) => (
                      <li key={i}>
                        <a target="_blank" rel="noreferrer" href={link.url}>
                          {link.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>

              <div className="info-area">
                <div className="policy-links">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={footerData.strapiFooter.privacyUrl}
                  >
                    Privacy
                  </a>
                  <span>|</span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={footerData.strapiFooter.termsUrl}
                  >
                    Terms of Use
                  </a>
                  <span>|</span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://business.amwell.com/sitemap"
                  >
                    SiteMap
                  </a>
                </div>
                <div
                  className="copyright"
                  dangerouslySetInnerHTML={{
                    __html: `©${new Date().getFullYear()} ${
                      footerData.strapiFooter.copyright
                    }`,
                  }}
                />
              </div>
            </div>
          </footer>
        )}
      />
    );
  }
}
export default Footer;
