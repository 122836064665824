import React from 'react';
import { Link } from 'gatsby';
import './CheckoutNavigationBar.scss';
import { AmwellLogo1 } from '../../icons';

export default function CheckoutNavigationBar() {
  return (
    <div className="CheckoutNavigationBar">
      <Link to="/">
        <AmwellLogo1 />
      </Link>
    </div>
  );
}
