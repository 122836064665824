import { getAuthInfo } from '.';
import { throwEndpointError, getProviderFieldKey } from '../../../utils';

export default async function updateProvider(user, providerObj) {
  const { jwt } = await getAuthInfo();
  const { provider, updatedFields } = providerObj;
  const updatedProviderFields = {};
  Object.entries(updatedFields).forEach((entry) => {
    const [key, value] = entry;
    const providerObjKey = getProviderFieldKey(key);
    if (providerObjKey) updatedProviderFields[providerObjKey] = value;
  });
  const payload = { ...updatedProviderFields };

  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/provider/${provider.id}`,
    {
      method: 'PUT',
      headers: new Headers({
        Authorization: jwt,
      }),
      body: JSON.stringify(payload),
    }
  ).catch((err) => throwEndpointError(err, res, 'Error updating provider'));

  if (!res.ok) {
    throwEndpointError({}, res, 'Error Updating Billing Information');
    return;
  }

  const data = await res.json();
  console.log('Provider Update Response', data);

  // eslint-disable-next-line consistent-return
  return data;
}
