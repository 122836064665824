import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import './DashboardPlanInformation.scss';
import { LoadingSpinner } from '../../misc';
import { DispatchContext, StateContext } from '../../../lib/providers';
import { Amwell } from '../../../lib/services';
import { APP_SET_USER_SUBSCRIPTION_DATA } from '../../../lib/events/app/types';
import { calcMonthlyPayment } from '../../../lib/utils';

export default function DashboardPlanInformation() {
  const [loading, setLoading] = useState(true);
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { user } = state;
  const { subscription, data } = user;
  const planName = subscription ? subscription.plan.name : null;
  const totalUsers = subscription ? subscription.addOns[0].quantity : null;
  const monthlyPayment = subscription
    ? calcMonthlyPayment(subscription, totalUsers)
    : null;
  const isFreePlan = planName ? planName.toLowerCase().includes('free') : null;
  const firstName = data ? data.recurly.firstName : '';

  if (!subscription) {
    (async () => {
      const subscriptionData = await Amwell.fetchSubscriptionInformation();
      dispatch({
        type: APP_SET_USER_SUBSCRIPTION_DATA,
        payload: { subscription: subscriptionData[0] },
      });
    })();
  }

  useEffect(() => {
    setLoading(!subscription);
  }, [subscription]);

  return (
    <div className="DashboardPlanInformation">
      <div className="DashboardPlanInformation-welcome">
        <h2 className="DashboardPlanInformation-welcome-heading">
          Welcome, {firstName}
        </h2>
        <p className="DashboardPlanInformation-welcome-copy">
          Your plan details are listed here. If you wish to add more Practice
          Users you can upgrade your plan below.
        </p>
      </div>
      <div className="DashboardPlanInformation-info">
        <PlanInformationInfoLine
          label={'Plan:'}
          content={!loading ? `${planName}` : <LoadingSpinner />}
        />
        <PlanInformationInfoLine
          label={'Total Users:'}
          content={!loading ? `${totalUsers}` : <LoadingSpinner />}
        />
        <PlanInformationInfoLine
          label={'Monthly Payment:'}
          content={!loading ? `${monthlyPayment.money}` : <LoadingSpinner />}
        />
      </div>
      <div className="DashboardPlanInformation-upgrade">
        {isFreePlan ? (
          <Link
            className="DashboardPlanInformation-upgrade-button"
            to="/dashboard/plan/change"
          >
            Upgrade Plan
          </Link>
        ) : (
          <Link
            className="DashboardPlanInformation-upgrade-button"
            to="/dashboard/plan/change"
          >
            Change User Count
          </Link>
        )}
      </div>
    </div>
  );
}

const PlanInformationInfoLine = ({ label, content }) => (
  <p className="DashboardPlanInformation-info-line">
    <span className="DashboardPlanInformation-info-line-label">{label}</span>
    <span className="DashboardPlanInformation-info-line-content">
      {content}
    </span>
  </p>
);
