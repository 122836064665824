import React, { Component } from 'react'
import {ToolTipController, Select} from 'react-tooltip-controller'

const ToolTip = () =>
  <div className="toolTip">
    The Business Associate Agreement (BAA) establishes a legally binding relationship between HIPAA (Health Insurance Portability and Accountability Act) covered entities and business associates to ensure protection of Patient PHI (Protected Health Information) and is necessary if the business associate will have access to PHI. Provider organizations using Amwell Private Practice are able to retrieve a dually executed agreement if they desire.
  </div>

class BAAInfoToolTip extends Component {

  render() {
    return (
      <div className="tooltip-container">

        <ToolTipController
          id="1"
          detect="hover"
          offsetX="centre"
          offsetY={20}
        >

          <Select>
            <i className="gg-info"></i>
          </Select>

          <ToolTip/>

        </ToolTipController>

      </div>

    )
  }
}

export default BAAInfoToolTip
