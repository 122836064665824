import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import DashboardPracticeUser from '../DashboardPracticeUser/DashboardPracticeUser';
import './DashboardUserList.scss';
import { mergeUniqueUsers } from './functions';
import { LoadingSpinner } from '../../misc';
import { DispatchContext, StateContext } from '../../../lib/providers';
import { Amwell } from '../../../lib/services';
import {
  APP_SET_USER_DATA,
  APP_SET_USER_SUBSCRIPTION_DATA,
  APP_SET_USER_PROVIDER_LIST_DATA,
} from '../../../lib/events/app/types';
import {
  addToAccountQueue,
  generateNewUserObj,
  getAccountQueue,
  getUnregisteredQueuedProviders,
  getUpdatingQueuedProviders,
  makeProviderObject,
} from '../../../lib/utils';

/**
[EXAMPLE USER OBJECT]

{
  title: "MD",
  firstName: "Richard",
  lastName: "Glass",
  specialty: "General Practice",
  email: "rglass@atlanticmedical.com",
  npi: "7389113903",
}
*/

export default function DashboardUserList() {
  const initiallyFetched = useRef(false);
  const [loading, setLoading] = useState({ status: 'loading', message: '' });
  const [editableUser, setEditableUser] = useState(null);
  const [updatingUsers, setUpdatingUsers] = useState({});
  const [totalActive, setTotalActive] = useState(0);
  const [showUnregisteredUsers, setShowUnregisteredUsers] = useState(false);
  const [registeredProviders, setRegisteredProviders] = useState([]);
  const [unregisteredProviders, setUnregisteredProviders] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [updatingProviders, setUpdatingProviders] = useState(null);
  const [users, setUsers] = useState([]);
  const dispatch = useContext(DispatchContext);
  const { user } = useContext(StateContext);
  const { subscription } = user;
  const { addOns, isFree } = subscription || {};
  const totalSeats = addOns ? addOns[0].quantity : 0;
  const mounted = useRef(false);
  const initialSetup = useRef(false);

  /**
   * Shows a message to inform the user the accounts are still being processed
   */
  const informUserCreation = useCallback(() => {
    const message = `Your provider accounts are being created. This could take up to five minutes.`;
    setLoading({ status: 'loading', message });
  }, []);

  /**
   * Handles setting a single editable user
   */
  const handleUserEdit = useCallback((evt) => {
    setEditableUser(evt.index);
  }, []);

  /**
   * Will merge all active, updating, creating, and unused accounts
   */
  const showAllProviderAccounts = useCallback(() => {
    const accountsToCreate = totalSeats - totalActive;
    // Create objects to use as form template for the unregistered users
    // added additional field flagging as unregistered
    const blankAccounts = [];
    for (let i = 0; i < accountsToCreate; i++) {
      const blankAccount = makeProviderObject(generateNewUserObj());
      blankAccount.unregistered = true;
      blankAccounts.push(blankAccount);
    }
    // Merge both registered and unregistered providers
    const mergedProviders = [
      ...registeredProviders,
      ...unregisteredProviders,
      ...blankAccounts,
    ];
    setUsersWithProviderAccounts(mergedProviders);

    /**
     * Auto find and edit the account if there's only a single account that can be added
     */
    if (blankAccounts.length === 1) {
      const index = mergedProviders.findIndex(
        (account) => account.unregistered && !account.Email
      );
      handleUserEdit({ index });
    }
  }, [
    totalSeats,
    totalActive,
    registeredProviders,
    unregisteredProviders,
    handleUserEdit,
  ]);

  /**
   * Will merge only active users and unregistered users that are being created
   */
  const showActiveProviderAccounts = useCallback(() => {
    setUsersWithProviderAccounts([
      ...registeredProviders,
      ...unregisteredProviders,
    ]);
  }, [registeredProviders, unregisteredProviders]);

  /**
   * Will toggle between showing only active users or including unregistered/blank users to the list
   */
  const handleToggleRemainingUsers = useCallback(() => {
    setShowUnregisteredUsers((val) => {
      if (!val) {
        showAllProviderAccounts();
      } else {
        showActiveProviderAccounts();
      }
      return !val;
    });
  }, [showActiveProviderAccounts, showAllProviderAccounts]);

  const addRemainingAccountsToPool = useCallback(
    (providerPool) => {
      const accountsToCreate = totalSeats - providerPool.length;
      console.log('addRemainingAccountsToPool', {
        accountsToCreate,
        totalSeats,
        providerPool,
      });
      if (accountsToCreate <= 0) {
        handleToggleRemainingUsers();
        return providerPool;
      }
      for (let i = 0; i < accountsToCreate; i++) {
        const blankAccount = makeProviderObject(generateNewUserObj());
        blankAccount.unregistered = true;
        providerPool.push(blankAccount);
      }
      return providerPool;
    },
    [totalSeats, handleToggleRemainingUsers]
  );

  /**
   * Provider information is fetched both remote and cached account data
   * 1. Will attempt to fetch the remote account data
   * 2. Fetch cached accounts data
   * 3. Attempt to resolve cached accounts with remote account data
   * 4. Create a pool of accounts of both remote and resolved cached accounts
   */
  // eslint-disable-next-line consistent-return
  const fetchProviderAccountList = useCallback(async () => {
    initiallyFetched.current = true;

    try {
      /**
       * Checks if salesforce object exists
       */
      if (!user.data.salesforce) {
        initialSetup.current = true;
        const { user: newUserObj, subscriptionData } =
          await Amwell.fetchCurrentUserInfo();

        if (mounted.current) {
          initiallyFetched.current = false;
          informUserCreation();
          return setTimeout(() => {
            dispatch({
              type: APP_SET_USER_DATA,
              payload: { user: newUserObj },
            });
            dispatch({
              type: APP_SET_USER_SUBSCRIPTION_DATA,
              payload: { subscription: subscriptionData[0] },
            });
          }, 60 * 1000);
        }
      }

      /**
       * Fetch provider accounts associated with the current account
       */
      const providerAccounts = await Amwell.fetchProviderAccounts();
      console.log('providerAccounts', providerAccounts);

      /**
       * Find the "Buyer" account of the current account
       */
      const buyer = providerAccounts.find(
        // eslint-disable-next-line camelcase
        ({ AWPP_Relationship__c }) => AWPP_Relationship__c === 'Buyer'
      );
      console.log('buyer', buyer);

      /**
       * Filter "Provider" accounts from the list of fetched accounts
       */
      const providers = providerAccounts.filter(
        // eslint-disable-next-line camelcase
        ({ AWPP_Relationship__c }) => AWPP_Relationship__c === 'Provider'
      );
      console.log('providers', providers);

      // Make sure checkout providers have been loaded
      if (initialSetup.current && localStorage.getItem('amwell-order')) {
        const { providers: orderProviders } = JSON.parse(
          localStorage.getItem('amwell-order')
        );
        if (providers.length !== orderProviders.length) {
          informUserCreation();
          return setTimeout(() => {
            fetchProviderAccountList();
          }, 30 * 1000);
        }
        initialSetup.current = false;
      }

      /**
       * Sets state of "Provider" accounts currently registered under the account
       */
      setRegisteredProviders(providers);
      // Handles user creation queue
      if (providers.length) {
        let providerPool = [...providers];

        // Queue information is stored in local storage to the user's account id
        const queuedProviders = getAccountQueue(user);

        if (queuedProviders.length) {
          console.log('Queued Provider Accounts', queuedProviders);

          /**
           * Filter and remove newly registered users from account queue
           * Unregistered accounts will not be immediately created and available in the db
           * Cached accounts flagged "unregistered" that appear among the remote accounts will be considered "in sync"
           * Cached accounts assumed to be "in sync" are resolved from the cache
           */
          const unregisteredQueuedProviders = getUnregisteredQueuedProviders(
            providers,
            queuedProviders,
            user
          );
          if (unregisteredQueuedProviders) {
            console.log(
              'Unregistered Providers in Queue',
              unregisteredQueuedProviders
            );
            setUnregisteredProviders(unregisteredQueuedProviders);
            setUpdatingUsers((val) => {
              unregisteredQueuedProviders.forEach((item) => {
                // eslint-disable-next-line no-param-reassign
                val[item.Email] = item;
              });
              return { ...val };
            });
            providerPool = [...providerPool, ...unregisteredQueuedProviders];
          }

          /**
           * Filter and remove fully updated users from account queue
           * If a user is updated (not created/registered) only the updated fields (Fragment) and identifying email will be cached locally
           * If a cached fragment (matched by email) aligns with it's parallel remote account, it's assumed "in sync"
           * Cached accounts assumed to be "in sync" are resolved from the cache
           */
          const updatingQueuedProviders = getUpdatingQueuedProviders(
            providers,
            queuedProviders,
            user
          );
          if (updatingQueuedProviders) {
            console.log('Queue of Updating Providers', updatingQueuedProviders);
            setUpdatingProviders(updatingQueuedProviders);
            setUpdatingUsers(() => {
              const newVal = {};
              updatingQueuedProviders.forEach((item) => {
                newVal[item.Email] = item;
              });
              return { ...newVal };
            });
            providerPool = [...providerPool, ...updatingQueuedProviders];
          }
          providerPool = mergeUniqueUsers(providerPool);
        }
        dispatch({
          type: APP_SET_USER_PROVIDER_LIST_DATA,
          payload: { providers: providerPool },
        });

        console.log('Working Provider Pool', providerPool);

        if (showUnregisteredUsers) {
          providerPool = addRemainingAccountsToPool(providerPool);
        }

        // Populates state with array of accounts to render in list
        setUsersWithProviderAccounts(providerPool);

        setLoading({ status: 'done', message: '' });
      }
      // No providers exist
      else if (buyer && !providers.length) {
        // If a user queue information exists locally, handle listing them
        const queuedProviders = getAccountQueue(user);
        if (queuedProviders.length) {
          console.log('Providers are currently enqueued', queuedProviders);
          const unregisteredQueuedProviders = getUnregisteredQueuedProviders(
            providers,
            queuedProviders,
            user
          );
          setUpdatingUsers((val) => {
            unregisteredQueuedProviders.forEach((item) => {
              // eslint-disable-next-line no-param-reassign
              val[item.Email] = item;
            });
            return { ...val };
          });

          if (showUnregisteredUsers && queuedProviders.length >= totalSeats) {
            // handleToggleRemainingUsers()
            setShowUnregisteredUsers(false);
          }

          setUsersWithProviderAccounts(unregisteredQueuedProviders);
          console.log('Unregistered Providers Enqueued', {
            unregisteredQueuedProviders,
          });
          dispatch({
            type: APP_SET_USER_PROVIDER_LIST_DATA,
            payload: { providers: [...unregisteredQueuedProviders] },
          });
        } else {
          console.log('No Providers have been created');
          setUsersWithProviderAccounts([]);
          dispatch({
            type: APP_SET_USER_PROVIDER_LIST_DATA,
            payload: { providers: [] },
          });
        }
        setLoading({ status: 'done', message: '' });
      }
      // User account has not finished setup
      else {
        informUserCreation();
        setTimeout(() => {
          fetchProviderAccountList();
        }, 60 * 1000);
      }
    } catch (error) {
      console.error('Error fetching provider accounts', error);
      /**
       * If the error response contains "No Providers Found For Account"
       * This indicates the account is newly created and the provider create queue has not been finished
       */
      informUserCreation();
      setTimeout(() => {
        fetchProviderAccountList();
      }, 60 * 1000);
    }
  }, [
    user,
    totalSeats,
    informUserCreation,
    dispatch,
    showUnregisteredUsers,
    addRemainingAccountsToPool,
    mounted,
    initialSetup,
  ]);

  /**
   * Handles editor close
   */
  const handleUserEditClose = useCallback(
    ({ save, remove } = { save: false, remove: false }) => {
      if (!save) {
        const providerAccount = users[editableUser];
        const uneditedAccountObj = {
          title: providerAccount ? providerAccount.title : '',
          firstName: providerAccount ? providerAccount.firstName : '',
          lastName: providerAccount ? providerAccount.lastName : '',
          specialty: providerAccount ? providerAccount.specialty : '',
          email: providerAccount ? providerAccount.email : '',
          npi: providerAccount ? providerAccount.npi : '',
          id: providerAccount ? providerAccount.id : '',
          unregistered: providerAccount ? providerAccount.unregistered : true,
        };

        setUsers((val) => {
          // eslint-disable-next-line no-param-reassign
          val[editableUser] = { ...uneditedAccountObj };
          return [...val];
        });
      }

      setEditableUser(null);
    },
    [editableUser, users]
  );

  /**
   * Handles submitting a user DELETE request
   */
  const handleUserDelete = useCallback(
    async (evt) => {
      const { user: practiceUser } = evt;
      try {
        const resData = await Amwell.deleteProvider(user, {
          provider: practiceUser,
        });
        console.log('handleUserDelete:success', { resData, evt });
        handleUserEditClose();
        window.location.reload();
        // TODO: Causes unexpected behavior rendering list (Fix later)
        // fetchProviderAccountList()
        return resData;
      } catch (error) {
        return false;
      }
    },
    [user, handleUserEditClose]
  );

  /**
   * Handles submitting a user PUT update
   */
  const handleUserSave = useCallback(
    async (evt) => {
      const { index, provider, id } = evt;
      const stateUser = users[index];
      const updatedFields = {};

      // Detect only the updated fields
      for (const key in stateUser) {
        if (stateUser[key] && provider[key]) {
          const oldField = stateUser[key];
          const newField = provider[key];
          const hasChanged = newField !== oldField;
          if (hasChanged) updatedFields[key] = newField;
        }
      }

      if (provider.unregistered) {
        console.log('Registering new user', provider);

        try {
          // Send to endpoint (Method: POST)
          await Amwell.addNewProvider(user, {
            provider: makeProviderObject(provider),
          });

          // Add to local queued
          const queueProvider = makeProviderObject(provider);
          queueProvider.unregistered = true;
          const queue = addToAccountQueue({ queueProvider }, user);
          console.log('User Registration Successful', { queue, provider });
          setUpdatingUsers((val) => ({ ...val, [provider.email]: provider }));
          handleUserEditClose({ save: true });
          setUnregisteredProviders([...unregisteredProviders, queueProvider]);
          await fetchProviderAccountList();

          return true;
        } catch (err) {
          const { name, message } = err;
          console.error('User Registration Failed', { name, message });
          return false;
        }
      } else {
        console.log('Updating user', updatedFields);
        try {
          const newProviderObj = makeProviderObject(provider);
          newProviderObj.id = id;
          // Send to endpoint (Method: PUT)
          await Amwell.updateProvider(user, {
            provider: newProviderObj,
            updatedFields,
          });

          // Add to local queued
          console.log('UPDATE  provider', provider);
          console.log('UPDATE  user', user);
          const queueProvider = makeProviderObject({
            ...updatedFields,
            email: provider.email,
          });
          queueProvider.updating = true;
          const queue = addToAccountQueue({ queueProvider }, user);
          console.log('User Update Successful', { queueProvider, queue });
          setUpdatingUsers((val) => ({ ...val, [provider.email]: provider }));
          handleUserEditClose({ save: true });
          await fetchProviderAccountList();
          return true;
        } catch (err) {
          const { name, message } = err;
          console.error('User Update Failed', { name, message });
          return false;
        }
      }
    },
    [
      users,
      user,
      handleUserEditClose,
      unregisteredProviders,
      fetchProviderAccountList,
    ]
  );

  /**
   * Will set "users" state as a list with converted "providerAccounts"
   */
  const setUsersWithProviderAccounts = (providerAccounts) => {
    setUsers(
      providerAccounts
        .map((account) => ({
          firstName: account.FirstName,
          lastName: account.LastName,
          email: account.Email,
          title: account.Title || '',
          specialty: account.Specialty__c || '',
          npi: account.NPI__c || '',
          id: account.Id,
          unregistered: account.unregistered,
        }))
        .sort((a, b) => {
          const A = (a.firstName + a.lastName).toLowerCase();
          const B = (b.firstName + b.lastName).toLowerCase();

          // Required to keep blank users at the bottom
          if (A.length === 0 && B.length === 0) return 0;
          if (A.length === 0) return 1;
          if (B.length === 0) return -1;

          if (A > B) return 1;
          if (A < B) return -1;
          return 0;
        })
    );
  };

  /**
   * Track "active" accounts
   */
  useEffect(() => {
    setTotalActive(users.length);
  }, [users, totalActive]);

  /**
   * Track mounted state
   */
  useEffect(() => {
    mounted.current = true;
    // eslint-disable-next-line no-return-assign
    return () => (mounted.current = false);
  }, []);

  /**
   * Initial Fetch Providers (Runs Once)
   */
  useEffect(() => {
    (async () => {
      if (!initiallyFetched.current) {
        await fetchProviderAccountList();
      }
    })();
  }, [fetchProviderAccountList]);

  switch (loading.status) {
    /**
     * User list video
     */
    case 'done':
      console.log('Users', users);

      return (
        <div className="DashboardUserList">
          <ListBackground
            editableUser={editableUser}
            onClick={handleUserEditClose}
          />

          <div className="DashboardUserList-head">
            <div className="DashboardUserList-head-cell heading name">Name</div>
            <div className="DashboardUserList-head-cell heading email">
              Email Address
            </div>
            <div className="DashboardUserList-head-cell heading title">
              Credentials
            </div>
            <div className="DashboardUserList-head-cell heading specialty">
              Care Specialty
            </div>
            <div className="DashboardUserList-head-cell heading npi">NPI</div>
          </div>

          {users.map((user, i) => (
            <DashboardPracticeUser
              key={`${user.email ? user.email : i}`}
              index={i}
              user={user}
              editableUser={editableUser}
              isFree={isFree}
              onEdit={handleUserEdit}
              onEditClose={handleUserEditClose}
              onSave={handleUserSave}
              onDelete={handleUserDelete}
              updating={!!updatingUsers[user.email]}
            />
          ))}

          {totalSeats - totalActive ||
          (!(totalSeats - totalActive) && showUnregisteredUsers) ? (
            <ShowRemainingUsersToggle
              isFree={isFree}
              isOpen={showUnregisteredUsers}
              onClick={handleToggleRemainingUsers}
              amountDisplay={totalSeats - totalActive}
            />
          ) : null}
        </div>
      );

    /**
     * View if no practice users are setup
     */
    case 'setup-required':
      return (
        <div className="DashboardUserList-loading">
          <span>{loading.message}</span>
          {/* <Link to="/dashboard/users/setup">Go Setup Users &gt;</Link> */}
        </div>
      );

    /**
     * Loading Indication (With Message)
     */
    case 'loading':
      return (
        <div className="DashboardUserList-loading">
          <LoadingSpinner />
          <span>{loading.message}</span>
        </div>
      );
    default:
      return (
        <div className="DashboardUserList-loading">
          <LoadingSpinner />
          <span>{loading.message}</span>
        </div>
      );
  }
}

const ShowRemainingUsersToggle = ({
  isFree,
  onClick: handleClick,
  amountDisplay,
  isOpen,
}) => {
  const label = isOpen
    ? 'Hide Remaining Users'
    : `Register Practice User (${amountDisplay} Remaining)`;
  return !isFree ? (
    <button
      className="DashboardUserList-registerRemaining"
      onClick={handleClick}
    >
      <span className="DashboardUserList-registerRemaining-icon"></span>
      <span className="DashboardUserList-registerRemaining-text">{label}</span>
    </button>
  ) : null;
};

const ListBackground = memo(({ editableUser, onClick: handleClick }) =>
  editableUser !== null ? (
    <button
      className="DashboardUserList-background"
      onClick={() => handleClick({ save: false })}
    >
      Close
    </button>
  ) : null
);
